import * as actionsTypes from '../../types/article/article';

const initialState = {
    articlelist: [],
    loading: false,
    rightdrawer: false,
    typedrawer: "null",

    loadingupload: false,
    filename: "",

    loading_add_article: false,
    loading_visibilite: false,

    currentPage: null,
    hasNextPage: null,
    hasPreviousPage: null,
    nexPage: null,
    previousPage: null,
    lastPage: null,

    currentPage_etudiant: null,
    hasNextPage_etudiant: null,
    hasPreviousPage_etudiant: null,
    nexPage_etudiant: null,
    previousPage_etudiant: null,
    lastPage_etudiant: null,

    currentPage_ressortissant: null,
    hasNextPage_ressortissant: null,
    hasPreviousPage_ressortissant: null,
    nexPage_ressortissant: null,
    previousPage_ressortissant: null,
    lastPage_ressortissant: null,
}

const reducerArticle = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.GET_ARTICLE_START:
            return {
                ...state,
                loading: true
            }

        case actionsTypes.GET_ARTICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                articlelist: action.articlelist,
                error: null
            }

        case actionsTypes.GET_ARTICLE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case actionsTypes.SET_RIGHT_DRAWER:
            return {
                ...state,
                rightdrawer: action.value
            }

        case actionsTypes.SET_TYPE_DRAWER:
            return {
                ...state,
                typedrawer: action.value
            }

        case actionsTypes.UPLOAD_ARTICLE_START:
            return {
                ...state,
                loadingupload: true,
                error: null
            }

        case actionsTypes.UPLOAD_ARTICLE_SUCCESS:
            return {
                ...state,
                loadingupload: false,
                error: false,
                filename: action.filename
            }

        case actionsTypes.UPLOAD_ARTICLE_FAILED:
            return {
                ...state,
                error: action.error,
                loadingupload: false
            }

        case actionsTypes.ADD_ARTICLE_START:
            return {
                ...state,
                loading_add_article: true
            }

        case actionsTypes.ADD_ARTICLE_SUCCESS:
            return {
                ...state,
                loading_add_article: false,
                error: null,
                rightdrawer: false,
                typedrawer: "null",
                filename: "",
            }

        case actionsTypes.ADD_ARTICLE_FAILED:
            return {
                ...state,
                loading_add_article: false,
                error: action.error
            }

        case actionsTypes.SEARCH_ARTICLE_START:
            return {
                ...state,
                loading: true
            }

        case actionsTypes.SEARCH_ARTICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                articlelist: action.articlelist,
                error: null
            }

        case actionsTypes.SEARCH_ARTICLE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case actionsTypes.ARTICLE_VISIBILITE_START:
            return {
                ...state,
                loading_visibilite: true
            }

        case actionsTypes.ARTICLE_VISIBILITE_SUCCESS:
            return {
                ...state,
                loading_visibilite: false,
                error: null
            }

        case actionsTypes.ARTICLE_VISIBILITE_FAILED:
            return {
                ...state,
                loading_visibilite: false,
                error: action.error
            }

        case actionsTypes.GET_ARTICLE_PAGINATION_START:
            return {
                ...state,
                loading: true
            }

        case actionsTypes.GET_ARTICLE_PAGINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                articlelist: action.articlelist,
                currentPage: action.currentPage,
                hasNextPage: action.hasNextPage,
                hasPreviousPage: action.hasPreviousPage,
                nexPage: action.nexPage,
                previousPage: action.previousPage,
                lastPage: action.lastPage,
                error: null
            }

        case actionsTypes.GET_ARTICLE_PAGINATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case actionsTypes.GET_ARTICLE_ETUDIANT_PAGINATION_START:
            return {
                ...state,
                loading: true
            }

        case actionsTypes.GET_ARTICLE_ETUDIANT_PAGINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                articlelist: action.articlelist,
                currentPage_etudiant: action.currentPage_etudiant,
                hasNextPage_etudiant: action.hasNextPage_etudiant,
                hasPreviousPage_etudiant: action.hasPreviousPage_etudiant,
                nexPage_etudiant: action.nexPage_etudiant,
                previousPage_etudiant: action.previousPage_etudiant,
                lastPage_etudiant: action.lastPage_etudiant,
                error: null
            }

        case actionsTypes.GET_ARTICLE_ETUDIANT_PAGINATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case actionsTypes.GET_ARTICLE_RESSORTISSANT_PAGINATION_START:
            return {
                ...state,
                loading: true
            }

        case actionsTypes.GET_ARTICLE_RESSORTISSANT_PAGINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                articlelist: action.articlelist,
                currentPage_ressortissant: action.currentPage_ressortissant,
                hasNextPage_ressortissant: action.hasNextPage_ressortissant,
                hasPreviousPage_ressortissant: action.hasPreviousPage_ressortissant,
                nexPage_ressortissant: action.nexPage_ressortissant,
                previousPage_ressortissant: action.previousPage_ressortissant,
                lastPage_ressortissant: action.lastPage_ressortissant,
                error: null
            }

        case actionsTypes.GET_ARTICLE_RESSORTISSANT_PAGINATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }




        default:
            return state
    }
}

export default reducerArticle