
import { GET_ARTICLE_URL, UPLOAD_ARTICLE_URL, ADD_ARTICLE_URL, SEARCH_ARTICLE_URL, ARTICLE_VISIBILITE_URL,
     GET_ARTICLE_PAGINATION_URL, GET_ARTICLE_ETUDIANT_PAGINATION_URL, GET_ARTICLE_RESSORTISSANT_PAGINATION_URL} from '../../urls/article/article';

import { GET_ARTICLE_START, GET_ARTICLE_SUCCESS, GET_ARTICLE_FAILED,
     SET_TYPE_DRAWER, SET_RIGHT_DRAWER, UPLOAD_ARTICLE_START, UPLOAD_ARTICLE_SUCCESS, 
     UPLOAD_ARTICLE_FAILED, ADD_ARTICLE_START, ADD_ARTICLE_SUCCESS, ADD_ARTICLE_FAILED,
    SEARCH_ARTICLE_START, SEARCH_ARTICLE_SUCCESS, SEARCH_ARTICLE_FAILED, ARTICLE_VISIBILITE_START, 
    ARTICLE_VISIBILITE_SUCCESS, ARTICLE_VISIBILITE_FAILED, GET_ARTICLE_PAGINATION_START, GET_ARTICLE_PAGINATION_SUCCESS,
     GET_ARTICLE_PAGINATION_FAILED, GET_ARTICLE_ETUDIANT_PAGINATION_START, GET_ARTICLE_ETUDIANT_PAGINATION_SUCCESS, 
     GET_ARTICLE_ETUDIANT_PAGINATION_FAILED, GET_ARTICLE_RESSORTISSANT_PAGINATION_START, GET_ARTICLE_RESSORTISSANT_PAGINATION_SUCCESS, 
     GET_ARTICLE_RESSORTISSANT_PAGINATION_FAILED
    } from '../../types/article/article';


export const getArticleStart = () => {
    return {
        type: GET_ARTICLE_START
    }
}

export const getArticleSuccess = (articlelist) => {
    return {
        type: GET_ARTICLE_SUCCESS,
        articlelist: articlelist
    }
}

export const getArticleFailed = (error) => {
    return {
        type: GET_ARTICLE_FAILED,
        error: error
    }
}

export const getArticleService = (token) => {
    return dispatch => {
        dispatch(getArticleStart())
        fetch(GET_ARTICLE_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getArticleSuccess(response.data.response))
                }
                else{
                    dispatch(getArticleFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(getArticleFailed(error.message))
            })
    }

}

export const setTypeDrawer = (value) => {
    return {
        type: SET_TYPE_DRAWER,
        value:value
    }
}

export const setRightDrawer = (value) => {
    return {
        type: SET_RIGHT_DRAWER,
        value:value
    }
}

export const uploadArticleStart = () => {
    return {
        type: UPLOAD_ARTICLE_START
    }
}

export const uploadArticleSuccess = (filename) => {
    return {
        type: UPLOAD_ARTICLE_SUCCESS,
        filename: filename
    }
}

export const uploadArticleFail = () => {
    return {
        type: UPLOAD_ARTICLE_FAILED
    }
}

export const uploadArticleService = (data) => {
    console.log(data)
    const formData = new FormData();
    formData.append('file', data);
    return dispatch => {
        dispatch(uploadArticleStart())
        fetch(UPLOAD_ARTICLE_URL, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(response => {
                console.log("==>>>", response)
                if (response.status === 200) {
                    dispatch(uploadArticleSuccess(response.filedestination))
                }
                else {
                    dispatch(uploadArticleFail())
                }
            })
            .catch((err) => {
                console.log("errrrrr ====>>>>", err)
            })
    }
}

export const addArticleStart = () => {
    return {
        type: ADD_ARTICLE_START
    }
}

export const addArticleSuccess = () => {
    return {
        type: ADD_ARTICLE_SUCCESS
    }
}

export const addArticleFailed = (error) => {
    return {
        type: ADD_ARTICLE_FAILED,
        error: error
    }
}

export const addArticleService = (title, details , image , show, type, token) => {
    return dispatch => {
        dispatch(addArticleStart())
        fetch(ADD_ARTICLE_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            },
            body: JSON.stringify({
                "title": title,
                "details": details,
                "image": image,
                "show":show,
                "type":type
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(addArticleSuccess())
                    window.location.reload();
                }
                else{
                    dispatch(addArticleFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(addArticleFailed(error.message))
            })
    }

}

export const searchArticleStart = () => {
    return {
        type: SEARCH_ARTICLE_START
    }
}

export const searchArticleSuccess = (articlelist) => {
    return {
        type: SEARCH_ARTICLE_SUCCESS,
        articlelist:articlelist
    }
}

export const searchArticleFailed = (error) => {
    return {
        type: SEARCH_ARTICLE_FAILED,
        error: error
    }
}

export const searchArticleService = ( title, token) => {
    return dispatch => {
        dispatch(searchArticleStart())
        fetch(SEARCH_ARTICLE_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            },
            body: JSON.stringify({
                "title": title
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(searchArticleSuccess(response.data.response))
                }
                else{
                    dispatch(searchArticleFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(searchArticleFailed(error.message))
            })
    }

}

export const articleVisibiliteStart = () => {
    return {
        type: ARTICLE_VISIBILITE_START
    }
}

export const articleVisibiliteSuccess = () => {
    return {
        type: ARTICLE_VISIBILITE_SUCCESS
    }
}

export const articleVisibiliteFailed = (error) => {
    return {
        type: ARTICLE_VISIBILITE_FAILED,
        error: error
    }
}

export const articleVisibiliteService = ( articleId,show, token) => {
    return dispatch => {
        dispatch(articleVisibiliteStart())
        fetch(ARTICLE_VISIBILITE_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            },
            body: JSON.stringify({
                "articleId":articleId,
                "show": show
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(articleVisibiliteSuccess())
                    dispatch(getArticleService(token))
                }
                else{
                    dispatch(articleVisibiliteFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(articleVisibiliteFailed(error.message))
            })
    }

}

export const getArticlePaginationStart = () => {
    return {
        type: GET_ARTICLE_PAGINATION_START
    }
}

export const getArticlePaginationSuccess = (articlelist, currentPage, hasNextPage, hasPreviousPage, nexPage, previousPage, lastPage) => {
    return {
        type: GET_ARTICLE_PAGINATION_SUCCESS,
        articlelist: articlelist,
        currentPage:currentPage,
        hasNextPage:hasNextPage,
        hasPreviousPage:hasPreviousPage,
        nexPage:nexPage,
        previousPage:previousPage,
        lastPage:lastPage
    }
}

export const getArticlePaginationFailed = (error) => {
    return {
        type: GET_ARTICLE_PAGINATION_FAILED,
        error: error
    }
}

export const getArticlePaginationService = (page,token) => {
    return dispatch => {
        dispatch(getArticlePaginationStart())
        fetch(GET_ARTICLE_PAGINATION_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            },
            body: JSON.stringify({
                "page": page
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getArticlePaginationSuccess(response.data.response, response.data.currentPage, response.data.hasNextPage, response.data.hasPreviousPage, response.data.nexPage, response.data.previousPage, response.data.lastPage))
                }
                else{
                    dispatch(getArticlePaginationFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(getArticlePaginationFailed(error.message))
            })
    }

}

export const getArticleEtudiantPaginationStart = () => {
    return {
        type: GET_ARTICLE_ETUDIANT_PAGINATION_START
    }
}

export const getArticleEtudiantPaginationSuccess = (articlelist, currentPage_etudiant, hasNextPage_etudiant, hasPreviousPage_etudiant, nexPage_etudiant, previousPage_etudiant, lastPage_etudiant) => {
    return {
        type: GET_ARTICLE_ETUDIANT_PAGINATION_SUCCESS,
        articlelist: articlelist,
        currentPage_etudiant:currentPage_etudiant,
        hasNextPage_etudiant:hasNextPage_etudiant,
        hasPreviousPage_etudiant:hasPreviousPage_etudiant,
        nexPage_etudiant:nexPage_etudiant,
        previousPage_etudiant:previousPage_etudiant,
        lastPage_etudiant:lastPage_etudiant
    }
}

export const getArticleEtudiantPaginationFailed = (error) => {
    return {
        type: GET_ARTICLE_ETUDIANT_PAGINATION_FAILED,
        error: error
    }
}

export const getArticleEtudiantPaginationService = (page,token) => {
    return dispatch => {
        dispatch(getArticleEtudiantPaginationStart())
        fetch(GET_ARTICLE_ETUDIANT_PAGINATION_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            },
            body: JSON.stringify({
                "page": page
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getArticleEtudiantPaginationSuccess(response.data.response, response.data.currentPage, response.data.hasNextPage, response.data.hasPreviousPage, response.data.nexPage, response.data.previousPage, response.data.lastPage))
                }
                else{
                    dispatch(getArticleEtudiantPaginationFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(getArticleEtudiantPaginationFailed(error.message))
            })
    }

}

export const getArticleRessortissantPaginationStart = () => {
    return {
        type: GET_ARTICLE_RESSORTISSANT_PAGINATION_START
    }
}

export const getArticleResortissantPaginationSuccess = (articlelist, currentPage_ressortissant, hasNextPage_ressortissant, hasPreviousPage_ressortissant, nexPage_ressortissant, previousPage_ressortissant, lastPage_ressortissant) => {
    return {
        type: GET_ARTICLE_RESSORTISSANT_PAGINATION_SUCCESS,
        articlelist: articlelist,
        currentPage_ressortissant:currentPage_ressortissant,
        hasNextPage_ressortissant:hasNextPage_ressortissant,
        hasPreviousPage_ressortissant:hasPreviousPage_ressortissant,
        nexPage_ressortissant:nexPage_ressortissant,
        previousPage_ressortissant:previousPage_ressortissant,
        lastPage_ressortissant:lastPage_ressortissant
    }
}

export const getArticleRessortissantPaginationFailed = (error) => {
    return {
        type: GET_ARTICLE_RESSORTISSANT_PAGINATION_FAILED,
        error: error
    }
}

export const getArticleRessortissantPaginationService = (page,token) => {
    return dispatch => {
        dispatch(getArticleRessortissantPaginationStart())
        fetch(GET_ARTICLE_RESSORTISSANT_PAGINATION_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            },
            body: JSON.stringify({
                "page": page
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getArticleResortissantPaginationSuccess(response.data.response, response.data.currentPage, response.data.hasNextPage, response.data.hasPreviousPage, response.data.nexPage, response.data.previousPage, response.data.lastPage))
                }
                else{
                    dispatch(getArticleRessortissantPaginationFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(getArticleRessortissantPaginationFailed(error.message))
            })
    }

}