export const LOGIN_ADMIN_START = "LOGIN_ADMIN_START";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAILED = "LOGIN_ADMIN_FAILED";
export const LOGOUT ="LOGOUT";

export const UPDATE_PASSWORD_ADMIN_START = "UPDATE_PASSWORD_ADMIN_START"
export const UPDATE_PASSWORD_ADMIN_SUCCESS = "UPDATE_PASSWORD_ADMIN_SUCCESS"
export const UPDATE_PASSWORD_ADMIN_FAILED = "UPDATE_PASSWORD_ADMIN_FAILED"

export const OPEN_UPDATE_PASSWORD_MODAL = "OPEN_UPDATE_PASSWORD_MODAL";
export const CLOSE_UPDATE_PASSWORD_MODAL = "CLOSE_UPDATE_PASSWORD_MODAL";