import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getArticleService, setTypeDrawer, setRightDrawer, uploadArticleService, addArticleService, articleVisibiliteService, getArticlePaginationService } from '../../actions/article/article';
import Dropzone from 'react-dropzone';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import EllipsisText from "react-ellipsis-text";

import SearchArticle from '../../components/searcharticle/searcharticle';
import Layout from '../../components/layout/layout';
import Loader from '../../components/loader/loader';

import Moment from 'react-moment';
import 'moment/locale/fr';

const Articles = () => {

    const dispatch = useDispatch();

    const token = useSelector(state => state.AuthReducer.token)
    const loading = useSelector(state => state.ArticleReducer.loading)
    const articlelist = useSelector(state => state.ArticleReducer.articlelist)
    const rightdrawer = useSelector(state => state.ArticleReducer.rightdrawer)
    const typedrawer = useSelector(state => state.ArticleReducer.typedrawer)
    const loadingupload = useSelector(state => state.ArticleReducer.loadingupload)
    const filename = useSelector(state => state.ArticleReducer.filename)
    const loading_add_article = useSelector(state => state.ArticleReducer.loading_add_article)
    const loading_visibilite = useSelector(state => state.ArticleReducer.loading_visibilite)

    const currentPage = useSelector(state => state.ArticleReducer.currentPage)
    const hasNextPage = useSelector(state => state.ArticleReducer.hasNextPage)
    const hasPreviousPage = useSelector(state => state.ArticleReducer.hasPreviousPage)
    const nexPage = useSelector(state => state.ArticleReducer.nexPage)
    const previousPage = useSelector(state => state.ArticleReducer.previousPage)
    const lastPage = useSelector(state => state.ArticleReducer.lastPage)

    const [acceptedfile, setAcceptedFile] = useState([])
    const [titre, setTitre] = useState("")
    const [text, setText] = useState("")
    const [error, setError] = useState(false)
    const [eyeVisible, setEyeVisible] = useState(true)
    const [show, setShow] = useState(true)
    const [type, setType] = useState("null")
    const [publier, setPublier] = useState(true)

    useEffect(() => {
        //dispatch(getArticleService(token))
        dispatch(getArticlePaginationService(1, token))
    }, []);


    const handleAddCustomer = () => {
        dispatch(setTypeDrawer("addcustomer"))
        dispatch(setRightDrawer(true))
    }

    const handleCloseRightDrawer = () => {
        dispatch(setTypeDrawer("null"))
        dispatch(setRightDrawer(false))

    }

    const handleFilter = () => {
        dispatch(setTypeDrawer("filter"))
        dispatch(setRightDrawer(true))
    }

    const handleDrop = (acceptedFile) => {

        setAcceptedFile(acceptedFile)
        dispatch(uploadArticleService(acceptedFile[0]))
        setError(false)
    }


    const handleEditor = (editor) => {
        console.log(editor.getData())
        setText(editor.getData())
        setError(false)
    }

    const handleTitre = (e) => {
        setTitre(e.target.value)
        setError(false)
    }

    const handleAddArticle = () => {
        if (filename !== "" && titre !== "" && titre.trim() !== "" && text !== "" && text.trim() !== "" && type !== "null") {
            dispatch(addArticleService(titre, text, filename, publier, type, token))
            // console.log(filename, titre, text)
        }
        else {
            setError(true)
        }
    }

    const handleEye = (item) => {

        dispatch(articleVisibiliteService(item.id, !item.show, token))
    }

    const handleType = (e) => {
        console.log(e.target.value)
        setType(e.target.value)
    }

    const handlePublier = (e) => {
        setPublier(!publier)
    }

    const handlePagination = (page) => {

        dispatch(getArticlePaginationService(page, token))
    }


    let ArticleMap = articlelist.map((item, index) => {

        return (
            <div class="col-md-4" key={index}>

                <div class="card-xl-stretch me-md-6">

                    <a class="d-block overlay" data-fslightbox="lightbox-hot-sales" href="assets/media/stock/600x400/img-23.jpg">

                        <div class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px" style={{ backgroundImage: `url(${item.image})` }}></div>

                        <div class="overlay-layer card-rounded bg-dark bg-opacity-25">
                            <i class="ki-outline ki-eye fs-2x text-white"></i>
                        </div>

                    </a>

                    <div class="mt-5">

                        <a href="#" class="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base"> {item.title.length > 50 ? item.title.substring(0, 100) + "..." : item.title}</a>

                        <div class="fw-semibold fs-5 text-gray-600 text-dark mt-3" dangerouslySetInnerHTML={{ __html: item.details.length > 100 ? item.details.substring(0, 100) + "..." : item.details }} />

                        <div class="fs-6 fw-bold mt-5 d-flex flex-stack">

                            <span class="badge border border-dashed fs-2 fw-bold text-dark p-2">

                                <span class="fs-6 fw-semibold text-gray-400"><Moment fromNow>{item.createdAt}</Moment></span>

                            </span>

                            {
                                loading_visibilite ? "chargement... " :
                                    <span>

                                        {
                                            item.show ?
                                                <i class="ki-duotone ki-eye fs-2x" onClick={() => handleEye(item)} style={{ cursor: 'pointer' }}>
                                                    <span class="path1"></span>
                                                    <span class="path2"></span>
                                                    <span class="path3"></span>
                                                </i>

                                                :

                                                <i class="ki-duotone ki-eye-slash fs-2x" onClick={() => handleEye(item)} style={{ cursor: 'pointer' }}>
                                                    <span class="path1"></span>
                                                    <span class="path2"></span>
                                                    <span class="path3"></span>
                                                    <span class="path4"></span>
                                                </i>
                                        }

                                    </span>
                            }




                            <a href="#" class="btn btn-sm btn-primary">Modifier</a>


                        </div>

                    </div>

                </div>

            </div>
        )
    })



    return (
        <Layout openDrawer={true}>
            <div id="kt_app_content" class="app-content flex-column-fluid">

                <div id="kt_app_content_container" class="app-container container-xxl">

                    <div class="card">

                        <div class="card-body p-lg-20">

                            <div class="card-header border-0 pt-6">

                                <SearchArticle />

                                <div class="card-toolbar">

                                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">

                                        <button onClick={handleFilter} type="button" class="btn btn-light-primary me-3" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <i class="ki-outline ki-filter fs-2"></i>Filtrer</button>

                                        <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true" id="kt-toolbar-filter">

                                            <div class="px-7 py-5">
                                                <div class="fs-4 text-dark fw-bold">Filter Options</div>
                                            </div>


                                            <div class="separator border-gray-200"></div>

                                            <div class="px-7 py-5">

                                                <div class="mb-10">

                                                    <label class="form-label fs-5 fw-semibold mb-3">Month:</label>


                                                    <select class="form-select form-select-solid fw-bold" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-kt-customer-table-filter="month" data-dropdown-parent="#kt-toolbar-filter">
                                                        <option></option>
                                                        <option value="aug">August</option>
                                                        <option value="sep">September</option>
                                                        <option value="oct">October</option>
                                                        <option value="nov">November</option>
                                                        <option value="dec">December</option>
                                                    </select>

                                                </div>


                                                <div class="mb-10">

                                                    <label class="form-label fs-5 fw-semibold mb-3">Payment Type:</label>


                                                    <div class="d-flex flex-column flex-wrap fw-semibold" data-kt-customer-table-filter="payment_type">

                                                        <label class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                                                            <input class="form-check-input" type="radio" name="payment_type" value="all" checked="checked" />
                                                            <span class="form-check-label text-gray-600">All</span>
                                                        </label>


                                                        <label class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                                                            <input class="form-check-input" type="radio" name="payment_type" value="visa" />
                                                            <span class="form-check-label text-gray-600">Visa</span>
                                                        </label>


                                                        <label class="form-check form-check-sm form-check-custom form-check-solid mb-3">
                                                            <input class="form-check-input" type="radio" name="payment_type" value="mastercard" />
                                                            <span class="form-check-label text-gray-600">Mastercard</span>
                                                        </label>


                                                        <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                            <input class="form-check-input" type="radio" name="payment_type" value="american_express" />
                                                            <span class="form-check-label text-gray-600">American Express</span>
                                                        </label>

                                                    </div>

                                                </div>


                                                <div class="d-flex justify-content-end">
                                                    <button type="reset" class="btn btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" data-kt-customer-table-filter="reset">Reset</button>
                                                    <button type="submit" class="btn btn-primary" data-kt-menu-dismiss="true" data-kt-customer-table-filter="filter">Apply</button>
                                                </div>

                                            </div>

                                        </div>

                                        <button type="button" onClick={handleAddCustomer} class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Ajouter un article</button>

                                    </div>
                                </div>

                            </div>

                            <div class="mb-17">

                                <div class="separator separator-dashed mb-9"></div>

                                <div class="row g-10">
                                    {loading ?
                                        <div style={{ textAlign: 'center' }}>
                                            <Loader />
                                        </div>
                                        :

                                        ArticleMap
                                    }

                                </div>


                            </div>

                            <ul class="pagination" style={{ float: 'right' }}>

                                {
                                    (currentPage != 1 && previousPage!=1 )? <li onClick={() => handlePagination(1)} class="page-item "><a href="#" class="page-link">1</a></li> : null
                                }
                                {
                                    hasPreviousPage ? <li class="page-item" onClick={() => handlePagination(previousPage)}><a href="#" class="page-link">{previousPage}<i class="previous"></i></a></li> : null
                                }
                                {

                                }
                                <li class="page-item active"><a href="#" class="page-link">{currentPage}</a></li>

                                {
                                    hasNextPage ? <li class="page-item next" onClick={() => handlePagination(nexPage)}><a href="#" class="page-link">{nexPage}<i class="next"></i></a></li> : null
                                }
                                {
                                    (lastPage !== currentPage && nexPage !== lastPage) ? <li onClick={() => handlePagination(lastPage)} class="page-item "><a href="#" class="page-link">{lastPage}</a></li> : null
                                }
                            </ul>



                        </div>
                    </div>
                </div>
                <div id="kt_drawer_chat" class={rightdrawer ? "bg-body drawer drawer-end drawer-on" : "bg-body drawer drawer-end drawer-off"} data-kt-drawer="true" data-kt-drawer-name="chat" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'md': '500px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_drawer_chat_toggle" data-kt-drawer-close="#kt_drawer_chat_close" style={{ width: "45%" }}>

                    {/* Drawer */}
                    <div class="card w-100 border-0 rounded-0" id="kt_drawer_chat_messenger">

                        <div class="card-header pe-5" id="kt_drawer_chat_messenger_header">

                            <div class="card-title">
                                {
                                    typedrawer === "addcustomer" ?
                                        <div class="d-flex justify-content-center flex-column me-3">
                                            <a href="#" class="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1">Ajouter un article</a>


                                            <div class="mb-0 lh-1">
                                                <span class="badge badge-success badge-circle w-10px h-10px me-1"></span>
                                                <span class="fs-7 fw-semibold text-muted">Actif</span>
                                            </div>

                                        </div>
                                        :
                                        typedrawer === "filter" ?
                                            <div class="d-flex justify-content-center flex-column me-3">
                                                <a href="#" class="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1">Filter un utilisateur</a>


                                                <div class="mb-0 lh-1">
                                                    <span class="badge badge-success badge-circle w-10px h-10px me-1"></span>
                                                    <span class="fs-7 fw-semibold text-muted">Actif</span>
                                                </div>

                                            </div>
                                            : null
                                }


                            </div>



                            <div class="card-toolbar">

                                <div class="btn btn-sm btn-icon btn-active-color-primary" id="kt_drawer_chat_close" onClick={handleCloseRightDrawer}>
                                    <i class="ki-duotone ki-cross-square fs-2"><span class="path1"></span><span class="path2"></span></i>                </div>

                            </div>

                        </div>

                        <div class="card-body" id="kt_drawer_chat_messenger_body">

                            <div class="scroll-y me-n5 pe-5" data-kt-element="messages" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer" data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body" data-kt-scroll-offset="0px" style={{ height: "733px" }}>


                                {
                                    typedrawer === "addcustomer" ?

                                        <form class="form" action="#" id="kt_modal_add_customer_form">

                                            <div class="modal-body py-10 px-lg-17">

                                                <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">

                                                    <div class="fv-row mb-7">

                                                        <label class="required fs-6 fw-semibold mb-2">Titre</label>

                                                        <textarea type="text" class="form-control" rows={5} placeholder="" name="name" value={titre} onChange={handleTitre} />
                                                    </div>


                                                    <div class="fv-row mb-15">


                                                        <label class="fs-6 fw-semibold mb-2">Image</label>

                                                        <Dropzone onDrop={handleDrop}>
                                                            {({ getRootProps, getInputProps }) => (
                                                                <div {...getRootProps()} className="dropzone" id="kt_dropzonejs_example_1">
                                                                    <input {...getInputProps()} />
                                                                    <div className="dz-message needsclick">
                                                                        <i className="ki-duotone ki-file-up fs-3x text-primary">
                                                                            <span className="path1"></span><span className="path2"></span>
                                                                        </i>
                                                                        <div className="ms-4">
                                                                            {acceptedfile.length > 0 ? (
                                                                                <div>
                                                                                    <h3 className="fs-5 fw-bold text-gray-900 mb-1">{acceptedfile[0].name}</h3>

                                                                                </div>
                                                                            ) : (

                                                                                <div>
                                                                                    {
                                                                                        loadingupload ? <h3 className="fs-5 fw-bold text-gray-900 mb-1">Chargement...</h3> : <h3 className="fs-5 fw-bold text-gray-900 mb-1">Glissez ou deposer le fichier</h3>
                                                                                    }

                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Dropzone>

                                                    </div>

                                                    <div class="fv-row mb-7">

                                                        <label class="required fs-6 fw-semibold mb-2">Text</label>
                                                        <CKEditor
                                                            editor={ClassicEditor}

                                                            data=""
                                                            onReady={editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                                console.log('Editor is ready to use!', editor);
                                                            }}
                                                            onChange={(event, editor) => {
                                                                handleEditor(editor)
                                                                // console.log("event==>>",event);
                                                            }}
                                                            onBlur={(event, editor) => {
                                                                //console.log('Blur.', editor);
                                                            }}
                                                            onFocus={(event, editor) => {
                                                                //console.log('Focus.', editor);
                                                            }}
                                                        />
                                                    </div>

                                                    <div class="fv-row mb-7" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                                        <div>
                                                            <label class="required fs-6 fw-semibold mb-2">Publier a</label>
                                                            <div class="form-floating">
                                                                <select class="form-select" id="floatingSelect" aria-label="Floating label select example" onChange={handleType}>
                                                                    <option selected value={"null"}>Selectionnez le target de l'artcle</option>
                                                                    <option value={0}>Tout le monde</option>
                                                                    <option value={1}>Etudiants</option>
                                                                    <option value={2}>Ressortissants</option>
                                                                </select>
                                                                <label for="floatingSelect">Publication d'article</label>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <label class="required fs-6 fw-semibold mb-2">Statut de l'article</label>
                                                            <div class="form-check form-switch form-check-custom form-check-solid">
                                                                <input class="form-check-input" type="checkbox" value="" id="flexSwitchDefault" onChange={handlePublier} checked={publier ? "checked" : ""} />
                                                                <label class="form-check-label" for="flexSwitchDefault">
                                                                    {publier ? "Publier automatiquement " : "Publier automatiquement"}
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    {
                                                        error ?
                                                            <div><p style={{ color: 'red' }}> Verifiez vos informations!</p></div>
                                                            :
                                                            null
                                                    }

                                                </div>

                                            </div>


                                        </form>

                                        :

                                        typedrawer === "filter" ?

                                            <form class="form" action="#" id="kt_modal_add_customer_form">

                                                <div class="modal-body py-10 px-lg-17">

                                                    <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">

                                                        <div class=" w-300px w-md-325px" data-kt-menu="true" id="kt-toolbar-filter">






                                                            <div class="px-7 py-5">

                                                                <div class="mb-10">

                                                                    <label class="form-label fs-5 fw-semibold mb-3">Month:</label>


                                                                    <select class="form-select form-select-solid fw-bold" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-kt-customer-table-filter="month" data-dropdown-parent="#kt-toolbar-filter">
                                                                        <option></option>
                                                                        <option value="aug">August</option>
                                                                        <option value="sep">September</option>
                                                                        <option value="oct">October</option>
                                                                        <option value="nov">November</option>
                                                                        <option value="dec">December</option>
                                                                    </select>

                                                                </div>


                                                                <div class="mb-10">

                                                                    <label class="form-label fs-5 fw-semibold mb-3">Payment Type:</label>


                                                                    <div class="d-flex flex-column flex-wrap fw-semibold" data-kt-customer-table-filter="payment_type">

                                                                        <label class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                                                                            <input class="form-check-input" type="radio" name="payment_type" value="all" checked="checked" />
                                                                            <span class="form-check-label text-gray-600">All</span>
                                                                        </label>


                                                                        <label class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                                                                            <input class="form-check-input" type="radio" name="payment_type" value="visa" />
                                                                            <span class="form-check-label text-gray-600">Visa</span>
                                                                        </label>


                                                                        <label class="form-check form-check-sm form-check-custom form-check-solid mb-3">
                                                                            <input class="form-check-input" type="radio" name="payment_type" value="mastercard" />
                                                                            <span class="form-check-label text-gray-600">Mastercard</span>
                                                                        </label>


                                                                        <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                                            <input class="form-check-input" type="radio" name="payment_type" value="american_express" />
                                                                            <span class="form-check-label text-gray-600">American Express</span>
                                                                        </label>

                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </form>

                                            :

                                            null
                                }


                            </div>

                        </div>

                        <div class="card-footer pt-4" id="kt_drawer_chat_messenger_footer" style={{ display: 'flex', justifyContent: 'end' }}>

                            {
                                loading_add_article ?
                                    <Loader />
                                    :
                                    <>
                                        <button onClick={handleCloseRightDrawer} type="reset" id="kt_modal_add_customer_cancel" class="btn btn-light me-3">Annuler</button>

                                        <button onClick={handleAddArticle} type="submit" id="kt_modal_add_customer_submit" class="btn btn-primary">
                                            <span class="indicator-label">Valider</span>
                                            <span class="indicator-progress">Please wait...
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                        </button>
                                    </>
                            }




                        </div>




                    </div>

                </div>
            </div>

        </Layout>
    )
};

export default Articles;