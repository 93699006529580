export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";

export const ADD_USER_START = "ADD_USER_START";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILED = "ADD_USER_FAILED";

export const SET_RIGHT_DRAWER = "SET_RIGHT_DRAWER";
export const SET_TYPE_DRAWER = "SET_TYPE_DRAWER";

export const SEARCH_USER_START = "SEARCH_USER_START";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_FAILED = "SEARCH_USER_FAILED";

export const GET_USER_PAGINATION_START = "GET_USER_PAGINATION_START";
export const GET_USER_PAGINATION_SUCCESS = "GET_USER_PAGINATION_SUCCESS";
export const GET_USER_PAGINATION_FAILED = "GET_USER_PAGINATION_FAILED";

export const GET_ETUDIANT_PAGINATION_START = "GET_ETUDIANT_PAGINATION_START";
export const GET_ETUDIANT_PAGINATION_SUCCESS = "GET_ETUDIANT_PAGINATION_SUCCESS";
export const GET_ETUDIANT_PAGINATION_FAILED = "GET_ETUDIANT_PAGINATION_FAILED";

export const GET_NON_ETUDIANT_PAGINATION_START = "GET_NON_ETUDIANT_PAGINATION_START";
export const GET_NON_ETUDIANT_PAGINATION_SUCCESS = "GET_NON_ETUDIANT_PAGINATION_SUCCESS";
export const GET_NON_ETUDIANT_PAGINATION_FAILED = "GET_NON_ETUDIANT_PAGINATION_FAILED";

export const GO_TO_USER_DETAIL = "GO_TO_USER_DETAIL";
export const GO_TO_USER_DETAIL_CANCEL = "GO_TO_USER_DETAIL_CANCEL";