import Decode from 'jwt-decode';

import { LOGIN_ADMIN_URL, UPDATE_PASSWORD_ADMIN_URL } from '../../urls/auth/auth';

import { LOGIN_ADMIN_START, LOGIN_ADMIN_SUCCESS, LOGIN_ADMIN_FAILED, LOGOUT ,
UPDATE_PASSWORD_ADMIN_START, UPDATE_PASSWORD_ADMIN_SUCCESS, UPDATE_PASSWORD_ADMIN_FAILED,
OPEN_UPDATE_PASSWORD_MODAL, CLOSE_UPDATE_PASSWORD_MODAL ,
} from '../../types/auth/auth';


export const checkLoginTimeOut = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logOut())
        }, expirationTime)
    }
}

export const logOut = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("expirationDate")
    localStorage.removeItem("role")

    return {
        type: LOGOUT
    }
}

export const loginAdminStart = () => {
    return {
        type: LOGIN_ADMIN_START
    }
}

export const loginAdminSuccess = (token, name, role) => {
    return {
        type: LOGIN_ADMIN_SUCCESS,
        token: token,
        name: name,
        role: role
    }
}

export const loginAdminFailed = (error) => {
    return {
        type: LOGIN_ADMIN_FAILED,
        error: error
    }
}

export const loginAdminService = (email, password) => {
    return dispatch => {
        dispatch(loginAdminStart())
        fetch(LOGIN_ADMIN_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "username": email,
                "password": password
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    var decoded = Decode(response.data.response)
                    console.log("decodedd==>>", decoded)
                    const expirationDate = new Date(new Date().getTime() + decoded.exp * 1000)

                    localStorage.setItem("token", response.data.response)
                    localStorage.setItem("expirationDate", expirationDate)
                    localStorage.setItem("role", "admin")
                    dispatch(loginAdminSuccess(response.data.response, "admin","admin"))
                    dispatch(checkLoginTimeOut(decoded.exp))
                }
                else{
                    dispatch(loginAdminFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(loginAdminFailed(error.message))
            })
    }

}

export const loginCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token')
        const role = localStorage.getItem('role')
        if(!token){
            dispatch(logOut())
        }
        else{
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if(expirationDate <= new Date()){
                dispatch(logOut())
            }
            else{
                dispatch(loginAdminSuccess(token ,"admin", role))
                dispatch(checkLoginTimeOut((expirationDate.getTime()- new Date().getTime())/ 1000))
            }
        }
    }
}

export const updatePasswordAdminStart = () => {
    return {
        type: UPDATE_PASSWORD_ADMIN_START
    }
}

export const updatePasswordAdminSuccess = () => {
    return {
        type: UPDATE_PASSWORD_ADMIN_SUCCESS,
       
    }
}

export const updatePasswordAdminFailed = (error_update_password) => {
    return {
        type: UPDATE_PASSWORD_ADMIN_FAILED,
        error_update_password: error_update_password,
    }
}

export const updatePasswordAdminService = (actualpassword, newpassword,token) => {
    let decoded = Decode(token)
    return dispatch => {
        dispatch(updatePasswordAdminStart())
        fetch(UPDATE_PASSWORD_ADMIN_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            },
            body: JSON.stringify({
                "userId": decoded.userdata[0].id,
                "actualpassword":actualpassword,
                "newpassword":newpassword
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(updatePasswordAdminSuccess())
                }
                else if(response.responseStatus === 400){
                    dispatch(updatePasswordAdminFailed("Les mots de passes sont differents!"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(updatePasswordAdminFailed("error server"))
            })
    }

}


export const closeUpdatePasswordModal = () => {
    return {
        type: CLOSE_UPDATE_PASSWORD_MODAL
    }
}

export const openUpdatePasswordModal = () => {
    return {
        type: OPEN_UPDATE_PASSWORD_MODAL
    }
}