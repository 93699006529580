import React, { useState } from 'react';
// IMPORT DEPENDENCIES
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginAdminService } from '../../actions/auth/auth';


import Loader from '../../components/loader/loader';

//IMPORT CSS

import '../../assets/css/login/plugins.bundle.css';
import '../../assets/css/login/style.bundle.css';

// IMPORT IMAGE
import Background from '../../assets/images/icon.png'

const Login = () => {

    const dispatch = useDispatch()

    const loading = useSelector((state) => state.AuthReducer.loading)

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("")
    const [error, setError] = useState(false)


    const handleEmail = (e) => {
        setEmail(e.target.value)
        setError(false)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
        setError(false)
    }


    const handleLogin = (e) => {
        e.preventDefault();
        if (email !== '' && email.trim() !== '' && password !== '' && password.trim() !== '') {
            dispatch(loginAdminService(email, password))
        }
        else {
            setError(true)
        }

    }

    return (
        <div id="kt_body" class="app-blank">
            <div class="d-flex flex-column flex-root" id="kt_app_root" >
                <div class="d-flex flex-column flex-lg-row flex-column-fluid" style={{ height: '100vh' }}>
                    <a href="../../demo29/dist/index.html" class="d-block d-lg-none mx-auto py-20">
                        <img alt="Logo" src="assets/media/logos/default.svg" class="theme-light-show h-25px" />
                        <img alt="Logo" src="assets/media/logos/default-dark.svg" class="theme-dark-show h-25px" />
                    </a>
                    <div class="d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10" >
                        <div class="d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px">

                            <div class="d-flex flex-stack py-2">
                                <div class="me-2"></div>
                            </div>
                            <div class="py-20" style={{ marginBottom: '250px' }}>
                                <form onSubmit={handleLogin} class="form w-100" novalidate="novalidate" id="kt_sign_in_form">
                                    <div class="card-body">

                                        <div class="text-start mb-10">

                                            <h1 class="text-dark mb-3 fs-3x" data-kt-translate="sign-in-title">Se connecter</h1>
                                        </div>

                                        <div class="fv-row mb-8">

                                            <input type="text" value={email} onChange={handleEmail} placeholder="Email" name="email" autocomplete="off" data-kt-translate="sign-in-input-email" class="form-control form-control-solid" />

                                        </div>

                                        <div class="fv-row mb-7">

                                            <input type="password" value={password} onChange={handlePassword} placeholder="Mot de passe" name="password" autocomplete="off" data-kt-translate="sign-in-input-password" class="form-control form-control-solid" />

                                        </div>

                                        {
                                            error ?

                                                <div> <p style={{ color: 'red' }}>Verifiez vos informations!</p> </div>
                                                : null
                                        }


                                        <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-10">
                                            <div></div>

                                            <a href="#" class="" data-kt-translate="sign-in-forgot-password" style={{ color: '#EF7400' }}>Mot de passe oublié ?</a>

                                        </div>

                                        <div class="d-flex flex-stack">
                                       
                                            {
                                                loading ?
                                                    <Loader />
                                                    :
                                                    <button id="kt_sign_in_submit" class="btn me-2 flex-shrink-0" style={{ backgroundColor: '#EF7400' }}>
                                                        <span class="indicator-label" data-kt-translate="sign-in-submit" style={{ color: 'white' }}>S'identifier</span>

                                                        {/* <span class="indicator-progress">
                                            <span data-kt-translate="general-progress">Please wait...</span>
                                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span> */}

                                                    </button>
                                            }



                                        </div>

                                    </div>

                                </form>

                            </div>


                        </div>
                    </div>
                    <div class="d-none d-lg-flex flex-lg-row-fluid w-100 bgi-position-y-center bgi-position-x-start bgi-no-repeat" style={{ backgroundImage: `url(${Background})` }}></div>
                </div>
            </div>
        </div>
    )
};

export default Login;