
import { GET_USER_URL , ADD_USER_URL, SEARCH_USER_URL, GET_USER_PAGINATION_URL, GET_ETUDIANT_PAGINATION_URL, 
    GET_NON_ETUDIANT_ETUDIANT_PAGINATION_URL} from '../../urls/user/user';

import { GET_USER_START, GET_USER_SUCCESS, GET_USER_FAILED, ADD_USER_START, 
    ADD_USER_SUCCESS, ADD_USER_FAILED, SET_TYPE_DRAWER, SET_RIGHT_DRAWER, 
    SEARCH_USER_START, SEARCH_USER_SUCCESS, SEARCH_USER_FAILED, GET_USER_PAGINATION_START,
     GET_USER_PAGINATION_SUCCESS, GET_USER_PAGINATION_FAILED , GET_ETUDIANT_PAGINATION_START,
      GET_ETUDIANT_PAGINATION_SUCCESS, GET_ETUDIANT_PAGINATION_FAILED, GET_NON_ETUDIANT_PAGINATION_START,
    GET_NON_ETUDIANT_PAGINATION_SUCCESS, GET_NON_ETUDIANT_PAGINATION_FAILED, GO_TO_USER_DETAIL, GO_TO_USER_DETAIL_CANCEL
    } from '../../types/user/user';


export const getUserStart = () => {
    return {
        type: GET_USER_START
    }
}

export const getUserSuccess = (userlist) => {
    return {
        type: GET_USER_SUCCESS,
        userlist: userlist
    }
}

export const getUserFailed = (error) => {
    return {
        type: GET_USER_FAILED,
        error: error
    }
}

export const getUserService = (token) => {
    return dispatch => {
        dispatch(getUserStart())
        fetch(GET_USER_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getUserSuccess(response.data.response))
                }
                else{
                    dispatch(getUserFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(getUserFailed(error.message))
            })
    }

}

export const addUserStart = () => {
    return {
        type: ADD_USER_START
    }
}

export const addUserSuccess = () => {
    return {
        type: ADD_USER_SUCCESS
    }
}

export const addUserFailed = (error) => {
    return {
        type: ADD_USER_FAILED,
        error: error
    }
}

export const addUserService = (email, nom , prenom , tel, status, token) => {
    return dispatch => {
        dispatch(addUserStart())
        fetch(ADD_USER_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            },
            body: JSON.stringify({
                "email": email,
                "nom": nom,
                "prenom": prenom,
                "tel":tel,
                "status":status
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(addUserSuccess())
                    window.location.reload();
                }
                else{
                    dispatch(addUserFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(addUserFailed(error.message))
            })
    }

}

export const setTypeDrawer = (value) => {
    return {
        type: SET_TYPE_DRAWER,
        value:value
    }
}

export const setRightDrawer = (value) => {
    return {
        type: SET_RIGHT_DRAWER,
        value:value
    }
}

export const searchUserStart = () => {
    return {
        type: SEARCH_USER_START
    }
}

export const searchUserSuccess = (userlist) => {
    return {
        type: SEARCH_USER_SUCCESS,
        userlist:userlist
    }
}

export const searchUserFailed = (error) => {
    return {
        type: SEARCH_USER_FAILED,
        error: error
    }
}

export const searchUserService = ( nom, token) => {
    return dispatch => {
        dispatch(searchUserStart())
        fetch(SEARCH_USER_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            },
            body: JSON.stringify({
                "nom": nom
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(searchUserSuccess(response.data.response))
                }
                else{
                    dispatch(searchUserFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(searchUserFailed(error.message))
            })
    }

}

export const getUserPaginationStart = () => {
    return {
        type: GET_USER_PAGINATION_START
    }
}

export const getUserPaginationSuccess = (userlist,currentPage, hasNextPage, hasPreviousPage, nexPage, previousPage, lastPage) => {
    return {
        type: GET_USER_PAGINATION_SUCCESS,
        userlist: userlist,
        currentPage:currentPage,
        hasNextPage:hasNextPage,
        hasPreviousPage:hasPreviousPage,
        nexPage:nexPage,
        previousPage:previousPage,
        lastPage:lastPage
    }
}

export const getUserPaginationFailed = (error) => {
    return {
        type: GET_USER_PAGINATION_FAILED,
        error: error
    }
}

export const getUserPaginationService = (page,token) => {
    return dispatch => {
        dispatch(getUserPaginationStart())
        fetch(GET_USER_PAGINATION_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            },
            body: JSON.stringify({
                "page": page
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getUserPaginationSuccess(response.data.response, response.data.currentPage, response.data.hasNextPage, response.data.hasPreviousPage, response.data.nexPage, response.data.previousPage, response.data.lastPage))
                }
                else{
                    dispatch(getUserPaginationFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(getUserPaginationFailed(error.message))
            })
    }

}

export const getEtudiantPaginationStart = () => {
    return {
        type: GET_ETUDIANT_PAGINATION_START
    }
}

export const getEtudiantPaginationSuccess = (etudiantlist,currentPage_etudiant, hasNextPage_etudiant, hasPreviousPage_etudiant, nexPage_etudiant, previousPage_etudiant, lastPage_etudiant) => {
    return {
        type: GET_ETUDIANT_PAGINATION_SUCCESS,
        etudiantlist: etudiantlist,
        currentPage_etudiant:currentPage_etudiant,
        hasNextPage_etudiant:hasNextPage_etudiant,
        hasPreviousPage_etudiant:hasPreviousPage_etudiant,
        nexPage_etudiant:nexPage_etudiant,
        previousPage_etudiant:previousPage_etudiant,
        lastPage_etudiant:lastPage_etudiant
    }
}

export const getEtudiantPaginationFailed = (error) => {
    return {
        type: GET_ETUDIANT_PAGINATION_FAILED,
        error: error
    }
}

export const getEtudiantPaginationService = (page,token) => {
    return dispatch => {
        dispatch(getEtudiantPaginationStart())
        fetch(GET_ETUDIANT_PAGINATION_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            },
            body: JSON.stringify({
                "page": page
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getEtudiantPaginationSuccess(response.data.response, response.data.currentPage, response.data.hasNextPage, response.data.hasPreviousPage, response.data.nexPage, response.data.previousPage, response.data.lastPage))
                }
                else{
                    dispatch(getEtudiantPaginationFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(getEtudiantPaginationFailed(error.message))
            })
    }

}

export const getNonEtudiantPaginationStart = () => {
    return {
        type: GET_NON_ETUDIANT_PAGINATION_START
    }
}

export const getNonEtudiantPaginationSuccess = (etudiantlist,currentPage_non_etudiant, hasNextPage_non_etudiant, hasPreviousPage_non_etudiant, nexPage_non_etudiant, previousPage_non_etudiant, lastPage_non_etudiant) => {
    return {
        type: GET_NON_ETUDIANT_PAGINATION_SUCCESS,
        etudiantlist: etudiantlist,
        currentPage_non_etudiant:currentPage_non_etudiant,
        hasNextPage_non_etudiant:hasNextPage_non_etudiant,
        hasPreviousPage_non_etudiant:hasPreviousPage_non_etudiant,
        nexPage_non_etudiant:nexPage_non_etudiant,
        previousPage_non_etudiant:previousPage_non_etudiant,
        lastPage_non_etudiant:lastPage_non_etudiant
    }
}

export const getNonEtudiantPaginationFailed = (error) => {
    return {
        type: GET_NON_ETUDIANT_PAGINATION_FAILED,
        error: error
    }
}

export const getNonEtudiantPaginationService = (page,token) => {
    return dispatch => {
        dispatch(getNonEtudiantPaginationStart())
        fetch(GET_NON_ETUDIANT_ETUDIANT_PAGINATION_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            },
            body: JSON.stringify({
                "page": page
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getNonEtudiantPaginationSuccess(response.data.response, response.data.currentPage, response.data.hasNextPage, response.data.hasPreviousPage, response.data.nexPage, response.data.previousPage, response.data.lastPage))
                }
                else{
                    dispatch(getNonEtudiantPaginationFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(getNonEtudiantPaginationFailed(error.message))
            })
    }

}

export const goToUserDetail = (userdetaildata) => {
    return {
        type: GO_TO_USER_DETAIL,
        userdetaildata:userdetaildata
      
    }
}

export const goToUserDetailCancel = () => {
    return {
        type: GO_TO_USER_DETAIL_CANCEL
    }
}