// IMPORT DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import Chart from "react-apexcharts";

import { useDispatch, useSelector } from 'react-redux';
import { getAppInfosService } from '../../actions/home/home';


// IMPORT CSS
import '../../assets/css/home/datatables.bundle.css';
import '../../assets/css/home/fullcalendar.bundle.css';
import '../../assets/css/home/plugins.bundle.css';
import '../../assets/css/home/style.bundle.css';


import Layout from '../../components/layout/layout';
import Loader from '../../components/loader/loader';


const Home = () => {

    const dispatch = useDispatch();

    const token = useSelector(state => state.AuthReducer.token)
    const loading = useSelector(state => state.HomeReducer.loading)
    const appinfos = useSelector(state => state.HomeReducer.appinfos)

    // const optionss = {
    //     enableHighAccuracy: true,
    //     timeout: 5000,
    //     maximumAge: 0,
    //   };
      
    //   function success(pos) {
    //     const crd = pos.coords;
      
    //     console.log("Your current position is:");
    //     console.log(`Latitude : ${crd.latitude}`);
    //     console.log(`Longitude: ${crd.longitude}`);
    //     console.log(`More or less ${crd.accuracy} meters.`);
    //   }
      
    //   function error(err) {
    //     console.warn(`ERROR(${err.code}): ${err.message}`);
    //   }

    useEffect(() => {
        dispatch(getAppInfosService(token))
       //window.navigator.geolocation.getCurrentPosition(success, error, optionss)
    }, []);

    const options = {
        colors: ['#546E7A', '#E91E63'],
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: false
            },
        },
        stroke: {
            curve: 'smooth',
        },
        xaxis: {
            categories: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Vend", "Sam"]
        },

    };
    const series = [

        {
            name: "series-3",
            data: [24, 20, 5, 75, 42, 79, 72]
        }
    ];


    const options2 = {
        xaxis: {
            categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
        }
    };
    const series2 = [

        {
            name: "series-1",
            data: [30, 40, 25, 50, 49, 21, 70, 51]
        },

        {
            name: "series-3",
            data: [24, 20, 5, 75, 42, 79, 72, 35]
        }
    ];


    return (
        <Layout openDrawer={false} >


            {
                loading ?
                    <div style={{ textAlign: 'center' }}>
                        <Loader />
                    </div>
                    :
                    <div id="kt_app_content" class="app-content flex-column-fluid">

                        <div id="kt_app_content_container" class="app-container container-xxl">

                            <div class="row gy-5 g-xl-10">

                                <div class="col-xl-12 mb-5 mb-xl-10">
                                    <div class="row g-lg-5 g-xl-10">
                                        <div class="col-xl-4 mb-5 mb-xl-10">

                                            <div class="card overflow-hidden mb-5 mb-xl-10">

                                                <div class="card-body d-flex justify-content-between flex-column px-0 pb-0">

                                                    <div class="mb-4 px-9">

                                                        <div class="d-flex align-items-center mb-2">

                                                            <span class="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">{appinfos.usercount}</span>


                                                            <span class="d-flex align-items-end text-gray-400 fs-6 fw-semibold">Utilisateurs</span>


                                                        </div>


                                                        <span class="fs-6 fw-semibold text-gray-400">Total utilisateurs</span>


                                                    </div>


                                                    <div id="kt_card_widget_12_chart" class="min-h-auto" style={{ height: "125px" }}>
                                                        <Chart options={options} series={series} type="rangeBar" height={120} />
                                                    </div>

                                                </div>

                                            </div>


                                            <div class="card card-flush h-md-50 mb-lg-10">

                                                <div class="card-header pt-5">

                                                    <div class="card-title d-flex flex-column">

                                                        <span class="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{appinfos.requetecount}</span>


                                                        <span class="text-gray-400 pt-1 fw-semibold fs-6">Rendez-vous</span>

                                                    </div>

                                                </div>


                                                <div class="card-body d-flex align-items-end pt-0">

                                                    <div class="d-flex align-items-center flex-wrap">

                                                        <div class="d-flex me-7 me-xxl-10">
                                                            <div id="kt_card_widget_10_chart" class="min-h-auto" data-kt-size="78" data-kt-line="11">
                                                                <Chart options={options} series={series} type="rangeArea" width={350} height={120} />
                                                            </div>
                                                        </div>


                                                        <div class="d-flex flex-column content-justify-center flex-grow-1">

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-xl-8 mb-5 mb-xl-10">
                                            <div class="row g-lg-5 g-xl-10">

                                                <div class="col-md-4 col-xl-6 mb-5 mb-xl-10">

                                                    <div class="card overflow-hidden h-md-50 mb-5 mb-xl-10">

                                                        <div class="card-body d-flex justify-content-between flex-column px-0 pb-0">

                                                            <div class="mb-4 px-9">

                                                                <div class="d-flex align-items-center mb-2">

                                                                    <span class="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">{appinfos.etudiantcount}</span>


                                                                    <span class="d-flex align-items-end text-gray-400 fs-6 fw-semibold">Etudiants</span>

                                                                </div>


                                                                <span class="fs-6 fw-semibold text-gray-400">Total étudiants</span>

                                                            </div>


                                                            <div id="kt_card_widget_12_chart" class="min-h-auto" style={{ height: "125px" }}>
                                                                <Chart options={options} series={series} height={120} width={'100%'} type="area" />
                                                            </div>

                                                        </div>

                                                    </div>


                                                    <div class="card card-flush h-md-50 mb-lg-10">

                                                        <div class="card-header pt-5">

                                                            <div class="card-title d-flex flex-column">

                                                                <span class="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{appinfos.requetecount}</span>


                                                                <span class="text-gray-400 pt-1 fw-semibold fs-6">Requetes</span>

                                                            </div>

                                                        </div>


                                                        <div class="card-body d-flex align-items-end pt-0">

                                                            <div class="d-flex align-items-center flex-wrap">

                                                                <div class="d-flex me-7 me-xxl-10">
                                                                    <div id="kt_card_widget_10_chart" class="min-h-auto" style={{ height: "125px" }} data-kt-size="78" data-kt-line="11">
                                                                        <Chart options={options} series={series} type="line" height={120} width={350} />
                                                                    </div>
                                                                </div>


                                                                <div class="d-flex flex-column content-justify-center flex-grow-1">


                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                                <div class="col-md-4 col-xl-6 mb-md-5 mb-xl-10">

                                                    <div class="card overflow-hidden h-md-50 mb-5 mb-xl-10">

                                                        <div class="card-body d-flex justify-content-between flex-column px-0 pb-0">

                                                            <div class="mb-4 px-9">

                                                                <div class="d-flex align-items-center mb-2">

                                                                    <span class="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">{appinfos.ressortissantcount}</span>



                                                                </div>


                                                                <span class="fs-6 fw-semibold text-gray-400">Ressortissants (Non -Etudiants)</span>

                                                            </div>


                                                            <div id="kt_card_widget_13_chart" class="min-h-auto" style={{ height: "125px" }}>
                                                                <Chart options={options} series={series} type="bar" height={120} />
                                                            </div>

                                                        </div>



                                                    </div>


                                                    <div class="card card-flush h-md-50 mb-lg-10">

                                                        <div class="card-header pt-5">

                                                            <div class="card-title d-flex flex-column">

                                                                <span class="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{appinfos.articlecount}</span>


                                                                <span class="text-gray-400 pt-1 fw-semibold fs-6">Articles</span>

                                                            </div>

                                                        </div>


                                                        <div class="card-body d-flex flex-column justify-content-end pe-0">

                                                            <span class="fs-6 fw-bolder text-gray-800 d-block mb-2">Articles envoyées</span>


                                                            <div class="symbol-group symbol-hover flex-nowrap">
                                                                <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Alan Warden">
                                                                    <span class="symbol-label bg-warning text-inverse-warning fw-bold">A</span>
                                                                </div>
                                                                <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Michael Eberon">
                                                                    {/* <img alt="Pic" src="assets/media/avatars/300-11.jpg" /> */}
                                                                </div>
                                                                <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Susan Redwood">
                                                                    <span class="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                                                                </div>
                                                                <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Melody Macy">
                                                                    {/* <img alt="Pic" src="assets/media/avatars/300-2.jpg" /> */}
                                                                </div>
                                                                <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Perry Matthew">
                                                                    <span class="symbol-label bg-danger text-inverse-danger fw-bold">P</span>
                                                                </div>
                                                                <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Barry Walter">
                                                                    {/* <img alt="Pic" src="assets/media/avatars/300-12.jpg" /> */}
                                                                </div>
                                                                <a href="#" class="symbol symbol-35px symbol-circle" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
                                                                    <span class="symbol-label bg-light text-gray-400 fs-8 fw-bold">+0</span>
                                                                </a>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row gy-5 g-xl-10">

                                <div class="col-xl-12 mb-5 mb-xl-10">

                                    <div class="col-xl-12">
                                        <div class="row h-xxl-50">

                                            <div class="col">

                                                <div class="card card-flush h-xxl-100">

                                                    <div class="card-header pt-7">

                                                        <h3 class="card-title align-items-start flex-column">
                                                            <span class="card-label fw-bold text-gray-800">Statistique general</span>
                                                            <span class="text-gray-400 mt-1 fw-semibold fs-6">3,567,457 projets</span>
                                                        </h3>

                                                        <div class="card-toolbar">

                                                            <div data-kt-daterangepicker="true" data-kt-daterangepicker-opens="left" class="btn btn-sm btn-light d-flex align-items-center px-4">

                                                                <div class="text-gray-600 fw-bold">Loading date range...</div>

                                                                <i class="ki-outline ki-calendar-8 fs-1 ms-2 me-0"></i>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div class="card-body d-flex flex-column justify-content-between pb-5 px-0">

                                                        <ul class="nav nav-pills nav-pills-custom mb-3 mx-9">

                                                            <li class="nav-item mb-3 me-3 me-lg-6">

                                                                <a class="nav-link btn btn-outline btn-flex btn-active-color-primary flex-column overflow-hidden w-120px h-85px pt-5 pb-2 active" data-bs-toggle="pill" id="kt_charts_widget_10_tab_1" href="#kt_charts_widget_10_tab_content_1">

                                                                    <div class="nav-icon mb-3">
                                                                        <i class="ki-outline ki-duotone ki-security-user  fs-1 p-0" style={{ color: '#EF7400' }}></i>
                                                                    </div>


                                                                    <span class="nav-text text-gray-800 fw-bold fs-6 lh-1">Utilisateurs</span>


                                                                    <span class="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>

                                                                </a>

                                                            </li>


                                                            <li class="nav-item mb-3 me-3 me-lg-6">

                                                                <a class="nav-link btn btn-outline btn-flex btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2" data-bs-toggle="pill" id="kt_charts_widget_10_tab_2" href="#kt_charts_widget_10_tab_content_2">

                                                                    <div class="nav-icon mb-3">
                                                                        <i class="ki-outline ki-duotone ki-document  fs-1 p-0"></i>
                                                                    </div>

                                                                    <span class="nav-text text-gray-800 fw-bold fs-6 lh-1">Etudiants</span>

                                                                    <span class="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>

                                                                </a>

                                                            </li>


                                                            <li class="nav-item mb-3 me-3 me-lg-6">

                                                                <a class="nav-link btn btn-outline btn-flex btn-active-color-primary flex-column overflow-hidden w-190px h-85px pt-5 pb-2" data-bs-toggle="pill" id="kt_charts_widget_10_tab_3" href="#kt_charts_widget_10_tab_content_3">

                                                                    <div class="nav-icon mb-3">
                                                                        <i class="ki-outline ki-duotone ki-bank fs-1 p-0"></i>
                                                                    </div>
                                                                    <span class="nav-text text-gray-800 fw-bold fs-6 lh-1">Ressortissants</span>
                                                                    <span class="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                                                                </a>

                                                            </li>


                                                            <li class="nav-item mb-3 me-3 me-lg-6">

                                                                <a class="nav-link btn btn-outline btn-flex btn-active-color-primary flex-column overflow-hidden w-105px h-85px pt-5 pb-2" data-bs-toggle="pill" id="kt_charts_widget_10_tab_4" href="#kt_charts_widget_10_tab_content_4">

                                                                    <div class="nav-icon mb-3">
                                                                        <i class="ki-outline ki-duotone ki-profile-user fs-1 p-0"></i>
                                                                    </div>


                                                                    <span class="nav-text text-gray-800 fw-bold fs-6 lh-1">Rendez-vous</span>


                                                                    <span class="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>

                                                                </a>

                                                            </li>

                                                        </ul>

                                                        <div class="tab-content ps-4 pe-6">

                                                            <div class="tab-pane fade active show" id="kt_charts_widget_10_tab_content_1">

                                                                <div id="kt_charts_widget_10_chart_1" class="min-h-auto" style={{ height: "270px" }}>
                                                                    <Chart options={options2} series={series2} type="area" height={180} />
                                                                </div>

                                                            </div>


                                                            <div class="tab-pane fade" id="kt_charts_widget_10_tab_content_2">

                                                                <div id="kt_charts_widget_10_chart_2" class="min-h-auto" style={{ height: "270px" }}></div>

                                                            </div>


                                                            <div class="tab-pane fade" id="kt_charts_widget_10_tab_content_3">

                                                                <div id="kt_charts_widget_10_chart_3" class="min-h-auto" style={{ height: "270px" }}></div>

                                                            </div>


                                                            <div class="tab-pane fade" id="kt_charts_widget_10_tab_content_4">

                                                                <div id="kt_charts_widget_10_chart_4" class="min-h-auto" style={{ height: "270px" }}></div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
            }


        </Layout>





    )
};
export default Home 
