
import {BASE_URL, BASE_UPLOAD_URL} from '../../urls/base/base';

export const GET_ARTICLE_URL =`${BASE_URL}/admin/getarticles`;
export const UPLOAD_ARTICLE_URL = `${BASE_UPLOAD_URL}/monambassade/uploadfile`;
export const ADD_ARTICLE_URL = `${BASE_URL}/admin/addarticle`;
export const SEARCH_ARTICLE_URL = `${BASE_URL}/admin/searcharticle`;
export const ARTICLE_VISIBILITE_URL =`${BASE_URL}/admin/articlevisibilite`;
export const GET_ARTICLE_PAGINATION_URL = `${BASE_URL}/admin/getarticlepagination`;
export const GET_ARTICLE_ETUDIANT_PAGINATION_URL = `${BASE_URL}/admin/getarticleetudiantpagination`;
export const GET_ARTICLE_RESSORTISSANT_PAGINATION_URL = `${BASE_URL}/admin/getarticleressortissantpagination`;