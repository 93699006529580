import * as actionsTypes from '../../types/home/home';

const initialState = {
    appinfos:[],
    loading: false
}

const reducerHome = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.GET_APP_INFOS_START:
            return {
                ...state,
                loading: true
            }

        case actionsTypes.GET_APP_INFOS_SUCCESS:
            return {
                ...state,
                loading: false,
                appinfos:action.appinfos,
                error: null
            }

        case actionsTypes.GET_APP_INFOS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state
    }
}

export default reducerHome