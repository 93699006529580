

import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import Layout from '../../components/layout/layout';

const GestionDesRoles = () => {
  return (
    <Layout openDrawer={true}>
      <div id="kt_app_content" class="app-content flex-column-fluid">

        <div id="kt_app_content_container" class="app-container container-xxl">

          <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">

            <div class="col-md-4">

              <div class="card card-flush h-md-100">

                <div class="card-header">

                  <div class="card-title">
                    <h2>Administrateur</h2>
                  </div>

                </div>


                <div class="card-body pt-1">

                  <div class="fw-bold text-gray-600 mb-5">Utilisateurs ayant ce role: 1</div>


                  <div class="d-flex flex-column text-gray-600">
                    <div class="d-flex align-items-center py-2">
                      <span class="bullet bg-primary me-3"></span>Tous les controls admin</div>
                    <div class="d-flex align-items-center py-2">
                      <span class="bullet bg-primary me-3"></span>Voir, Modifier et Supprimer des ressources</div>
                    <div class="d-flex align-items-center py-2">
                      <span class="bullet bg-primary me-3"></span>Creer des ressoucres pour tous le monde</div>
                    <div class="d-flex align-items-center py-2">
                      <span class="bullet bg-primary me-3"></span>Telecharger et exporter les rapports</div>
                    {/* <div class="d-flex align-items-center py-2">
                      <span class="bullet bg-primary me-3"></span>View and Edit Payouts</div>
                    <div class="d-flex align-items-center py-2">
                      <span class="bullet bg-primary me-3"></span>View and Edit Disputes</div>
                    <div class="d-flex align-items-center py-2">
                      <span class="bullet bg-primary me-3"></span>
                      <em>and 7 more...</em>
                    </div> */}
                  </div>

                </div>


                {/* <div class="card-footer flex-wrap pt-0">
                  <Link to="/setting-role-detail" class="btn my-1 me-2" style={{backgroundColor: "#8e736a", color: "#fff"}}>Voir le Role</Link>
                  <button type="button" class="btn btn-light btn-active-light-primary my-1" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">Modifier Role</button>
                </div> */}

              </div>

            </div>


            <div class="col-md-4">

              <div class="card card-flush h-md-100">

                <div class="card-header">

                  <div class="card-title">
                    <h2>Etudiant-Admin</h2>
                  </div>

                </div>


                <div class="card-body pt-1">

                  <div class="fw-bold text-gray-600 mb-5">Utilisateurs ayant ce role: 0</div>


                  <div class="d-flex flex-column text-gray-600">

                    <div class="d-flex align-items-center py-2">
                      <span class="bullet bg-primary me-3"></span>Voir et Modifier des ressources</div>
                    <div class="d-flex align-items-center py-2">
                      <span class="bullet bg-primary me-3"></span>Creer des ressoucres Etudiants uniquement</div>
                    <div class="d-flex align-items-center py-2">
                      <span class="bullet bg-primary me-3"></span>Telecharger et exporter les rapports</div>
                    {/* <div class="d-flex align-items-center py-2">
                      <span class="bullet bg-primary me-3"></span>View and Edit Payouts</div>
                    <div class="d-flex align-items-center py-2">
                      <span class="bullet bg-primary me-3"></span>View and Edit Disputes</div>
                    <div class="d-flex align-items-center py-2">
                      <span class="bullet bg-primary me-3"></span>
                      <em>and 7 more...</em>
                    </div> */}
                  </div>



                </div>


                <div class="card-footer flex-wrap pt-0">
                  <a href="#" class="btn my-1 me-2" style={{ backgroundColor: "#EF7400", color: "#fff" }}>Voir le Role</a>
                  <button type="button" class="btn btn-light btn-active-light-primary my-1" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">Modifier Role</button>
                </div>

              </div>

            </div>

            <div class="col-md-4">

              <div class="card card-flush h-md-100">

                <div class="card-header">

                  <div class="card-title">
                    <h2>Etudiant</h2>
                  </div>

                </div>


                <div class="card-body pt-1">

                  <div class="fw-bold text-gray-600 mb-5">Utilisateurs ayant ce role: 0</div>


                  <div class="d-flex flex-column text-gray-600">

                    <div class="d-flex align-items-center py-2">
                      <span class="bullet bg-primary me-3"></span>Access a l'application mobile uniquement
                    </div>
                    <div class="d-flex align-items-center py-2">

                      <span class="bullet bg-primary me-3"></span>Voir les ressoucres Etudiants uniquement</div>

                    {/* <div class="d-flex align-items-center py-2">
        <span class="bullet bg-primary me-3"></span>View and Edit Payouts</div>
      <div class="d-flex align-items-center py-2">
        <span class="bullet bg-primary me-3"></span>View and Edit Disputes</div>
      <div class="d-flex align-items-center py-2">
        <span class="bullet bg-primary me-3"></span>
        <em>and 7 more...</em>
      </div> */}
                  </div>



                </div>


                <div class="card-footer flex-wrap pt-0">
                  <a href="#" class="btn my-1 me-2" style={{ backgroundColor: "#EF7400", color: "#fff" }}>Voir le Role</a>
                  <button type="button" class="btn btn-light btn-active-light-primary my-1" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">Modifier Role</button>
                </div>

              </div>

            </div>

            <div class="col-md-4">

              <div class="card card-flush h-md-100">

                <div class="card-header">

                  <div class="card-title">
                    <h2>Non-Etudiant</h2>
                  </div>
                </div>
                <div class="card-body pt-1">

                  <div class="fw-bold text-gray-600 mb-5">Utilisateurs ayant ce role: 0</div>


                  <div class="d-flex flex-column text-gray-600">

                    <div class="d-flex align-items-center py-2">
                      <span class="bullet bg-primary me-3"></span>Access a l'application mobile uniquement
                    </div>
                    <div class="d-flex align-items-center py-2">

                      <span class="bullet bg-primary me-3"></span>Voir les ressoucres Ressortissants uniquement</div>

                    {/* <div class="d-flex align-items-center py-2">
<span class="bullet bg-primary me-3"></span>View and Edit Payouts</div>
<div class="d-flex align-items-center py-2">
<span class="bullet bg-primary me-3"></span>View and Edit Disputes</div>
<div class="d-flex align-items-center py-2">
<span class="bullet bg-primary me-3"></span>
<em>and 7 more...</em>
</div> */}
                  </div>



                </div>


                <div class="card-footer flex-wrap pt-0">
                  <a href="#" class="btn my-1 me-2" style={{ backgroundColor: "#EF7400", color: "#fff" }}>Voir le Role</a>
                  <button type="button" class="btn btn-light btn-active-light-primary my-1" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">Modifier Role</button>
                </div>

              </div>

            </div>


            <div class="col-md-4">

              <div class="card h-md-100">

                <div class="card-body d-flex flex-center">

                  <button type="button" class="btn btn-clear d-flex flex-column flex-center" data-bs-toggle="modal" data-bs-target="#kt_modal_add_role">

                    <img src="assets/media/illustrations/sketchy-1/4.png" alt="" class="mw-100 mh-150px mb-7" />


                    <div class="fw-bold fs-3 text-gray-600 text-hover-primary">Ajouter un nouveau role</div>

                  </button>

                </div>

              </div>

            </div>

          </div>



          <div class="modal fade" id="kt_modal_add_role" tabindex="-1" aria-hidden="true">

            <div class="modal-dialog modal-dialog-centered mw-750px">

              <div class="modal-content">

                <div class="modal-header">

                  <h2 class="fw-bold">Add a Role</h2>


                  <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-roles-modal-action="close">
                    <i class="ki-outline ki-cross fs-1"></i>
                  </div>

                </div>


                <div class="modal-body scroll-y mx-lg-5 my-7">


                  <form id="kt_modal_add_role_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">

                    <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_role_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_role_header" data-kt-scroll-wrappers="#kt_modal_add_role_scroll" data-kt-scroll-offset="300px" style={{ maxHeight: "477px" }}>

                      <div class="fv-row mb-10 fv-plugins-icon-container">

                        <label class="fs-5 fw-bold form-label mb-2">
                          <span class="required">Role name</span>
                        </label>


                        <input class="form-control form-control-solid" placeholder="Enter a role name" name="role_name" />

                        <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>


                      <div class="fv-row">

                        <label class="fs-5 fw-bold form-label mb-2">Role Permissions</label>


                        <div class="table-responsive">

                          <table class="table align-middle table-row-dashed fs-6 gy-5">

                            <tbody class="text-gray-600 fw-semibold">

                              <tr>
                                <td class="text-gray-800">Administrator Access
                                  <span class="ms-2" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="Allows a full access to the system" data-kt-initialized="1">
                                    <i class="ki-outline ki-information fs-7"></i>
                                  </span></td>
                                <td>

                                  <label class="form-check form-check-custom form-check-solid me-9">
                                    <input class="form-check-input" type="checkbox" value="" id="kt_roles_select_all" />
                                    <span class="form-check-label" for="kt_roles_select_all">Select all</span>
                                  </label>

                                </td>
                              </tr>


                              <tr>

                                <td class="text-gray-800">User Management</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="user_management_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="user_management_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="user_management_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">Content Management</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="content_management_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="content_management_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="content_management_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">Financial Management</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="financial_management_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="financial_management_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="financial_management_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">Reporting</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="reporting_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="reporting_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="reporting_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">Payroll</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="payroll_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="payroll_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="payroll_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">Disputes Management</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="disputes_management_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="disputes_management_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="disputes_management_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">API Controls</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="api_controls_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="api_controls_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="api_controls_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">Database Management</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="database_management_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="database_management_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="database_management_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">Repository Management</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="repository_management_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="repository_management_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-sm form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="repository_management_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>

                            </tbody>

                          </table>

                        </div>

                      </div>

                    </div>


                    <div class="text-center pt-15">
                      <button type="reset" class="btn btn-light me-3" data-kt-roles-modal-action="cancel">Discard</button>
                      <button type="submit" class="btn btn-primary" data-kt-roles-modal-action="submit">
                        <span class="indicator-label">Submit</span>
                        <span class="indicator-progress">Please wait...
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                      </button>
                    </div>

                  </form>

                </div>

              </div>

            </div>

          </div>


          <div class="modal fade" id="kt_modal_update_role" tabindex="-1" aria-hidden="true">

            <div class="modal-dialog modal-dialog-centered mw-750px">

              <div class="modal-content">

                <div class="modal-header">

                  <h2 class="fw-bold">Update Role</h2>


                  <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-roles-modal-action="close">
                    <i class="ki-outline ki-cross fs-1"></i>
                  </div>

                </div>


                <div class="modal-body scroll-y mx-5 my-7">

                  <form id="kt_modal_update_role_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">

                    <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_update_role_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_update_role_header" data-kt-scroll-wrappers="#kt_modal_update_role_scroll" data-kt-scroll-offset="300px" style={{ maxHeight: "477px" }}>

                      <div class="fv-row mb-10 fv-plugins-icon-container">

                        <label class="fs-5 fw-bold form-label mb-2">
                          <span class="required">Role name</span>
                        </label>


                        <input class="form-control form-control-solid" placeholder="Enter a role name" name="role_name" value="Developer" />

                        <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>


                      <div class="fv-row">

                        <label class="fs-5 fw-bold form-label mb-2">Role Permissions</label>


                        <div class="table-responsive">

                          <table class="table align-middle table-row-dashed fs-6 gy-5">

                            <tbody class="text-gray-600 fw-semibold">

                              <tr>
                                <td class="text-gray-800">Administrator Access
                                  <span class="ms-1" data-bs-toggle="tooltip" aria-label="Allows a full access to the system" data-bs-original-title="Allows a full access to the system" data-kt-initialized="1">
                                    <i class="ki-outline ki-information-5 text-gray-500 fs-6"></i>
                                  </span></td>
                                <td>

                                  <label class="form-check form-check-sm form-check-custom form-check-solid me-9">
                                    <input class="form-check-input" type="checkbox" value="" id="kt_roles_select_all" />
                                    <span class="form-check-label" for="kt_roles_select_all">Select all</span>
                                  </label>

                                </td>
                              </tr>


                              <tr>

                                <td class="text-gray-800">User Management</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="user_management_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="user_management_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="user_management_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">Content Management</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="content_management_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="content_management_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="content_management_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">Financial Management</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="financial_management_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="financial_management_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="financial_management_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">Reporting</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="reporting_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="reporting_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="reporting_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">Payroll</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="payroll_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="payroll_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="payroll_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">Disputes Management</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="disputes_management_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="disputes_management_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="disputes_management_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">API Controls</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="api_controls_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="api_controls_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="api_controls_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">Database Management</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="database_management_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="database_management_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="database_management_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>


                              <tr>

                                <td class="text-gray-800">Repository Management</td>


                                <td>

                                  <div class="d-flex">

                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="repository_management_read" />
                                      <span class="form-check-label">Read</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                      <input class="form-check-input" type="checkbox" value="" name="repository_management_write" />
                                      <span class="form-check-label">Write</span>
                                    </label>


                                    <label class="form-check form-check-custom form-check-solid">
                                      <input class="form-check-input" type="checkbox" value="" name="repository_management_create" />
                                      <span class="form-check-label">Create</span>
                                    </label>

                                  </div>

                                </td>

                              </tr>

                            </tbody>

                          </table>

                        </div>

                      </div>

                    </div>


                    <div class="text-center pt-15">
                      <button type="reset" class="btn btn-light me-3" data-kt-roles-modal-action="cancel">Discard</button>
                      <button type="submit" class="btn btn-primary" data-kt-roles-modal-action="submit">
                        <span class="indicator-label">Submit</span>
                        <span class="indicator-progress">Please wait...
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                      </button>
                    </div>

                  </form>

                </div>

              </div>

            </div>

          </div>


        </div>

      </div>
    </Layout>
  )
}

export default GestionDesRoles