import React, {} from 'react';
import Layout from '../../components/layout/layout';
const NotificationParticulier = () => {
   
    return(
        <Layout openDrawer={true}>
        <div>Hello world</div>
        </Layout>
    )
};
export default NotificationParticulier;