// IMPORT DEPENDENCIES
import React, { useEffect, useState } from 'react';


// IMPORT CSS
import '../../assets/css/home/datatables.bundle.css';
import '../../assets/css/home/fullcalendar.bundle.css';
import '../../assets/css/home/plugins.bundle.css';
import '../../assets/css/home/style.bundle.css';

// IMPORT COMPONENTS
import Navbar from '../../components/navbar/navbar';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';


// IMPORT IMAGES
import Logo from '../../assets/images/logo.png';


const Layout = ({children, openDrawer}) => {

    const [drawer, setDrawer] = useState(openDrawer)

    const handleDrawer = (newValue) => setDrawer(newValue)

    return (

        <div id="kt_app_body" class="app-default" data-kt-drawer-overlay="true" data-kt-drawer-width="auto" data-kt-app-sidebar-push-toolbar="true" data-kt-app-sidebar-stacked="true" data-kt-app-sidebar-push-header="true" data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle" data-kt-drawer-direction="start" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-name="app-sidebar" data-kt-app-header-fixed-mobile="true" data-kt-app-sidebar-enabled="true" data-kt-app-sidebar-fixed="true" data-kt-app-toolbar-enabled="true" >

            <div class="d-flex flex-column flex-root app-root" id="kt_app_root">

                <div class="app-page flex-column flex-column-fluid" id="kt_app_page">

                    <div id="kt_app_header" class="app-header d-flex d-lg-none" data-kt-sticky="true" data-kt-sticky-activate="{default: false, lg: true}" data-kt-sticky-name="app-header-sticky" data-kt-sticky-offset="{default: false, lg: '300px'}">

                        <div class="app-container container-xxl d-flex align-items-center justify-content-between" id="kt_app_header_container">

                            <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
                                <a href="../../demo29/dist/index.html">
                                    <img alt="Logo" src={Logo} class="h-30px" />
                                </a>
                            </div>

                            <div class="d-flex align-items-center d-lg-none ms-2 me-n3" title="Show sidebar menu">
                                <div class="btn btn-icon btn-color-white bg-white bg-opacity-0 bg-hover-opacity-10 w-35px h-35px" id="kt_app_sidebar_mobile_toggle">
                                    <i class="ki-outline ki-abstract-14 fs-1"></i>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">

                        <Navbar isDrawerOpen={drawer} onChangeDrawer={handleDrawer} />

                        <div class="app-main flex-column flex-row-fluid" id="kt_app_main" style={{ paddingLeft: drawer ? 18 + 'rem' : 0 }} >

                            <div class="d-flex flex-column flex-column-fluid">

                                <Header />

                                        {children}

                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};
export default Layout 
