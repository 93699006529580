
import React, { } from 'react';
import Layout from '../../components/layout/layout';
const RendezVousEtudiant = () => {

    return (
        <Layout openDrawer={true}>
            <div id="kt_app_content_container" class="app-container container-xxl">
                <div class="row gx-6 gx-xl-9">
                    <div class="col-lg-12">

                        <div class="card card-flush h-lg-100">

                            <div class="card-header mt-6">

                                <div class="card-title flex-column">
                                    <h3 class="fw-bold mb-1">Rendez-vous de la semaine (Etudiant)</h3>
                                    <div class="fs-6 text-gray-400">Total 2 rendez-vous</div>
                                </div>

                                <div class="card-toolbar">

                                    <select name="status" data-control="select2" data-hide-search="true" class="form-select form-select-solid form-select-sm fw-bold w-100px">
                                        <option value="1" selected="selected">Ajuster mes disponibilités</option>
                                        {/* <option value="2">Rendez-vous Etudiant</option>
                                        <option value="3">Rendez-vous Ressortissant</option> */}
                                       
                                    </select>

                                </div>

                            </div>

                            <div class="card-body p-9 pt-4">

                                <ul class="nav nav-pills d-flex flex-nowrap hover-scroll-x py-2">

                                    <li class="nav-item me-1">
                                        <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_0">
                                            <span class="opacity-50 fs-7 fw-semibold">Dim</span>
                                            <span class="fs-6 fw-bold">22</span>
                                        </a>
                                    </li>

                                    <li class="nav-item me-1">
                                        <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary active" data-bs-toggle="tab" href="#kt_schedule_day_1">
                                            <span class="opacity-50 fs-7 fw-semibold">Lun</span>
                                            <span class="fs-6 fw-bold">23</span>
                                        </a>
                                    </li>

                                    <li class="nav-item me-1">
                                        <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_2">
                                            <span class="opacity-50 fs-7 fw-semibold">Mar</span>
                                            <span class="fs-6 fw-bold">24</span>
                                        </a>
                                    </li>

                                    <li class="nav-item me-1">
                                        <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_3">
                                            <span class="opacity-50 fs-7 fw-semibold">Mer</span>
                                            <span class="fs-6 fw-bold">25</span>
                                        </a>
                                    </li>

                                    <li class="nav-item me-1">
                                        <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_4">
                                            <span class="opacity-50 fs-7 fw-semibold">Jeu</span>
                                            <span class="fs-6 fw-bold">26</span>
                                        </a>
                                    </li>

                                    <li class="nav-item me-1">
                                        <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_5">
                                            <span class="opacity-50 fs-7 fw-semibold">Ven</span>
                                            <span class="fs-6 fw-bold">27</span>
                                        </a>
                                    </li>

                                    <li class="nav-item me-1">
                                        <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_6">
                                            <span class="opacity-50 fs-7 fw-semibold">Sam</span>
                                            <span class="fs-6 fw-bold">28</span>
                                        </a>
                                    </li>

                                    <li class="nav-item me-1">
                                        <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_7">
                                            <span class="opacity-50 fs-7 fw-semibold">Dim</span>
                                            <span class="fs-6 fw-bold">29</span>
                                        </a>
                                    </li>

                                    <li class="nav-item me-1">
                                        <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_8">
                                            <span class="opacity-50 fs-7 fw-semibold">Lun</span>
                                            <span class="fs-6 fw-bold">30</span>
                                        </a>
                                    </li>

                                    <li class="nav-item me-1">
                                        <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_9">
                                            <span class="opacity-50 fs-7 fw-semibold">Mar</span>
                                            <span class="fs-6 fw-bold">31</span>
                                        </a>
                                    </li>

                                </ul>

                                <div class="tab-content">

                                    <div id="kt_schedule_day_0" class="tab-pane fade show">

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">13:00 - 14:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Rdv avec Desire Boutchoue</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Yannis Gloverson</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">12:00 - 13:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Development Team Capacity Review</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Kendell Trevor</a></div>

                                            </div>


                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">11:00 - 11:45
                                                    <span class="fs-7 text-gray-400 text-uppercase">am</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Marketing Campaign Discussion</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Peter Marcus</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                    </div>

                                    <div id="kt_schedule_day_1" class="tab-pane fade show active">

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">9:00 - 10:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">am</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Rendez-vous avec Florent Boutchoue</a>

                                                <div class="text-gray-400">dirrigé par
                                                    <a href="#"> L'ambassade</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">13:00 - 14:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Reunion avec l'association des Etudiants en Turquie</a>

                                                <div class="text-gray-400">dirrigé par
                                                    <a href="#"> L'ambassade</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">9:00 - 10:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">am</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Rendez-vous avec Ange Boutchoue</a>

                                                <div class="text-gray-400">dirrigé par
                                                    <a href="#"> L'ambassade</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                        </div>

                                    </div>

                                    <div id="kt_schedule_day_2" class="tab-pane fade show">

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">16:30 - 17:30
                                                    <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Creative Content Initiative</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Terry Robins</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">10:00 - 11:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">am</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Team Backlog Grooming Session</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Yannis Gloverson</a></div>

                                            </div>


                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">10:00 - 11:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">am</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Project Review & Testing</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Kendell Trevor</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                    </div>

                                    <div id="kt_schedule_day_3" class="tab-pane fade show">

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">9:00 - 10:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">am</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Committee Review Approvals</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Bob Harris</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">9:00 - 10:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">am</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Project Review & Testing</a>


                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Bob Harris</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">14:30 - 15:30
                                                    <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Development Team Capacity Review</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Karina Clarke</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                    </div>

                                    <div id="kt_schedule_day_4" class="tab-pane fade show">

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">11:00 - 11:45
                                                    <span class="fs-7 text-gray-400 text-uppercase">am</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Sales Pitch Proposal</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Mark Randall</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">14:30 - 15:30
                                                    <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Committee Review Approvals</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Caleb Donaldson</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">11:00 - 11:45
                                                    <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Dashboard UI/UX Design Review</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Mark Randall</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                    </div>

                                    <div id="kt_schedule_day_5" class="tab-pane fade show">

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">9:00 - 10:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">am</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Marketing Campaign Discussion</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Peter Marcus</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">14:30 - 15:30
                                                    <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Sales Pitch Proposal</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Mark Randall</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">9:00 - 10:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">am</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Weekly Team Stand-Up</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Sean Bean</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                    </div>

                                    <div id="kt_schedule_day_6" class="tab-pane fade show">

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">9:00 - 10:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">am</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">9 Degree Project Estimation Meeting</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Naomi Hayabusa</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">13:00 - 14:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Team Backlog Grooming Session</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Naomi Hayabusa</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">10:00 - 11:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">am</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Committee Review Approvals</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Yannis Gloverson</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                    </div>

                                    <div id="kt_schedule_day_7" class="tab-pane fade show">

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">12:00 - 13:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Project Review & Testing</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Caleb Donaldson</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">12:00 - 13:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Team Backlog Grooming Session</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Karina Clarke</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">9:00 - 10:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">am</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Team Backlog Grooming Session</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Sean Bean</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                    </div>

                                    <div id="kt_schedule_day_8" class="tab-pane fade show">

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">14:30 - 15:30
                                                    <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Sales Pitch Proposal</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Karina Clarke</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">12:00 - 13:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">9 Degree Project Estimation Meeting</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Naomi Hayabusa</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">14:30 - 15:30
                                                    <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">9 Degree Project Estimation Meeting</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">David Stevenson</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                    </div>

                                    <div id="kt_schedule_day_9" class="tab-pane fade show">

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">9:00 - 10:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">am</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Lunch & Learn Catch Up</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">David Stevenson</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">13:00 - 14:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Project Review & Testing</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Karina Clarke</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                        <div class="d-flex flex-stack position-relative mt-8">

                                            <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>

                                            <div class="fw-semibold ms-5 text-gray-600">

                                                <div class="fs-5">13:00 - 14:00
                                                    <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>

                                                <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Development Team Capacity Review</a>

                                                <div class="text-gray-400">Lead by
                                                    <a href="#">Karina Clarke</a></div>

                                            </div>

                                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    )
};
export default RendezVousEtudiant;