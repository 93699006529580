
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openUpdatePasswordModal, closeUpdatePasswordModal, updatePasswordAdminService } from '../../actions/auth/auth';
import Layout from '../../components/layout/layout';
import User from '../../assets/images/avatar.png';
import Loader from '../../components/loader/loader';

const Settings = () => {

  const dispatch = useDispatch();

  const token = useSelector(state => state.AuthReducer.token);
  const loading_update = useSelector(state => state.AuthReducer.loading_update);
  const error_update_password = useSelector(state => state.AuthReducer.error_update_password);
  const showupdatepassword = useSelector(state => state.AuthReducer.showupdatepassword);



  useEffect(() => {
    setSecurity(true)
  }, []);

  const [security, setSecurity] = useState(false);
  const [eventLog, setEventLog] = useState(false);

  const [actualPassword, setActualPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [error, setError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const handleEventLog = () => {
    setEventLog(true);
    setSecurity(false);
  }

  const handleSecurity = () => {
    setSecurity(true);
    setEventLog(false);
  }

  const handleCloseUpdateModal = () => {
    dispatch(closeUpdatePasswordModal())
  }

  const handleOpenUpdateModal = () => {
    dispatch(openUpdatePasswordModal())
  }


  const handleActualPassword = (e) => {
    setActualPassword(e.target.value)
    console.log(e.target.value)
    setError(false)
  }

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value)
    setError(false)
  }

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value)
    setError(false)
  }

  const handleShowPassord = () => {
    setShowPassword(!showPassword)
  }

  const handleChangePassword = (e) => {
    e.preventDefault()
    if (newPassword !== "" && newPassword.trim() !== "" && actualPassword !== "" && actualPassword.trim() !== "" && confirmPassword !== "" && confirmPassword.trim() !== "" && newPassword === confirmPassword) {
      dispatch(updatePasswordAdminService(actualPassword, newPassword, token))
    }
    else {
      setError(true)
    }

  }


  return (
    <Layout openDrawer={true}>
      <div id="kt_app_content" class="app-content flex-column-fluid">

        <div id="kt_app_content_container" class="app-container container-xxl">

          <div class="d-flex flex-column flex-lg-row">

            <div class="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">

              <div class="card mb-5 mb-xl-8">

                <div class="card-body">

                  <div class="d-flex flex-center flex-column py-5">

                    <div class="symbol symbol-100px symbol-circle mb-7">
                      <img src={User} alt="image" />
                    </div>

                    <a href="#" class="fs-3 text-gray-800 fw-bold mb-3" >Compte Ambassade</a>

                    <div class="mb-9">

                      <div class="badge badge-lg  d-inline" style={{ backgroundColor: "#EF7400", color: "#fff" }}>Administrateur</div>

                    </div>



                    <div class="fw-bold mb-3">Statistiques
                      <span class="ms-2" ddata-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="Number of support tickets assigned, closed and pending this week.">
                        <i class="ki-outline ki-information fs-7"></i>
                      </span></div>

                    <div class="d-flex flex-wrap flex-center">

                      <div class="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                        <div class="fs-4 fw-bold text-gray-700">
                          <span class="w-75px">0</span>
                          <i class="ki-outline ki-arrow-up fs-3 text-success"></i>
                        </div>
                        <div class="fw-semibold text-muted">Actions</div>
                      </div>


                      <div class="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                        <div class="fs-4 fw-bold text-gray-700">
                          <span class="w-50px">0</span>
                          <i class="ki-outline ki-arrow-down fs-3 text-danger"></i>
                        </div>
                        <div class="fw-semibold text-muted">Crash</div>
                      </div>


                      <div class="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                        <div class="fs-4 fw-bold text-gray-700">
                          <span class="w-50px">0</span>
                          <i class="ki-outline ki-arrow-up fs-3 text-success"></i>
                        </div>
                        <div class="fw-semibold text-muted">Moyenne</div>
                      </div>

                    </div>

                  </div>

                  <div class="d-flex flex-stack fs-4 py-3">
                    <div class="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#kt_user_view_details" role="button" aria-expanded="false" aria-controls="kt_user_view_details">Detail
                      <span class="ms-2 rotate-180">
                        <i class="ki-outline ki-down fs-3"></i>
                      </span></div>
                    {/* <span data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title="Edit customer details" data-kt-initialized="1">
                      <a href="#" class="btn btn-sm" data-bs-toggle="modal" data-bs-target="#kt_modal_update_details" style={{ backgroundColor: "#EF7400", color: "#fff" }}>Modifier</a>
                    </span> */}
                  </div>

                  <div class="separator"></div>

                  <div id="kt_user_view_details" class="collapse show">
                    <div class="pb-5 fs-6">

                      <div class="fw-bold mt-5">Account ID</div>
                      <div class="text-gray-600">ID-45453423</div>


                      <div class="fw-bold mt-5">Email</div>
                      <div class="text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">admin@monambassade.ci</a>
                      </div>


                      <div class="fw-bold mt-5">Address</div>
                      <div class="text-gray-600">Ankara,
                        <br />Turquie</div>


                      <div class="fw-bold mt-5">Langue</div>
                      <div class="text-gray-600">Français</div>


                      <div class="fw-bold mt-5">Derniere connexion</div>
                      <div class="text-gray-600">24 Jun 2023, 5:20 pm</div>

                    </div>
                  </div>

                </div>

              </div>




            </div>


            <div class="flex-lg-row-fluid ms-lg-15">

              <ul class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8" role="tablist">

                {/* <li class="nav-item" role="presentation">
                    <a class="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#kt_user_view_overview_tab" aria-selected="true" role="tab">Overview</a>
                  </li> */}


                <li class="nav-item" role="presentation" onClick={() => handleSecurity()} style={{ cursor: 'pointer' }}>
                  <span class={`nav-link text-active-primary pb-4 ${security ? 'active' : ''}`} data-kt-countup-tabs="true" data-bs-toggle="tab" data-kt-initialized="1" aria-selected="false" tabindex="-1" role="tab">Sécurité</span>
                </li>


                <li class="nav-item" role="presentation" onClick={() => handleEventLog()} style={{ cursor: 'pointer' }}>
                  <span class={`nav-link text-active-primary pb-4 ${eventLog ? 'active' : ''}`} data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">Evenements &amp; Logs</span>
                </li>


                <li class="nav-item ms-auto" style={{ backgroundColor: "#8e736a" }}>



                </li>

              </ul>


              <div class="tab-content" id="myTabContent">




                <div class={`tab-pane fade ${security ? "show active" : ''}`} id="kt_user_view_overview_security" role="tabpanel">

                  <div class="card pt-4 mb-6 mb-xl-9">

                    <div class="card-header border-0">

                      <div class="card-title">
                        <h2>Profile</h2>
                      </div>

                    </div>


                    <div class="card-body pt-0 pb-5">

                      <div class="table-responsive">

                        <table class="table align-middle table-row-dashed gy-5" id="kt_table_users_login_session">
                          <tbody class="fs-6 fw-semibold text-gray-600">
                            <tr>
                              <td>Email</td>
                              <td>admin@monambassade.ci</td>
                              <td class="text-end">
                                <button type="button" class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" data-bs-toggle="modal" data-bs-target="#kt_modal_update_email">
                                  {/* <i class="ki-outline ki-pencil fs-3"></i> */}
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td>Mot de passe</td>
                              <td>******</td>
                              <td class="text-end">
                                <button onClick={handleOpenUpdateModal} type="button" class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" data-bs-toggle="modal" data-bs-target="#kt_modal_update_password">
                                  <i class="ki-outline ki-pencil fs-3"></i>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td>Role</td>
                              <td>Administrateur</td>
                              <td class="text-end">
                                {/* <button type="button" class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">
                                    <i class="ki-outline ki-pencil fs-3"></i>
                                  </button> */}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                      </div>

                    </div>

                  </div>


                  <div class="card pt-4 mb-6 mb-xl-9">

                    <div class="card-header border-0">

                      <div class="card-title flex-column">
                        <h2 class="mb-1">Deux Facteurs Authentification</h2>
                        <div class="fs-6 fw-semibold text-muted">Gardez votre compte encore plus sécurisé avec une deuxième méthode d'authentification.</div>
                      </div>


                      <div class="card-toolbar">

                        <button type="button" class="btn btn-sm" style={{ backgroundColor: "#EF7400", color: "#fff" }} data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                          <i class="ki-outline ki-fingerprint-scanning fs-3" style={{ color: "#fff" }}></i>Ajouter</button>

                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-6 w-200px py-4" data-kt-menu="true">

                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_add_auth_app">Use authenticator app</a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_add_one_time_password">Enable one-time password</a>
                          </div>

                        </div>


                      </div>

                    </div>


                    <div class="card-body pb-5">

                      <div class="d-flex flex-stack">

                        <div class="d-flex flex-column">
                          <span>SMS</span>
                          <span class="text-muted fs-6">+90 539 246 2904</span>
                        </div>


                        <div class="d-flex justify-content-end align-items-center">

                          <button type="button" class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto me-5" data-bs-toggle="modal" data-bs-target="#kt_modal_add_one_time_password">
                            <i class="ki-outline ki-pencil fs-3"></i>
                          </button>


                          <button type="button" class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" id="kt_users_delete_two_step">
                            <i class="ki-outline ki-trash fs-3"></i>
                          </button>

                        </div>

                      </div>


                      <div class="separator separator-dashed my-5"></div>


                      <div class="text-gray-600">Si vous perdez votre appareil mobile ou votre clé de sécurité, vous pouvez
                        <a href="#" class="me-1">générer un code de secours</a>pour vous connecter à votre compte.</div>

                    </div>

                  </div>


                </div>


                <div class={`tab-pane fade ${eventLog ? "show active" : ''}`} id="kt_user_view_overview_events_and_logs_tab" role="tabpanel">

                  <div class="card pt-4 mb-6 mb-xl-9">

                    <div class="card-header border-0">

                      <div class="card-title">
                        <h2>Login Sessions</h2>
                      </div>


                      <div class="card-toolbar">

                        <button type="button" class="btn btn-sm btn-flex" style={{ backgroundColor: "#EF7400", color: "#fff" }} id="kt_modal_sign_out_sesions">
                          <i class="ki-outline ki-entrance-right fs-3"></i>Deconexion des sessions</button>

                      </div>

                    </div>


                    <div class="card-body pt-0 pb-5">

                      <div class="table-responsive">

                        <table class="table align-middle table-row-dashed gy-5" id="kt_table_users_login_session">
                          <thead class="border-bottom border-gray-200 fs-7 fw-bold">
                            <tr class="text-start text-muted text-uppercase gs-0">
                              <th class="min-w-100px">Location</th>
                              <th>Device</th>
                              <th>IP Address</th>
                              <th class="min-w-125px">Time</th>
                              <th class="min-w-70px">Actions</th>
                            </tr>
                          </thead>
                          <tbody class="fs-6 fw-semibold text-gray-600">
                            <tr>
                              <td>Australia</td>
                              <td>Chome - Windows</td>
                              <td>207.11.26.178</td>
                              <td>23 seconds ago</td>
                              <td>Current session</td>
                            </tr>

                          </tbody>
                        </table>

                      </div>

                    </div>

                  </div>


                  <div class="card pt-4 mb-6 mb-xl-9">

                    <div class="card-header border-0">

                      <div class="card-title">
                        <h2>Logs</h2>
                      </div>


                      <div class="card-toolbar">

                        <button type="button" class="btn btn-sm" style={{ backgroundColor: "#EF7400", color: "#fff" }}>
                          <i class="ki-outline ki-cloud-download fs-3"></i>Télécharger le Report</button>

                      </div>

                    </div>


                    <div class="card-body py-0">

                      <div class="table-responsive">

                        <table class="table align-middle table-row-dashed fw-semibold text-gray-600 fs-6 gy-5" id="kt_table_users_logs">
                          <tbody>
                            <tr>
                              <td class="min-w-70px">
                                <div class="badge badge-light-warning">404 WRN</div>
                              </td>
                              <td>POST /v1/customer/c_64b77c7dea947/not_found</td>
                              <td class="pe-0 text-end min-w-200px">20 Jun 2023, 2:40 pm</td>
                            </tr>

                          </tbody>
                        </table>

                      </div>

                    </div>

                  </div>


                  <div class="card pt-4 mb-6 mb-xl-9">

                    <div class="card-header border-0">

                      <div class="card-title">
                        <h2>Evenements</h2>
                      </div>


                      <div class="card-toolbar">

                        <button type="button" class="btn btn-sm" style={{ backgroundColor: "#EF7400", color: "#fff" }}>
                          <i class="ki-outline ki-cloud-download fs-3"></i>Télécharger Report</button>

                      </div>

                    </div>


                    <div class="card-body py-0">

                      <table class="table align-middle table-row-dashed fs-6 text-gray-600 fw-semibold gy-5" id="kt_table_customers_events">
                        <tbody>
                          <tr>
                            <td class="min-w-400px">
                              <a href="#" class="text-gray-600 text-hover-primary me-1">Systeme</a> Ok
                              <a href="#" class="fw-bold text-gray-900 text-hover-primary"> Pas d'erreur signalée</a></td>
                            <td class="pe-0 text-gray-600 text-end min-w-200px">10 Nov 2023, 2:40 pm</td>
                          </tr>
                          {/* <tr>
                              <td class="min-w-400px">
                                <a href="#" class="text-gray-600 text-hover-primary me-1">Brian Cox</a>has made payment to
                                <a href="#" class="fw-bold text-gray-900 text-hover-primary">#OLP-45690</a></td>
                              <td class="pe-0 text-gray-600 text-end min-w-200px">20 Dec 2023, 6:05 pm</td>
                            </tr> */}

                        </tbody>
                      </table>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>



          <div class="modal fade" id="kt_modal_update_details" tabindex="-1" aria-hidden="true">

            <div class="modal-dialog modal-dialog-centered mw-650px">

              <div class="modal-content">

                <form class="form" action="#" id="kt_modal_update_user_form">

                  <div class="modal-header" id="kt_modal_update_user_header">

                    <h2 class="fw-bold">Update User Details</h2>


                    <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                      <i class="ki-outline ki-cross fs-1"></i>
                    </div>

                  </div>


                  <div class="modal-body py-10 px-lg-17">

                    <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_update_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_update_user_header" data-kt-scroll-wrappers="#kt_modal_update_user_scroll" data-kt-scroll-offset="300px" style={{ maxHeight: "477px" }}>

                      <div class="fw-bolder fs-3 rotate collapsible mb-7" data-bs-toggle="collapse" href="#kt_modal_update_user_user_info" role="button" aria-expanded="false" aria-controls="kt_modal_update_user_user_info">User Information
                        <span class="ms-2 rotate-180">
                          <i class="ki-outline ki-down fs-3"></i>
                        </span></div>


                      <div id="kt_modal_update_user_user_info" class="collapse show">

                        <div class="mb-7">

                          <label class="fs-6 fw-semibold mb-2">
                            <span>Update Avatar</span>
                            <span class="ms-1" data-bs-toggle="tooltip" aria-label="Allowed file types: png, jpg, jpeg." data-bs-original-title="Allowed file types: png, jpg, jpeg." data-kt-initialized="1">
                              <i class="ki-outline ki-information fs-7"></i>
                            </span>
                          </label>


                          <div class="mt-1">

                            {/* <style>.image-input-placeholder {background - image: url('assets/media/svg/avatars/blank.svg'); } [data-bs-theme="dark"] .image-input-placeholder {background - image: url('assets/media/svg/avatars/blank-dark.svg'); }</style> */}


                            <div class="image-input image-input-outline image-input-placeholder" data-kt-image-input="true">

                              <div class="image-input-wrapper w-125px h-125px" style={{ backgroundImage: "url(assets/media/avatars/300-6.jpg" }}></div>


                              <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" aria-label="Change avatar" data-bs-original-title="Change avatar" data-kt-initialized="1">
                                <i class="ki-outline ki-pencil fs-7"></i>

                                <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                <input type="hidden" name="avatar_remove" />

                              </label>


                              <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" aria-label="Cancel avatar" data-bs-original-title="Cancel avatar" data-kt-initialized="1">
                                <i class="ki-outline ki-cross fs-2"></i>
                              </span>


                              <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" aria-label="Remove avatar" data-bs-original-title="Remove avatar" data-kt-initialized="1">
                                <i class="ki-outline ki-cross fs-2"></i>
                              </span>

                            </div>

                          </div>

                        </div>


                        <div class="fv-row mb-7">

                          <label class="fs-6 fw-semibold mb-2">Name</label>


                          <input type="text" class="form-control form-control-solid" placeholder="" name="name" value="Emma Smith" />

                        </div>


                        <div class="fv-row mb-7">

                          <label class="fs-6 fw-semibold mb-2">
                            <span>Email</span>
                            <span class="ms-1" data-bs-toggle="tooltip" aria-label="Email address must be active" data-bs-original-title="Email address must be active" data-kt-initialized="1">
                              <i class="ki-outline ki-information fs-7"></i>
                            </span>
                          </label>


                          <input type="email" class="form-control form-control-solid" placeholder="" name="email" value="smith@kpmg.com" />

                        </div>


                        <div class="fv-row mb-7">

                          <label class="fs-6 fw-semibold mb-2">Description</label>


                          <input type="text" class="form-control form-control-solid" placeholder="" name="description" />

                        </div>


                        <div class="fv-row mb-15">

                          <label class="fs-6 fw-semibold mb-2">Language</label>


                          <select name="language" aria-label="Select a Language" data-control="select2" data-placeholder="Select a Language..." class="form-select form-select-solid select2-hidden-accessible" data-dropdown-parent="#kt_modal_update_details" data-select2-id="select2-data-18-h0u5" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
                            <option data-select2-id="select2-data-20-qd1g"></option>
                            <option value="id">Bahasa Indonesia - Indonesian</option>
                            <option value="msa">Bahasa Melayu - Malay</option>
                            <option value="ca">Català - Catalan</option>
                            <option value="cs">Čeština - Czech</option>
                            <option value="da">Dansk - Danish</option>
                            <option value="de">Deutsch - German</option>
                            <option value="en">English</option>
                            <option value="en-gb">English UK - British English</option>
                            <option value="es">Español - Spanish</option>
                            <option value="fil">Filipino</option>
                            <option value="fr">Français - French</option>
                            <option value="ga">Gaeilge - Irish (beta)</option>
                            <option value="gl">Galego - Galician (beta)</option>
                            <option value="hr">Hrvatski - Croatian</option>
                            <option value="it">Italiano - Italian</option>
                            <option value="hu">Magyar - Hungarian</option>
                            <option value="nl">Nederlands - Dutch</option>
                            <option value="no">Norsk - Norwegian</option>
                            <option value="pl">Polski - Polish</option>
                            <option value="pt">Português - Portuguese</option>
                            <option value="ro">Română - Romanian</option>
                            <option value="sk">Slovenčina - Slovak</option>
                            <option value="fi">Suomi - Finnish</option>
                            <option value="sv">Svenska - Swedish</option>
                            <option value="vi">Tiếng Việt - Vietnamese</option>
                            <option value="tr">Türkçe - Turkish</option>
                            <option value="el">Ελληνικά - Greek</option>
                            <option value="bg">Български език - Bulgarian</option>
                            <option value="ru">Русский - Russian</option>
                            <option value="sr">Српски - Serbian</option>
                            <option value="uk">Українська мова - Ukrainian</option>
                            <option value="he">עִבְרִית - Hebrew</option>
                            <option value="ur">اردو - Urdu (beta)</option>
                            <option value="ar">العربية - Arabic</option>
                            <option value="fa">فارسی - Persian</option>
                            <option value="mr">मराठी - Marathi</option>
                            <option value="hi">हिन्दी - Hindi</option>
                            <option value="bn">বাংলা - Bangla</option>
                            <option value="gu">ગુજરાતી - Gujarati</option>
                            <option value="ta">தமிழ் - Tamil</option>
                            <option value="kn">ಕನ್ನಡ - Kannada</option>
                            <option value="th">ภาษาไทย - Thai</option>
                            <option value="ko">한국어 - Korean</option>
                            <option value="ja">日本語 - Japanese</option>
                            <option value="zh-cn">简体中文 - Simplified Chinese</option>
                            <option value="zh-tw">繁體中文 - Traditional Chinese</option>
                          </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-19-tceo" style={{ width: "100%" }}><span class="selection"><span class="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-language-83-container" aria-controls="select2-language-83-container"><span class="select2-selection__rendered" id="select2-language-83-container" role="textbox" aria-readonly="true" title="Select a Language..."><span class="select2-selection__placeholder">Select a Language...</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>

                        </div>

                      </div>


                      <div class="fw-bolder fs-3 rotate collapsible mb-7" data-bs-toggle="collapse" href="#kt_modal_update_user_address" role="button" aria-expanded="false" aria-controls="kt_modal_update_user_address">Address Details
                        <span class="ms-2 rotate-180">
                          <i class="ki-outline ki-down fs-3"></i>
                        </span></div>


                      <div id="kt_modal_update_user_address" class="collapse show">

                        <div class="d-flex flex-column mb-7 fv-row">

                          <label class="fs-6 fw-semibold mb-2">Address Line 1</label>


                          <input class="form-control form-control-solid" placeholder="" name="address1" value="101, Collins Street" />

                        </div>


                        <div class="d-flex flex-column mb-7 fv-row">

                          <label class="fs-6 fw-semibold mb-2">Address Line 2</label>


                          <input class="form-control form-control-solid" placeholder="" name="address2" />

                        </div>


                        <div class="d-flex flex-column mb-7 fv-row">

                          <label class="fs-6 fw-semibold mb-2">Town</label>


                          <input class="form-control form-control-solid" placeholder="" name="city" value="Melbourne" />

                        </div>


                        <div class="row g-9 mb-7">

                          <div class="col-md-6 fv-row">

                            <label class="fs-6 fw-semibold mb-2">State / Province</label>


                            <input class="form-control form-control-solid" placeholder="" name="state" value="Victoria" />

                          </div>


                          <div class="col-md-6 fv-row">

                            <label class="fs-6 fw-semibold mb-2">Post Code</label>


                            <input class="form-control form-control-solid" placeholder="" name="postcode" value="3000" />

                          </div>

                        </div>


                        <div class="d-flex flex-column mb-7 fv-row">

                          <label class="fs-6 fw-semibold mb-2">
                            <span>Country</span>
                            <span class="ms-1" data-bs-toggle="tooltip" aria-label="Country of origination" data-bs-original-title="Country of origination" data-kt-initialized="1">
                              <i class="ki-outline ki-information fs-7"></i>
                            </span>
                          </label>


                          <select name="country" aria-label="Select a Country" data-control="select2" data-placeholder="Select a Country..." class="form-select form-select-solid select2-hidden-accessible" data-dropdown-parent="#kt_modal_update_details" data-select2-id="select2-data-21-f7zs" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
                            <option value="" data-select2-id="select2-data-23-xcha">Select a Country...</option>
                            <option value="AF">Afghanistan</option>
                            <option value="AX">Aland Islands</option>
                            <option value="AL">Albania</option>
                            <option value="DZ">Algeria</option>
                            <option value="AS">American Samoa</option>
                            <option value="AD">Andorra</option>
                            <option value="AO">Angola</option>
                            <option value="AI">Anguilla</option>
                            <option value="AG">Antigua and Barbuda</option>
                            <option value="AR">Argentina</option>
                            <option value="AM">Armenia</option>
                            <option value="AW">Aruba</option>
                            <option value="AU">Australia</option>
                            <option value="AT">Austria</option>
                            <option value="AZ">Azerbaijan</option>
                            <option value="BS">Bahamas</option>
                            <option value="BH">Bahrain</option>
                            <option value="BD">Bangladesh</option>
                            <option value="BB">Barbados</option>
                            <option value="BY">Belarus</option>
                            <option value="BE">Belgium</option>
                            <option value="BZ">Belize</option>
                            <option value="BJ">Benin</option>
                            <option value="BM">Bermuda</option>
                            <option value="BT">Bhutan</option>
                            <option value="BO">Bolivia, Plurinational State of</option>
                            <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                            <option value="BA">Bosnia and Herzegovina</option>
                            <option value="BW">Botswana</option>
                            <option value="BR">Brazil</option>
                            <option value="IO">British Indian Ocean Territory</option>
                            <option value="BN">Brunei Darussalam</option>
                            <option value="BG">Bulgaria</option>
                            <option value="BF">Burkina Faso</option>
                            <option value="BI">Burundi</option>
                            <option value="KH">Cambodia</option>
                            <option value="CM">Cameroon</option>
                            <option value="CA">Canada</option>
                            <option value="CV">Cape Verde</option>
                            <option value="KY">Cayman Islands</option>
                            <option value="CF">Central African Republic</option>
                            <option value="TD">Chad</option>
                            <option value="CL">Chile</option>
                            <option value="CN">China</option>
                            <option value="CX">Christmas Island</option>
                            <option value="CC">Cocos (Keeling) Islands</option>
                            <option value="CO">Colombia</option>
                            <option value="KM">Comoros</option>
                            <option value="CK">Cook Islands</option>
                            <option value="CR">Costa Rica</option>
                            <option value="CI">Côte d'Ivoire</option>
                            <option value="HR">Croatia</option>
                            <option value="CU">Cuba</option>
                            <option value="CW">Curaçao</option>
                            <option value="CZ">Czech Republic</option>
                            <option value="DK">Denmark</option>
                            <option value="DJ">Djibouti</option>
                            <option value="DM">Dominica</option>
                            <option value="DO">Dominican Republic</option>
                            <option value="EC">Ecuador</option>
                            <option value="EG">Egypt</option>
                            <option value="SV">El Salvador</option>
                            <option value="GQ">Equatorial Guinea</option>
                            <option value="ER">Eritrea</option>
                            <option value="EE">Estonia</option>
                            <option value="ET">Ethiopia</option>
                            <option value="FK">Falkland Islands (Malvinas)</option>
                            <option value="FJ">Fiji</option>
                            <option value="FI">Finland</option>
                            <option value="FR">France</option>
                            <option value="PF">French Polynesia</option>
                            <option value="GA">Gabon</option>
                            <option value="GM">Gambia</option>
                            <option value="GE">Georgia</option>
                            <option value="DE">Germany</option>
                            <option value="GH">Ghana</option>
                            <option value="GI">Gibraltar</option>
                            <option value="GR">Greece</option>
                            <option value="GL">Greenland</option>
                            <option value="GD">Grenada</option>
                            <option value="GU">Guam</option>
                            <option value="GT">Guatemala</option>
                            <option value="GG">Guernsey</option>
                            <option value="GN">Guinea</option>
                            <option value="GW">Guinea-Bissau</option>
                            <option value="HT">Haiti</option>
                            <option value="VA">Holy See (Vatican City State)</option>
                            <option value="HN">Honduras</option>
                            <option value="HK">Hong Kong</option>
                            <option value="HU">Hungary</option>
                            <option value="IS">Iceland</option>
                            <option value="IN">India</option>
                            <option value="ID">Indonesia</option>
                            <option value="IR">Iran, Islamic Republic of</option>
                            <option value="IQ">Iraq</option>
                            <option value="IE">Ireland</option>
                            <option value="IM">Isle of Man</option>
                            <option value="IL">Israel</option>
                            <option value="IT">Italy</option>
                            <option value="JM">Jamaica</option>
                            <option value="JP">Japan</option>
                            <option value="JE">Jersey</option>
                            <option value="JO">Jordan</option>
                            <option value="KZ">Kazakhstan</option>
                            <option value="KE">Kenya</option>
                            <option value="KI">Kiribati</option>
                            <option value="KP">Korea, Democratic People's Republic of</option>
                            <option value="KW">Kuwait</option>
                            <option value="KG">Kyrgyzstan</option>
                            <option value="LA">Lao People's Democratic Republic</option>
                            <option value="LV">Latvia</option>
                            <option value="LB">Lebanon</option>
                            <option value="LS">Lesotho</option>
                            <option value="LR">Liberia</option>
                            <option value="LY">Libya</option>
                            <option value="LI">Liechtenstein</option>
                            <option value="LT">Lithuania</option>
                            <option value="LU">Luxembourg</option>
                            <option value="MO">Macao</option>
                            <option value="MG">Madagascar</option>
                            <option value="MW">Malawi</option>
                            <option value="MY">Malaysia</option>
                            <option value="MV">Maldives</option>
                            <option value="ML">Mali</option>
                            <option value="MT">Malta</option>
                            <option value="MH">Marshall Islands</option>
                            <option value="MQ">Martinique</option>
                            <option value="MR">Mauritania</option>
                            <option value="MU">Mauritius</option>
                            <option value="MX">Mexico</option>
                            <option value="FM">Micronesia, Federated States of</option>
                            <option value="MD">Moldova, Republic of</option>
                            <option value="MC">Monaco</option>
                            <option value="MN">Mongolia</option>
                            <option value="ME">Montenegro</option>
                            <option value="MS">Montserrat</option>
                            <option value="MA">Morocco</option>
                            <option value="MZ">Mozambique</option>
                            <option value="MM">Myanmar</option>
                            <option value="NA">Namibia</option>
                            <option value="NR">Nauru</option>
                            <option value="NP">Nepal</option>
                            <option value="NL">Netherlands</option>
                            <option value="NZ">New Zealand</option>
                            <option value="NI">Nicaragua</option>
                            <option value="NE">Niger</option>
                            <option value="NG">Nigeria</option>
                            <option value="NU">Niue</option>
                            <option value="NF">Norfolk Island</option>
                            <option value="MP">Northern Mariana Islands</option>
                            <option value="NO">Norway</option>
                            <option value="OM">Oman</option>
                            <option value="PK">Pakistan</option>
                            <option value="PW">Palau</option>
                            <option value="PS">Palestinian Territory, Occupied</option>
                            <option value="PA">Panama</option>
                            <option value="PG">Papua New Guinea</option>
                            <option value="PY">Paraguay</option>
                            <option value="PE">Peru</option>
                            <option value="PH">Philippines</option>
                            <option value="PL">Poland</option>
                            <option value="PT">Portugal</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="QA">Qatar</option>
                            <option value="RO">Romania</option>
                            <option value="RU">Russian Federation</option>
                            <option value="RW">Rwanda</option>
                            <option value="BL">Saint Barthélemy</option>
                            <option value="KN">Saint Kitts and Nevis</option>
                            <option value="LC">Saint Lucia</option>
                            <option value="MF">Saint Martin (French part)</option>
                            <option value="VC">Saint Vincent and the Grenadines</option>
                            <option value="WS">Samoa</option>
                            <option value="SM">San Marino</option>
                            <option value="ST">Sao Tome and Principe</option>
                            <option value="SA">Saudi Arabia</option>
                            <option value="SN">Senegal</option>
                            <option value="RS">Serbia</option>
                            <option value="SC">Seychelles</option>
                            <option value="SL">Sierra Leone</option>
                            <option value="SG">Singapore</option>
                            <option value="SX">Sint Maarten (Dutch part)</option>
                            <option value="SK">Slovakia</option>
                            <option value="SI">Slovenia</option>
                            <option value="SB">Solomon Islands</option>
                            <option value="SO">Somalia</option>
                            <option value="ZA">South Africa</option>
                            <option value="KR">South Korea</option>
                            <option value="SS">South Sudan</option>
                            <option value="ES">Spain</option>
                            <option value="LK">Sri Lanka</option>
                            <option value="SD">Sudan</option>
                            <option value="SR">Suriname</option>
                            <option value="SZ">Swaziland</option>
                            <option value="SE">Sweden</option>
                            <option value="CH">Switzerland</option>
                            <option value="SY">Syrian Arab Republic</option>
                            <option value="TW">Taiwan, Province of China</option>
                            <option value="TJ">Tajikistan</option>
                            <option value="TZ">Tanzania, United Republic of</option>
                            <option value="TH">Thailand</option>
                            <option value="TG">Togo</option>
                            <option value="TK">Tokelau</option>
                            <option value="TO">Tonga</option>
                            <option value="TT">Trinidad and Tobago</option>
                            <option value="TN">Tunisia</option>
                            <option value="TR">Turkey</option>
                            <option value="TM">Turkmenistan</option>
                            <option value="TC">Turks and Caicos Islands</option>
                            <option value="TV">Tuvalu</option>
                            <option value="UG">Uganda</option>
                            <option value="UA">Ukraine</option>
                            <option value="AE">United Arab Emirates</option>
                            <option value="GB">United Kingdom</option>
                            <option value="US">United States</option>
                            <option value="UY">Uruguay</option>
                            <option value="UZ">Uzbekistan</option>
                            <option value="VU">Vanuatu</option>
                            <option value="VE">Venezuela, Bolivarian Republic of</option>
                            <option value="VN">Vietnam</option>
                            <option value="VI">Virgin Islands</option>
                            <option value="YE">Yemen</option>
                            <option value="ZM">Zambia</option>
                            <option value="ZW">Zimbabwe</option>
                          </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-22-7fjt" style={{ width: "100%" }}><span class="selection"><span class="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-country-qv-container" aria-controls="select2-country-qv-container"><span class="select2-selection__rendered" id="select2-country-qv-container" role="textbox" aria-readonly="true" title="Select a Country..."><span class="select2-selection__placeholder">Select a Country...</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>

                        </div>

                      </div>

                    </div>

                  </div>


                  <div class="modal-footer flex-center">

                    <button type="reset" class="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>


                    <button type="submit" class="btn btn-primary" data-kt-users-modal-action="submit">
                      <span class="indicator-label">Submit</span>
                      <span class="indicator-progress">Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>

                  </div>

                </form>

              </div>
            </div>
          </div>


          <div class="modal fade" id="kt_modal_add_schedule" tabindex="-1" aria-hidden="true">

            <div class="modal-dialog modal-dialog-centered mw-650px">

              <div class="modal-content">

                <div class="modal-header">

                  <h2 class="fw-bold">Add an Event</h2>


                  <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                    <i class="ki-outline ki-cross fs-1"></i>
                  </div>

                </div>


                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">

                  <form id="kt_modal_add_schedule_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">

                    <div class="fv-row mb-7 fv-plugins-icon-container">

                      <label class="required fs-6 fw-semibold form-label mb-2">Event Name</label>


                      <input type="text" class="form-control form-control-solid" name="event_name" value="" />

                      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>


                    <div class="fv-row mb-7 fv-plugins-icon-container">

                      <label class="fs-6 fw-semibold form-label mb-2">
                        <span class="required">Date &amp; Time</span>
                        <span class="ms-2" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="Select a date &amp; time." data-kt-initialized="1">
                          <i class="ki-outline ki-information fs-7"></i>
                        </span>
                      </label>


                      <input class="form-control form-control-solid flatpickr-input" placeholder="Pick date &amp; time" name="event_datetime" id="kt_modal_add_schedule_datepicker" type="text" readonly="readonly" />

                      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>


                    <div class="fv-row mb-7 fv-plugins-icon-container">

                      <label class="required fs-6 fw-semibold form-label mb-2">Event Organiser</label>


                      <input type="text" class="form-control form-control-solid" name="event_org" value="" />

                      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>


                    <div class="fv-row mb-7 fv-plugins-icon-container">

                      <label class="required fs-6 fw-semibold form-label mb-2">Send Event Details To</label>


                      <tags class="tagify  form-control form-control-solid" tabindex="-1">
                        <tag title="smith@kpmg.com" contenteditable="false" spellcheck="false" tabindex="-1" class="tagify__tag tagify--noAnim" value="smith@kpmg.com"><x title="" class="tagify__tag__removeBtn" role="button" aria-label="remove tag"></x><div><span class="tagify__tag-text">smith@kpmg.com</span></div></tag><tag title="melody@altbox.com" contenteditable="false" spellcheck="false" tabindex="-1" class="tagify__tag tagify--noAnim" value="melody@altbox.com"><x title="" class="tagify__tag__removeBtn" role="button" aria-label="remove tag"></x><div><span class="tagify__tag-text">melody@altbox.com</span></div></tag><span contenteditable="" tabindex="0" data-placeholder="" aria-placeholder="" class="tagify__input" role="textbox" aria-autocomplete="both" aria-multiline="false"></span>

                      </tags><input id="kt_modal_add_schedule_tagify" type="text" class="form-control form-control-solid" name="event_invitees" value="smith@kpmg.com, melody@altbox.com" tabindex="-1" />

                      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>


                    <div class="text-center pt-15">
                      <button type="reset" class="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
                      <button type="submit" class="btn btn-primary" data-kt-users-modal-action="submit">
                        <span class="indicator-label">Submit</span>
                        <span class="indicator-progress">Please wait...
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                      </button>
                    </div>

                  </form>

                </div>

              </div>

            </div>

          </div>


          <div class="modal fade" id="kt_modal_add_task" tabindex="-1" aria-hidden="true">

            <div class="modal-dialog modal-dialog-centered mw-650px">

              <div class="modal-content">

                <div class="modal-header">

                  <h2 class="fw-bold">Add a Task</h2>


                  <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                    <i class="ki-outline ki-cross fs-1"></i>
                  </div>

                </div>


                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">

                  <form id="kt_modal_add_task_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">

                    <div class="fv-row mb-7 fv-plugins-icon-container">

                      <label class="required fs-6 fw-semibold form-label mb-2">Task Name</label>


                      <input type="text" class="form-control form-control-solid" name="task_name" value="" />

                      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>


                    <div class="fv-row mb-7 fv-plugins-icon-container">

                      <label class="fs-6 fw-semibold form-label mb-2">
                        <span class="required">Task Due Date</span>
                        <span class="ms-2" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="Select a due date." data-kt-initialized="1">
                          <i class="ki-outline ki-information fs-7"></i>
                        </span>
                      </label>


                      <input class="form-control form-control-solid flatpickr-input" placeholder="Pick date" name="task_duedate" id="kt_modal_add_task_datepicker" type="text" readonly="readonly" />

                      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>


                    <div class="fv-row mb-7">

                      <label class="fs-6 fw-semibold form-label mb-2">Task Description</label>


                      <textarea class="form-control form-control-solid rounded-3"></textarea>

                    </div>


                    <div class="text-center pt-15">
                      <button type="reset" class="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
                      <button type="submit" class="btn btn-primary" data-kt-users-modal-action="submit">
                        <span class="indicator-label">Submit</span>
                        <span class="indicator-progress">Please wait...
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                      </button>
                    </div>

                  </form>

                </div>

              </div>

            </div>

          </div>


          <div class="modal fade" id="kt_modal_update_email" tabindex="-1" aria-hidden="true">

            <div class="modal-dialog modal-dialog-centered mw-650px">

              <div class="modal-content">

                <div class="modal-header">

                  <h2 class="fw-bold">Update Email Address</h2>


                  <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                    <i class="ki-outline ki-cross fs-1"></i>
                  </div>

                </div>


                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">

                  <form id="kt_modal_update_email_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">


                    <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">

                      <i class="ki-outline ki-information fs-2tx text-primary me-4"></i>


                      <div class="d-flex flex-stack flex-grow-1">

                        <div class="fw-semibold">
                          <div class="fs-6 text-gray-700">Please note that a valid email address is required to complete the email verification.</div>
                        </div>

                      </div>

                    </div>



                    <div class="fv-row mb-7 fv-plugins-icon-container">

                      <label class="fs-6 fw-semibold form-label mb-2">
                        <span class="required">Email Address</span>
                      </label>


                      <input class="form-control form-control-solid" placeholder="" name="profile_email" value="smith@kpmg.com" />

                      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>


                    <div class="text-center pt-15">
                      <button type="reset" class="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
                      <button type="submit" class="btn btn-primary" data-kt-users-modal-action="submit">
                        <span class="indicator-label">Submit</span>
                        <span class="indicator-progress">Please wait...
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                      </button>
                    </div>

                  </form>

                </div>

              </div>

            </div>

          </div>


          <div class={showupdatepassword ? "modal fade show" : "modal fade"} id="kt_modal_update_password" tabindex="-1" aria-hidden="true" style={{ display: showupdatepassword ? 'block' : 'none' }}>

            <div class="modal-dialog modal-dialog-centered mw-650px">

              <div class="modal-content">

                <div class="modal-header">

                  <h2 class="fw-bold">Modifier mon de passe</h2>


                  <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" onClick={handleCloseUpdateModal}>
                    <i class="ki-outline ki-cross fs-1"></i>
                  </div>

                </div>


                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">

                  <form id="kt_modal_update_password_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">

                    <div class="fv-row mb-10 fv-plugins-icon-container">
                      <label class="required form-label fs-6 mb-2">Mot de passe actuel</label>
                      <input value={actualPassword} onChange={handleActualPassword} class="form-control form-control-lg form-control-solid" type="password" placeholder="" name="current_password" autocomplete="off" />
                      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>

                    <div class="mb-10 fv-row fv-plugins-icon-container" data-kt-password-meter="true">

                      <div class="mb-1">

                        <label class="form-label fw-semibold fs-6 mb-2">Nouveau Mot de passe</label>


                        <div class="position-relative mb-3">
                          <input value={newPassword} onChange={handleNewPassword} class="form-control form-control-lg form-control-solid" type={showPassword ? "text" : "password"} placeholder="" name="new_password" autocomplete="off" />
                          <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                            {
                              showPassword ?
                                <span onClick={handleShowPassord}>
                                  <i class="ki-outline ki-eye  fs-1"></i>

                                </span>

                                :
                                <span onClick={handleShowPassord}>
                                  <i class="ki-outline ki-eye-slash fs-1"></i>
                                  <i class="ki-outline ki-eye d-none fs-1"></i>
                                </span>

                            }

                          </span>
                        </div>


                        <div class="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                          <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                          <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                          <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                          <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                        </div>

                      </div>

                      <div class="text-muted">Utilisez 8 caracteres ou plus avec un melange de lettre, nombres &amp; symboles.</div>

                      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>

                    <div class="fv-row mb-10 fv-plugins-icon-container">
                      <label class="form-label fw-semibold fs-6 mb-2">Confirmez votre nouveau mot de passe</label>
                      <input value={confirmPassword} onChange={handleConfirmPassword} class="form-control form-control-lg form-control-solid" type="password" placeholder="" name="confirm_password" autocomplete="off" />
                      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>

                    {error ? <p style={{ color: 'red' }}>Verifiez vos informations!</p> : null}
                    {error_update_password === null ? null : <p style={{ color: 'red' }}>{error_update_password}</p>}

                    <div class="text-center pt-15">
                      {
                        loading_update ?
                          <Loader />
                          :
                          <>
                            <button type="reset" onClick={handleCloseUpdateModal} class="btn btn-light me-3" data-kt-users-modal-action="cancel">Annuler</button>
                            <button type="submit" class="btn btn-primary" data-kt-users-modal-action="submit" onClick={handleChangePassword} >
                              <span class="indicator-label">Modifier</span>
                            </button>
                          </>
                      }


                    </div>

                  </form>

                </div>

              </div>

            </div>

          </div>


          <div class="modal fade" id="kt_modal_update_role" tabindex="-1" aria-hidden="true">

            <div class="modal-dialog modal-dialog-centered mw-650px">

              <div class="modal-content">

                <div class="modal-header">

                  <h2 class="fw-bold">Update User Role</h2>


                  <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                    <i class="ki-outline ki-cross fs-1"></i>
                  </div>

                </div>


                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">

                  <form id="kt_modal_update_role_form" class="form" action="#">


                    <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">

                      <i class="ki-outline ki-information fs-2tx text-primary me-4"></i>


                      <div class="d-flex flex-stack flex-grow-1">

                        <div class="fw-semibold">
                          <div class="fs-6 text-gray-700">Please note that reducing a user role rank, that user will lose all priviledges that was assigned to the previous role.</div>
                        </div>

                      </div>

                    </div>



                    <div class="fv-row mb-7">

                      <label class="fs-6 fw-semibold form-label mb-5">
                        <span class="required">Select a user role</span>
                      </label>


                      <div class="d-flex">

                        <div class="form-check form-check-custom form-check-solid">

                          <input class="form-check-input me-3" name="user_role" type="radio" value="0" id="kt_modal_update_role_option_0" checked="checked" />


                          <label class="form-check-label" for="kt_modal_update_role_option_0">
                            <div class="fw-bold text-gray-800">Administrator</div>
                            <div class="text-gray-600">Best for business owners and company administrators</div>
                          </label>

                        </div>

                      </div>

                      <div class="separator separator-dashed my-5"></div>

                      <div class="d-flex">

                        <div class="form-check form-check-custom form-check-solid">

                          <input class="form-check-input me-3" name="user_role" type="radio" value="1" id="kt_modal_update_role_option_1" />


                          <label class="form-check-label" for="kt_modal_update_role_option_1">
                            <div class="fw-bold text-gray-800">Developer</div>
                            <div class="text-gray-600">Best for developers or people primarily using the API</div>
                          </label>

                        </div>

                      </div>

                      <div class="separator separator-dashed my-5"></div>

                      <div class="d-flex">

                        <div class="form-check form-check-custom form-check-solid">

                          <input class="form-check-input me-3" name="user_role" type="radio" value="2" id="kt_modal_update_role_option_2" />


                          <label class="form-check-label" for="kt_modal_update_role_option_2">
                            <div class="fw-bold text-gray-800">Analyst</div>
                            <div class="text-gray-600">Best for people who need full access to analytics data, but don't need to update business settings</div>
                          </label>

                        </div>

                      </div>

                      <div class="separator separator-dashed my-5"></div>

                      <div class="d-flex">

                        <div class="form-check form-check-custom form-check-solid">

                          <input class="form-check-input me-3" name="user_role" type="radio" value="3" id="kt_modal_update_role_option_3" />


                          <label class="form-check-label" for="kt_modal_update_role_option_3">
                            <div class="fw-bold text-gray-800">Support</div>
                            <div class="text-gray-600">Best for employees who regularly refund payments and respond to disputes</div>
                          </label>

                        </div>

                      </div>

                      <div class="separator separator-dashed my-5"></div>

                      <div class="d-flex">

                        <div class="form-check form-check-custom form-check-solid">

                          <input class="form-check-input me-3" name="user_role" type="radio" value="4" id="kt_modal_update_role_option_4" />


                          <label class="form-check-label" for="kt_modal_update_role_option_4">
                            <div class="fw-bold text-gray-800">Trial</div>
                            <div class="text-gray-600">Best for people who need to preview content data, but don't need to make any updates</div>
                          </label>

                        </div>

                      </div>

                    </div>


                    <div class="text-center pt-15">
                      <button type="reset" class="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
                      <button type="submit" class="btn btn-primary" data-kt-users-modal-action="submit">
                        <span class="indicator-label">Submit</span>
                        <span class="indicator-progress">Please wait...
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                      </button>
                    </div>

                  </form>

                </div>

              </div>

            </div>

          </div>


          <div class="modal fade" id="kt_modal_add_auth_app" tabindex="-1" aria-hidden="true">

            <div class="modal-dialog modal-dialog-centered mw-650px">

              <div class="modal-content">

                <div class="modal-header">

                  <h2 class="fw-bold">Add Authenticator App</h2>


                  <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                    <i class="ki-outline ki-cross fs-1"></i>
                  </div>

                </div>


                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">

                  <div class="fw-bold d-flex flex-column justify-content-center mb-5">

                    <div class="text-center mb-5" data-kt-add-auth-action="qr-code-label">Download the
                      <a href="#">Authenticator app</a>, add a new account, then scan this barcode to set up your account.</div>
                    <div class="text-center mb-5 d-none" data-kt-add-auth-action="text-code-label">Download the
                      <a href="#">Authenticator app</a>, add a new account, then enter this code to set up your account.</div>


                    <div class="d-flex flex-center" data-kt-add-auth-action="qr-code">
                      <img src="assets/media/misc/qr.png" alt="Scan this QR code" />
                    </div>


                    <div class="border rounded p-5 d-flex flex-center d-none" data-kt-add-auth-action="text-code">
                      <div class="fs-1">gi2kdnb54is709j</div>
                    </div>

                  </div>


                  <div class="d-flex flex-center">
                    <div class="btn btn-light-primary" data-kt-add-auth-action="text-code-button">Enter code manually</div>
                    <div class="btn btn-light-primary d-none" data-kt-add-auth-action="qr-code-button">Scan barcode instead</div>
                  </div>

                </div>

              </div>

            </div>

          </div>


          <div class="modal fade" id="kt_modal_add_one_time_password" tabindex="-1" aria-hidden="true">

            <div class="modal-dialog modal-dialog-centered mw-650px">

              <div class="modal-content">

                <div class="modal-header">

                  <h2 class="fw-bold">Enable One Time Password</h2>


                  <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                    <i class="ki-outline ki-cross fs-1"></i>
                  </div>

                </div>


                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">

                  <form class="form fv-plugins-bootstrap5 fv-plugins-framework" id="kt_modal_add_one_time_password_form">

                    <div class="fw-bold mb-9">Enter the new phone number to receive an SMS to when you log in.</div>


                    <div class="fv-row mb-7 fv-plugins-icon-container">

                      <label class="fs-6 fw-semibold form-label mb-2">
                        <span class="required">Mobile number</span>
                        <span class="ms-2" data-bs-toggle="tooltip" aria-label="A valid mobile number is required to receive the one-time password to validate your account login." data-bs-original-title="A valid mobile number is required to receive the one-time password to validate your account login." data-kt-initialized="1">
                          <i class="ki-outline ki-information fs-7"></i>
                        </span>
                      </label>


                      <input type="text" class="form-control form-control-solid" name="otp_mobile_number" placeholder="+6123 456 789" value="" />

                      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>


                    <div class="separator saperator-dashed my-5"></div>


                    <div class="fv-row mb-7">

                      <label class="fs-6 fw-semibold form-label mb-2">
                        <span class="required">Email</span>
                      </label>


                      <input type="email" class="form-control form-control-solid" name="otp_email" value="smith@kpmg.com" readonly="readonly" />

                    </div>


                    <div class="fv-row mb-7 fv-plugins-icon-container">

                      <label class="fs-6 fw-semibold form-label mb-2">
                        <span class="required">Confirm password</span>
                      </label>


                      <input type="password" class="form-control form-control-solid" name="otp_confirm_password" value="" />

                      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>


                    <div class="text-center pt-15">
                      <button type="reset" class="btn btn-light me-3" data-kt-users-modal-action="cancel">Cancel</button>
                      <button type="submit" class="btn btn-primary" data-kt-users-modal-action="submit">
                        <span class="indicator-label">Submit</span>
                        <span class="indicator-progress">Please wait...
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                      </button>
                    </div>

                  </form>

                </div>

              </div>

            </div>

          </div>


        </div>

      </div>
    </Layout>
  )
}

export default Settings