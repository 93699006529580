
import { GET_APP_INFOS_URL } from '../../urls/home/home';

import { GET_APP_INFOS_START, GET_APP_INFOS_SUCCESS, GET_APP_INFOS_FAILED } from '../../types/home/home';


export const getAppInfosStart = () => {
    return {
        type: GET_APP_INFOS_START
    }
}

export const getAppInfosSuccess = (appinfos) => {
    return {
        type: GET_APP_INFOS_SUCCESS,
        appinfos: appinfos
    }
}

export const getAppInfosFailed = (error) => {
    return {
        type: GET_APP_INFOS_FAILED,
        error: error
    }
}

export const getAppInfosService = (token) => {
    return dispatch => {
        dispatch(getAppInfosStart())
        fetch(GET_APP_INFOS_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getAppInfosSuccess(response.data))
                }
                else{
                    dispatch(getAppInfosFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(getAppInfosFailed(error.message))
            })
    }

}
