import * as actionsTypes from '../../types/user/user';

const initialState = {
    userlist: [],
    loading: false,
    rightdrawer: false,
    typedrawer: "null",

    loading_add_user: false,

    currentPage: null,
    hasNextPage: null,
    hasPreviousPage: null,
    nexPage: null,
    previousPage: null,
    lastPage: null,

    etudiantlist: [],
    currentPage_etudiant: null,
    hasNextPage_etudiant: null,
    hasPreviousPage_etudiant: null,
    nexPage_etudiant: null,
    previousPage_etudiant: null,
    lastPage_etudiant: null,

    currentPage_non_etudiant: null,
    hasNextPage_non_etudiant: null,
    hasPreviousPage_non_etudiant: null,
    nexPage_non_etudiant: null,
    previousPage_non_etudiant: null,
    lastPage_non_etudiant: null,

    userdetaildata:[],
    userdetail:false
}

const reducerUser = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.GET_USER_START:
            return {
                ...state,
                loading: true
            }

        case actionsTypes.GET_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userlist: action.userlist,
                error: null
            }

        case actionsTypes.GET_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case actionsTypes.ADD_USER_START:
            return {
                ...state,
                loading_add_user: true
            }

        case actionsTypes.ADD_USER_SUCCESS:
            return {
                ...state,
                loading_add_user: false,
                error: null,
                rightdrawer: false,
                typedrawer: "null"
            }

        case actionsTypes.ADD_USER_FAILED:
            return {
                ...state,
                loading_add_user: false,
                error: action.error
            }

        case actionsTypes.SET_RIGHT_DRAWER:
            return {
                ...state,
                rightdrawer: action.value
            }

        case actionsTypes.SET_TYPE_DRAWER:
            return {
                ...state,
                typedrawer: action.value
            }

        case actionsTypes.SEARCH_USER_START:
            return {
                ...state,
                loading: true
            }

        case actionsTypes.SEARCH_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userlist: action.userlist,
                error: null
            }

        case actionsTypes.SEARCH_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case actionsTypes.GET_USER_PAGINATION_START:
            return {
                ...state,
                loading: true
            }

        case actionsTypes.GET_USER_PAGINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                userlist: action.userlist,
                currentPage: action.currentPage,
                hasNextPage: action.hasNextPage,
                hasPreviousPage: action.hasPreviousPage,
                nexPage: action.nexPage,
                previousPage: action.previousPage,
                lastPage: action.lastPage,
                error: null
            }

        case actionsTypes.GET_USER_PAGINATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case actionsTypes.GET_ETUDIANT_PAGINATION_START:
            return {
                ...state,
                loading: true
            }

        case actionsTypes.GET_ETUDIANT_PAGINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                etudiantlist: action.etudiantlist,
                currentPage_etudiant: action.currentPage_etudiant,
                hasNextPage_etudiant: action.hasNextPage_etudiant,
                hasPreviousPage_etudiant: action.hasPreviousPage_etudiant,
                nexPage_etudiant: action.nexPage_etudiant,
                previousPage_etudiant: action.previousPage_etudiant,
                lastPage_etudiant: action.lastPage_etudiant,
                error: null
            }

        case actionsTypes.GET_ETUDIANT_PAGINATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case actionsTypes.GET_NON_ETUDIANT_PAGINATION_START:
            return {
                ...state,
                loading: true
            }

        case actionsTypes.GET_NON_ETUDIANT_PAGINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                etudiantlist: action.etudiantlist,
                currentPage_non_etudiant: action.currentPage_non_etudiant,
                hasNextPage_non_etudiant: action.hasNextPage_non_etudiant,
                hasPreviousPage_non_etudiant: action.hasPreviousPage_non_etudiant,
                nexPage_non_etudiant: action.nexPage_non_etudiant,
                previousPage_non_etudiant: action.previousPage_non_etudiant,
                lastPage_non_etudiant: action.lastPage_non_etudiant,
                error: null
            }

        case actionsTypes.GET_NON_ETUDIANT_PAGINATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }

            case actionsTypes.GO_TO_USER_DETAIL:
                return {
                    ...state,
                    userdetaildata: action.userdetaildata,
                    userdetail:true

                }

                case actionsTypes.GO_TO_USER_DETAIL_CANCEL:
                return {
                    ...state,
                    userdetail:false

                }

        default:
            return state
    }
}

export default reducerUser