import React, { useEffect } from 'react';

// IMPORT CONFIG & DEPENDENCIES
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginCheckState } from './actions/auth/auth';

// IMPORT PAGES
import Home from '../src/pages/home/home';
import UserList from '../src/pages/users/userlist';
import Login from './pages/login/login';
import Articles from './pages/articles/articlelist';
import RendezVous from './pages/rendezvous/rendezvous';
import Notificationn from './pages/notifications/notification';
import Settings from './pages/settings/settings';
import Etudiants from './pages/users/etudiants';
import Ressortissants from './pages/users/ressortissants';
import Requetes from './pages/users/requetes';
import ArticleEtudiants from './pages/articles/articleetudiant';
import ArticleNonEtudiants from './pages/articles/articlenonetudiant';
import RendezVousEudiant from './pages/rendezvous/rendezvousetudiant';
import RendezVousParticulier from './pages/rendezvous/rendezvousparticulier';
import NotificationEtudiant from './pages/notifications/notificaation-etudiant';
import NotificationNonEtudiant from './pages/notifications/notification-non-etudiant';
import NotificationParticulier from './pages/notifications/notification-particulier';
import GestionDesRoles from './pages/settings/gestion-des-roles';
import Preferences from './pages/settings/preferences';
import UserDetails from './pages/users/user-detail';


import './App.css';

const App = () => {

  const dispatch = useDispatch();

  const token = useSelector(state => state.AuthReducer.token)
  const role = useSelector(state => state.AuthReducer.role)

  useEffect(() => {
    dispatch(loginCheckState())
  }, []);

  let route = (
    <Switch>
      <Route path="/login" component={Login} />
      <Redirect to='/login' />
    </Switch>
  )

  if (token && (role == 'admin')) {
    route = (
      <Switch>
        <Route path="/user-detail" component={UserDetails} />
        <Route path="/preferences" component={Preferences} />
        <Route path="/gestion-des-roles" component={GestionDesRoles} />
        <Route path="/notification-particulier" component={NotificationParticulier} />
        <Route path="/notification-non-etudiant" component={NotificationNonEtudiant} />
        <Route path="/notification-etudiant" component={NotificationEtudiant} />
        <Route path="/rendez-vous-particulier" component={RendezVousParticulier} />
        <Route path="/rendez-vous-etudiant" component={RendezVousEudiant} />
        <Route path="/article-non-etudiant" component={ArticleNonEtudiants} />
        <Route path="/article-etudiant" component={ArticleEtudiants} />
        <Route path="/ressortissants" component={Ressortissants} />
        <Route path="/etudiants" component={Etudiants} />
        <Route path="/requetes" component={Requetes} />
        <Route path="/settings" component={Settings} />
        <Route path="/notifications" component={Notificationn} />
        <Route path="/rendez-vous" component={RendezVous} />
        <Route path="/articles" component={Articles} />
        <Route path="/userlist" component={UserList} />
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>
    )
  }

  return (
    <>
      {route}
    </>
  )


}

export default withRouter(App);

