import { GET_NOTIFICATION_PAGINATION_URL, GET_NOTIFICATION_ETUDIANT_PAGINATION_URL, GET_NOTIFICATION_RESSORTISSANT_PAGINATION_URL} from '../../urls/notification/notification';

import {  GET_NOTIFICATION_PAGINATION_START, GET_NOTIFICATION_PAGINATION_SUCCESS, GET_NOTIFICATION_PAGINATION_FAILED,
GET_NOTIFICATION_ETUDIANT_PAGINATION_START, GET_NOTIFICATION_ETUDIANT_PAGINATION_SUCCESS, GET_NOTIFICATION_ETUDIANT_PAGINATION_FAILED,
GET_NOTIFICATION_RESSORTISSANT_PAGINATION_START, GET_NOTIFICATION_RESSORTISSANT_PAGINATION_SUCCESS, GET_NOTIFICATION_RESSORTISSANT_PAGINATION_FAILED
} from '../../types/notification/notification';


export const getNotificationPaginationStart = () => {
    return {
        type: GET_NOTIFICATION_PAGINATION_START
    }
}

export const getNotificationPaginationSuccess = (notificationlist,currentPage, hasNextPage, hasPreviousPage, nexPage, previousPage, lastPage) => {
    return {
        type: GET_NOTIFICATION_PAGINATION_SUCCESS,
        notificationlist: notificationlist,
        currentPage:currentPage,
        hasNextPage:hasNextPage,
        hasPreviousPage:hasPreviousPage,
        nexPage:nexPage,
        previousPage:previousPage,
        lastPage:lastPage
    }
}

export const getNotificationPaginationFailed = (error) => {
    return {
        type: GET_NOTIFICATION_PAGINATION_FAILED,
        error: error
    }
}

export const getNotificationPaginationService = (page,token) => {
    return dispatch => {
        dispatch(getNotificationPaginationStart())
        fetch(GET_NOTIFICATION_PAGINATION_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            },
            body: JSON.stringify({
                "page": page
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getNotificationPaginationSuccess(response.data.response, response.data.currentPage, response.data.hasNextPage, response.data.hasPreviousPage, response.data.nexPage, response.data.previousPage, response.data.lastPage))
                }
                else{
                    dispatch(getNotificationPaginationFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(getNotificationPaginationFailed(error.message))
            })
    }

}

export const getNotificationEtudiantPaginationStart = () => {
    return {
        type: GET_NOTIFICATION_ETUDIANT_PAGINATION_START
    }
}

export const getNotificationEtudiantPaginationSuccess = (notificationlist,currentPage_etudiant, hasNextPage_etudiant, hasPreviousPage_etudiant, nexPage_etudiant, previousPage_etudiant, lastPage_etudiant) => {
    return {
        type: GET_NOTIFICATION_ETUDIANT_PAGINATION_SUCCESS,
        notificationlist: notificationlist,
        currentPage_etudiant:currentPage_etudiant,
        hasNextPage_etudiant:hasNextPage_etudiant,
        hasPreviousPage_etudiant:hasPreviousPage_etudiant,
        nexPage_etudiant:nexPage_etudiant,
        previousPage_etudiant:previousPage_etudiant,
        lastPage_etudiant:lastPage_etudiant
    }
}

export const getNotificationEtudiantPaginationFailed = (error) => {
    return {
        type: GET_NOTIFICATION_ETUDIANT_PAGINATION_FAILED,
        error: error
    }
}

export const getNotificationEtudiantPaginationService = (page,token) => {
    return dispatch => {
        dispatch(getNotificationEtudiantPaginationStart())
        fetch(GET_NOTIFICATION_ETUDIANT_PAGINATION_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            },
            body: JSON.stringify({
                "page": page
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getNotificationEtudiantPaginationSuccess(response.data.response, response.data.currentPage, response.data.hasNextPage, response.data.hasPreviousPage, response.data.nexPage, response.data.previousPage, response.data.lastPage))
                }
                else{
                    dispatch(getNotificationEtudiantPaginationFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(getNotificationEtudiantPaginationFailed(error.message))
            })
    }

}

export const getNotificationRessortissantPaginationStart = () => {
    return {
        type: GET_NOTIFICATION_RESSORTISSANT_PAGINATION_START
    }
}

export const getNotificationRessortissantPaginationSuccess = (notificationlist,currentPage_ressortissant, hasNextPage_ressortissant, hasPreviousPage_ressortissant, nexPage_ressortissant, previousPage_ressortissant, lastPage_ressortissant) => {
    return {
        type: GET_NOTIFICATION_RESSORTISSANT_PAGINATION_SUCCESS,
        notificationlist: notificationlist,
        currentPage_ressortissant:currentPage_ressortissant,
        hasNextPage_ressortissant:hasNextPage_ressortissant,
        hasPreviousPage_ressortissant:hasPreviousPage_ressortissant,
        nexPage_ressortissant:nexPage_ressortissant,
        previousPage_ressortissant:previousPage_ressortissant,
        lastPage_ressortissant:lastPage_ressortissant
    }
}

export const getNotificationRessortissantPaginationFailed = (error) => {
    return {
        type: GET_NOTIFICATION_RESSORTISSANT_PAGINATION_FAILED,
        error: error
    }
}

export const getNotificationRessortissantPaginationService = (page,token) => {
    return dispatch => {
        dispatch(getNotificationRessortissantPaginationStart())
        fetch(GET_NOTIFICATION_RESSORTISSANT_PAGINATION_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+ token
            },
            body: JSON.stringify({
                "page": page
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getNotificationRessortissantPaginationSuccess(response.data.response, response.data.currentPage, response.data.hasNextPage, response.data.hasPreviousPage, response.data.nexPage, response.data.previousPage, response.data.lastPage))
                }
                else{
                    dispatch(getNotificationRessortissantPaginationFailed("error"))
                }

            })
            .catch(error => {
                console.log(error)
                dispatch(getNotificationRessortissantPaginationFailed(error.message))
            })
    }

}