import * as actionsTypes from '../../types/appsettings/appsettings';

const initialState = {
    openuserdrawer: false,
    openarticledrawer: false,
    openrendezvousdrawer: false,
    opensettingsdrawer: false,
    opennotificationdrawer: false
}

const reducerAppSettings = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.OPEN_USER_DRAWER:
            return {
                ...state,
                openuserdrawer: action.value
            }

        case actionsTypes.OPEN_ARTICLE_DRAWER:
            return {
                ...state,
                openarticledrawer: action.value
            }

        case actionsTypes.OPEN_RENDEZVOUS_DRAWER:
            return {
                ...state,
                openrendezvousdrawer: action.value
            }

        case actionsTypes.OPEN_NOTIFICATION_DRAWER:
            return {
                ...state,
                opennotificationdrawer: action.value
            }

        case actionsTypes.OPEN_SETTING_DRAWER:
            return {
                ...state,
                opensettingsdrawer: action.value
            }

        default:
            return state
    }
}

export default reducerAppSettings