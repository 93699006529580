import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  searchArticleService } from '../../actions/article/article';

const SearchArticle = () => {
    
    const dispatch = useDispatch();

    const token = useSelector(state => state.AuthReducer.token)
    
    const [searchArticle, setSearchArticle] = useState("")

    const handleSearchArticle = (e) => {
       
        setSearchArticle(e.target.value)
        console.log(e.target.value)
        dispatch(searchArticleService(e.target.value, token))
     }


    return(
        <div class="card-title">

        <div class="d-flex align-items-center position-relative my-1">
            <i class="ki-outline ki-magnifier fs-3 position-absolute ms-5"></i>
            <input type="text" class="form-control form-control-solid w-250px ps-12" value={searchArticle} onChange={handleSearchArticle} placeholder="Rechercher des articles" />
        </div>

    </div>
    )
};

export default SearchArticle;