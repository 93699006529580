import * as actionsTypes from '../../types/notification/notification';

const initialState = {
    notificationlist: [],
    loading: false,
    rightdrawer: false,
    typedrawer: "null",

    loading_add_user: false,

    currentPage: null,
    hasNextPage: null,
    hasPreviousPage: null,
    nexPage: null,
    previousPage: null,
    lastPage: null,

    currentPage_etudiant: null,
    hasNextPage_etudiant: null,
    hasPreviousPage_etudiant: null,
    nexPage_etudiant: null,
    previousPage_etudiant: null,
    lastPage_etudiant: null,

    currentPage_ressortissant: null,
    hasNextPage_ressortissant: null,
    hasPreviousPage_ressortissant: null,
    nexPage_ressortissant: null,
    previousPage_ressortissant: null,
    lastPage_ressortissant: null,

}

const reducerNotification = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.GET_NOTIFICATION_PAGINATION_START:
            return {
                ...state,
                loading: true
            }

        case actionsTypes.GET_NOTIFICATION_PAGINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationlist: action.notificationlist,
                currentPage: action.currentPage,
                hasNextPage: action.hasNextPage,
                hasPreviousPage: action.hasPreviousPage,
                nexPage: action.nexPage,
                previousPage: action.previousPage,
                lastPage: action.lastPage,
                error: null
            }

        case actionsTypes.GET_NOTIFICATION_PAGINATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case actionsTypes.GET_NOTIFICATION_ETUDIANT_PAGINATION_START:
            return {
                ...state,
                loading: true
            }

        case actionsTypes.GET_NOTIFICATION_ETUDIANT_PAGINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationlist: action.notificationlist,
                currentPage_etudiant: action.currentPage_etudiant,
                hasNextPage_etudiant: action.hasNextPage_etudiant,
                hasPreviousPage_etudiant: action.hasPreviousPage_etudiant,
                nexPage_etudiant: action.nexPage_etudiant,
                previousPage_etudiant: action.previousPage_etudiant,
                lastPage_etudiant: action.lastPage_etudiant,
                error: null
            }

        case actionsTypes.GET_NOTIFICATION_ETUDIANT_PAGINATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case actionsTypes.GET_NOTIFICATION_RESSORTISSANT_PAGINATION_START:
            return {
                ...state,
                loading: true
            }

        case actionsTypes.GET_NOTIFICATION_RESSORTISSANT_PAGINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationlist: action.notificationlist,
                currentPage_ressortissant: action.currentPage_ressortissant,
                hasNextPage_ressortissant: action.hasNextPage_ressortissant,
                hasPreviousPage_ressortissant: action.hasPreviousPage_ressortissant,
                nexPage_ressortissant: action.nexPage_ressortissant,
                previousPage_ressortissant: action.previousPage_ressortissant,
                lastPage_ressortissant: action.lastPage_ressortissant,
                error: null
            }

        case actionsTypes.GET_NOTIFICATION_RESSORTISSANT_PAGINATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state
    }
}

export default reducerNotification