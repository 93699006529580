

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  getNonEtudiantPaginationService , setTypeDrawer, setRightDrawer, addUserService} from '../../actions/user/user';

import Loader from '../../components/loader/loader';
import Layout from '../../components/layout/layout';
import SearchUser from '../../components/search/search';
import Avatar from '../../assets/images/avatar.png';

import Moment from 'react-moment';
import 'moment/locale/fr';

const Ressortissants = () => {

    const dispatch = useDispatch();

    const token = useSelector(state => state.AuthReducer.token)
    const loading = useSelector(state => state.UserReducer.loading)
    const etudiantlist = useSelector(state => state.UserReducer.etudiantlist)
    const rightdrawer = useSelector(state => state.UserReducer.rightdrawer)
    const typedrawer = useSelector(state => state.UserReducer.typedrawer)
    const loading_add_user = useSelector(state => state.UserReducer.loading_add_user)

    const currentPage_non_etudiant = useSelector(state => state.UserReducer.currentPage_non_etudiant)
    const hasNextPage_non_etudiant = useSelector(state => state.UserReducer.hasNextPage_non_etudiant)
    const hasPreviousPage_non_etudiant = useSelector(state => state.UserReducer.hasPreviousPage_non_etudiant)
    const nexPage_non_etudiant = useSelector(state => state.UserReducer.nexPage_non_etudiant)
    const previousPage_non_etudiant = useSelector(state => state.UserReducer.previousPage_non_etudiant)
    const lastPage_non_etudiant = useSelector(state => state.UserReducer.lastPage_non_etudiant)


    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [type, setType] = useState(0);
    const [error, setError] = useState(false)


    useEffect(() => {
        dispatch(getNonEtudiantPaginationService(1, token))
    }, []);


    const handlePagination = (page) => {

        dispatch(getNonEtudiantPaginationService(page, token))
    }


    const handleAddCustomer = () => {
        dispatch(setTypeDrawer("addcustomer"))
        dispatch(setRightDrawer(true))

    }

    const handleCloseRightDrawer = () => {
        dispatch(setTypeDrawer("null"))

        dispatch(setRightDrawer(false))

    }

    const handleFilter = () => {
        dispatch(setTypeDrawer("filter"))
        dispatch(setRightDrawer(true))

    }

    const handleNom = (e) => {
        setNom(e.target.value)
        setError(false)
    }

    const handlePrenom = (e) => {
        setPrenom(e.target.value)
        setError(false)
    }

    const handleEmail = (e) => {
        setEmail(e.target.value)
        setError(false)
    }

    const handleTel = (e) => {
        setTel(e.target.value)
        setError(false)
    }

    const handleType = (e) => {
        console.log(e.target.value)
        setType(e.target.value)
        setError(false)
    }


    const handleAddUser = (e) => {
        e.preventDefault();
        console.log(nom, prenom, email, tel, type)
        if (nom !== "" && nom.trim() !== "" && prenom !== "" && prenom.trim() !== "" && email.trim() !== "" && email !== "" && type !== 0 && type !== "0" && tel !== 0) {
            dispatch(addUserService(email, nom, prenom, tel, "2", token))
        }
        else {
            setError(true)
        }
    }

    let EtudiantMap = etudiantlist.map((item, index) => {

        return (
            <tr key={index}>

                <td>
                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input class="form-check-input" type="checkbox" value="1" />
                    </div>
                </td>
                <td data-filter="mastercard">
                    <img src={Avatar} class="w-35px me-3" alt="" /></td>
                <td>
                    <a href="#" class="text-gray-600 text-hover-primary mb-1">{item.client.nom} {item.client.prenom}</a>
                </td>

                <td>
                    <a href="#" class="text-gray-600 text-hover-primary mb-1">{item.email}</a>
                </td>

                <td>
                    <a href="#" class="text-gray-600 text-hover-primary mb-1">{item.client.status === 1 ? "Etudiant" : "Non-Etudiant"}</a>
                </td>

                <td>
                    <a href="#" class="text-gray-600 text-hover-primary mb-1">{item.client.tel}</a>
                </td>

                <td>
                    <a href="#" class="text-gray-600 text-hover-primary mb-1"> <Moment fromNow>{item.createdAt}</Moment></a>
                </td>

                <td class="text-end">
                    <i class="ki-duotone ki-pencil fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }}>
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </i>
                    <i class="ki-duotone ki-eye fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }}>
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                    </i>
                    <i class="ki-duotone ki-trash-square fs-2" style={{ cursor: 'pointer' }}>
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                        <span class="path4"></span>
                    </i>


                </td>


            </tr>
        )
    })




    return(
        <Layout openDrawer={true}>

        <>
            <div id="kt_app_content" class="app-content flex-column-fluid">

                <div id="kt_app_content_container" class="app-container container-xxl">

                    <div class="card">

                        <div class="card-header border-0 pt-6">

                            <SearchUser />

                            <div class="card-toolbar">

                                <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">

                                    <button onClick={handleFilter} type="button" class="btn btn-light-primary me-3" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                        <i class="ki-outline ki-filter fs-2"></i>Filtrer</button>

                                    <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true" id="kt-toolbar-filter">

                                        <div class="px-7 py-5">
                                            <div class="fs-4 text-dark fw-bold">Filter Options</div>
                                        </div>


                                        <div class="separator border-gray-200"></div>

                                        <div class="px-7 py-5">

                                            <div class="mb-10">

                                                <label class="form-label fs-5 fw-semibold mb-3">Month:</label>


                                                <select class="form-select form-select-solid fw-bold" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-kt-customer-table-filter="month" data-dropdown-parent="#kt-toolbar-filter">
                                                    <option></option>
                                                    <option value="aug">August</option>
                                                    <option value="sep">September</option>
                                                    <option value="oct">October</option>
                                                    <option value="nov">November</option>
                                                    <option value="dec">December</option>
                                                </select>

                                            </div>


                                            <div class="mb-10">

                                                <label class="form-label fs-5 fw-semibold mb-3">Payment Type:</label>


                                                <div class="d-flex flex-column flex-wrap fw-semibold" data-kt-customer-table-filter="payment_type">

                                                    <label class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                                                        <input class="form-check-input" type="radio" name="payment_type" value="all" checked="checked" />
                                                        <span class="form-check-label text-gray-600">All</span>
                                                    </label>


                                                    <label class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                                                        <input class="form-check-input" type="radio" name="payment_type" value="visa" />
                                                        <span class="form-check-label text-gray-600">Visa</span>
                                                    </label>


                                                    <label class="form-check form-check-sm form-check-custom form-check-solid mb-3">
                                                        <input class="form-check-input" type="radio" name="payment_type" value="mastercard" />
                                                        <span class="form-check-label text-gray-600">Mastercard</span>
                                                    </label>


                                                    <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                        <input class="form-check-input" type="radio" name="payment_type" value="american_express" />
                                                        <span class="form-check-label text-gray-600">American Express</span>
                                                    </label>

                                                </div>

                                            </div>


                                            <div class="d-flex justify-content-end">
                                                <button type="reset" class="btn btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" data-kt-customer-table-filter="reset">Reset</button>
                                                <button type="submit" class="btn btn-primary" data-kt-menu-dismiss="true" data-kt-customer-table-filter="filter">Apply</button>
                                            </div>

                                        </div>

                                    </div>



                                    <button type="button" class="btn btn-light-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_customers_export_modal">
                                        <i class="ki-outline ki-exit-up fs-2"></i>Exporter</button>

                                    <button type="button" onClick={handleAddCustomer} class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Ajouter un ressortissant</button>

                                </div>


                                <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                                    <div class="fw-bold me-5">
                                        <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                                    <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                                </div>

                            </div>

                        </div>

                        {loading ?
                            <div style={{ textAlign: 'center' }}>
                                <Loader />
                            </div>
                            :

                            <div class="card-body pt-0">

                                <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_customers_table">
                                    <thead>
                                        <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                            <th class="w-10px pe-2">
                                                <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                    <input class="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_customers_table .form-check-input" value="1" />
                                                </div>
                                            </th>
                                            <th class="min-w-125px">Photo</th>
                                            <th class="min-w-125px">Nom</th>
                                            <th class="min-w-125px">Email</th>
                                            <th class="min-w-125px">Status</th>
                                            <th class="min-w-125px">Telephone</th>
                                            <th class="min-w-125px">Date de création</th>
                                            <th class="text-end min-w-70px">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody class="fw-semibold text-gray-600">

                                        {EtudiantMap.length > 0 ? EtudiantMap : null}

                                    </tbody>
                                </table>
                                <ul class="pagination" style={{ float: 'right' }}>

                                {
                                (currentPage_non_etudiant != 1 && previousPage_non_etudiant!=1 )? <li onClick={() => handlePagination(1)} class="page-item "><a href="#" class="page-link">1</a></li> : null
                            }
                            {
                                hasPreviousPage_non_etudiant ? <li class="page-item" onClick={() => handlePagination(previousPage_non_etudiant)}><a href="#" class="page-link">{previousPage_non_etudiant}<i class="previous"></i></a></li> : null
                            }
                            {

                            }
                            <li class="page-item active"><a href="#" class="page-link">{currentPage_non_etudiant}</a></li>

                            {
                                hasNextPage_non_etudiant ? <li class="page-item next" onClick={() => handlePagination(nexPage_non_etudiant)}><a href="#" class="page-link">{nexPage_non_etudiant}<i class="next"></i></a></li> : null
                            }
                            {
                                (lastPage_non_etudiant !== currentPage_non_etudiant && nexPage_non_etudiant !== lastPage_non_etudiant) ? <li onClick={() => handlePagination(lastPage_non_etudiant)} class="page-item "><a href="#" class="page-link">{lastPage_non_etudiant}</a></li> : null
                            }

                                </ul>

                            </div>
                        }

                    </div>



                    <div class="modal fade" id="kt_customers_export_modal" tabindex="-1" aria-hidden="true">

                        <div class="modal-dialog modal-dialog-centered mw-650px">

                            <div class="modal-content">

                                <div class="modal-header">

                                    <h2 class="fw-bold">Export Customers</h2>

                                    <div id="kt_customers_export_close" class="btn btn-icon btn-sm btn-active-icon-primary">
                                        <i class="ki-outline ki-cross fs-1"></i>
                                    </div>

                                </div>

                                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">

                                    <form id="kt_customers_export_form" class="form" action="#">

                                        <div class="fv-row mb-10">

                                            <label class="fs-5 fw-semibold form-label mb-5">Select Export Format:</label>


                                            <select data-control="select2" data-placeholder="Select a format" data-hide-search="true" name="format" class="form-select form-select-solid">
                                                <option value="excell">Excel</option>
                                                <option value="pdf">PDF</option>
                                                <option value="cvs">CVS</option>
                                                <option value="zip">ZIP</option>
                                            </select>

                                        </div>


                                        <div class="fv-row mb-10">

                                            <label class="fs-5 fw-semibold form-label mb-5">Select Date Range:</label>


                                            <input class="form-control form-control-solid" placeholder="Pick a date" name="date" />

                                        </div>


                                        <div class="row fv-row mb-15">

                                            <label class="fs-5 fw-semibold form-label mb-5">Payment Type:</label>


                                            <div class="d-flex flex-column">

                                                <label class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                    <input class="form-check-input" type="checkbox" value="1" checked="checked" name="payment_type" />
                                                    <span class="form-check-label text-gray-600 fw-semibold">All</span>
                                                </label>


                                                <label class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                    <input class="form-check-input" type="checkbox" value="2" checked="checked" name="payment_type" />
                                                    <span class="form-check-label text-gray-600 fw-semibold">Visa</span>
                                                </label>


                                                <label class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                    <input class="form-check-input" type="checkbox" value="3" name="payment_type" />
                                                    <span class="form-check-label text-gray-600 fw-semibold">Mastercard</span>
                                                </label>


                                                <label class="form-check form-check-custom form-check-sm form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="4" name="payment_type" />
                                                    <span class="form-check-label text-gray-600 fw-semibold">American Express</span>
                                                </label>

                                            </div>

                                        </div>


                                        <div class="text-center">
                                            <button type="reset" id="kt_customers_export_cancel" class="btn btn-light me-3">Discard</button>
                                            <button type="submit" id="kt_customers_export_submit" class="btn btn-primary">
                                                <span class="indicator-label">Submit</span>
                                                <span class="indicator-progress">Please wait...
                                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
                                        </div>

                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
            <div id="kt_drawer_chat" class={rightdrawer ? "bg-body drawer drawer-end drawer-on" : "bg-body drawer drawer-end drawer-off"} data-kt-drawer="true" data-kt-drawer-name="chat" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'md': '500px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_drawer_chat_toggle" data-kt-drawer-close="#kt_drawer_chat_close" style={{ width: "35%" }}>

                {/* Drawer */}
                <div class="card w-100 border-0 rounded-0" id="kt_drawer_chat_messenger">

                    <div class="card-header pe-5" id="kt_drawer_chat_messenger_header">

                        <div class="card-title">
                            {
                                typedrawer === "addcustomer" ?
                                    <div class="d-flex justify-content-center flex-column me-3">
                                        <a href="#" class="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1">Ajouter un ressortissant</a>


                                        <div class="mb-0 lh-1">
                                            <span class="badge badge-success badge-circle w-10px h-10px me-1"></span>
                                            <span class="fs-7 fw-semibold text-muted">Actif</span>
                                        </div>

                                    </div>
                                    :
                                    typedrawer === "filter" ?
                                        <div class="d-flex justify-content-center flex-column me-3">
                                            <a href="#" class="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1">Filter un ressortissant</a>


                                            <div class="mb-0 lh-1">
                                                <span class="badge badge-success badge-circle w-10px h-10px me-1"></span>
                                                <span class="fs-7 fw-semibold text-muted">Actif</span>
                                            </div>

                                        </div>
                                        : null
                            }


                        </div>



                        <div class="card-toolbar">

                            <div class="btn btn-sm btn-icon btn-active-color-primary" id="kt_drawer_chat_close" onClick={handleCloseRightDrawer}>
                                <i class="ki-duotone ki-cross-square fs-2"><span class="path1"></span><span class="path2"></span></i>                </div>

                        </div>

                    </div>

                    <div class="card-body" id="kt_drawer_chat_messenger_body">

                        <div class="scroll-y me-n5 pe-5" data-kt-element="messages" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer" data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body" data-kt-scroll-offset="0px" style={{ height: "733px" }}>


                            {
                                typedrawer === "addcustomer" ?

                                    <form class="form" action="#" id="kt_modal_add_customer_form">

                                        <div class="modal-body py-10 px-lg-17">

                                            <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">


                                                <div class="fv-row mb-7">

                                                    <label class="required fs-6 fw-semibold mb-2">Nom</label>

                                                    <input type="text" onChange={handleNom} class="form-control form-control-solid" placeholder="" name="name" value={nom} />

                                                </div>

                                                <div class="fv-row mb-7">

                                                    <label class="required fs-6 fw-semibold mb-2">Prenom</label>

                                                    <input type="text" onChange={handlePrenom} class="form-control form-control-solid" placeholder="" name="name" value={prenom} />

                                                </div>


                                                <div class="fv-row mb-7">

                                                    <label class="fs-6 fw-semibold mb-2">
                                                        <span class="required">Email</span>
                                                        <span class="ms-1" data-bs-toggle="tooltip" title="Email address must be active">
                                                            <i class="ki-outline ki-information fs-7"></i>
                                                        </span>
                                                    </label>


                                                    <input type="email" onChange={handleEmail} class="form-control form-control-solid" placeholder="" name="email" value={email} />

                                                </div>


                                                <div class="fv-row mb-15">

                                                    <label class="fs-6 fw-semibold mb-2">Telephone</label>


                                                    <input type="number" onChange={handleTel} class="form-control form-control-solid" placeholder="" name="tel" value={tel} />

                                                </div>



                                                <div id="kt_modal_add_customer_billing_info" class="collapse show">

                                                    <div class="d-flex flex-column mb-7 fv-row">

                                                        <label class="fs-6 fw-semibold mb-2">
                                                            <span class="required">Statut</span>
                                                            <span class="ms-1" data-bs-toggle="tooltip" title="Country of origination">
                                                                <i class="ki-outline ki-information fs-7"></i>
                                                            </span>
                                                        </label>


                                                        {/* <select name="country" aria-label="Select a Country" data-control="select2" data-placeholder="Select a Country..." data-dropdown-parent="#kt_modal_add_customer" class="form-select form-select-solid fw-bold" onChange={handleType}>
                                                            <option value="0">Choisissez le statut</option>
                                                            <option value="1">Etudiant</option>
                                                            <option value="2">Non - Etudiant</option>

                                                        </select> */}

                                                    </div>



                                                </div>
                                                {
                                                    error ?
                                                        <div><p style={{ color: 'red' }}> Verifiez vos informations!</p></div>
                                                        :
                                                        null
                                                }

                                            </div>

                                        </div>


                                    </form>

                                    :

                                    typedrawer === "filter" ?

                                        <form class="form" action="#" id="kt_modal_add_customer_form">

                                            <div class="modal-body py-10 px-lg-17">

                                                <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">

                                                    <div class=" w-300px w-md-325px" data-kt-menu="true" id="kt-toolbar-filter">






                                                        <div class="px-7 py-5">

                                                            <div class="mb-10">

                                                                <label class="form-label fs-5 fw-semibold mb-3">Month:</label>


                                                                <select class="form-select form-select-solid fw-bold" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-kt-customer-table-filter="month" data-dropdown-parent="#kt-toolbar-filter">
                                                                    <option></option>
                                                                    <option value="aug">August</option>
                                                                    <option value="sep">September</option>
                                                                    <option value="oct">October</option>
                                                                    <option value="nov">November</option>
                                                                    <option value="dec">December</option>
                                                                </select>

                                                            </div>


                                                            <div class="mb-10">

                                                                <label class="form-label fs-5 fw-semibold mb-3">Payment Type:</label>


                                                                <div class="d-flex flex-column flex-wrap fw-semibold" data-kt-customer-table-filter="payment_type">

                                                                    <label class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                                                                        <input class="form-check-input" type="radio" name="payment_type" value="all" checked="checked" />
                                                                        <span class="form-check-label text-gray-600">All</span>
                                                                    </label>


                                                                    <label class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                                                                        <input class="form-check-input" type="radio" name="payment_type" value="visa" />
                                                                        <span class="form-check-label text-gray-600">Visa</span>
                                                                    </label>


                                                                    <label class="form-check form-check-sm form-check-custom form-check-solid mb-3">
                                                                        <input class="form-check-input" type="radio" name="payment_type" value="mastercard" />
                                                                        <span class="form-check-label text-gray-600">Mastercard</span>
                                                                    </label>


                                                                    <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                                        <input class="form-check-input" type="radio" name="payment_type" value="american_express" />
                                                                        <span class="form-check-label text-gray-600">American Express</span>
                                                                    </label>

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </form>

                                        :

                                        null
                            }

                        </div>

                    </div>

                    <div class="card-footer pt-4" id="kt_drawer_chat_messenger_footer" style={{ display: 'flex', justifyContent: 'end' }}>

                        {
                            loading_add_user ?
                                <Loader />
                                :
                                <>
                                    <button onClick={handleCloseRightDrawer} type="reset" id="kt_modal_add_customer_cancel" class="btn btn-light me-3">Annuler</button>

                                    <button onClick={handleAddUser} type="submit" id="kt_modal_add_customer_submit" class="btn btn-primary">
                                        <span class="indicator-label">Valider</span>
                                        <span class="indicator-progress">Please wait...
                                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                    </button>
                                </>
                        }




                    </div>




                </div>

            </div>
        </>



    </Layout>
    )
};
export default Ressortissants;