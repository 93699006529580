import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  searchUserService } from '../../actions/user/user';

const SearchUser = () => {
    
    const dispatch = useDispatch();

    const token = useSelector(state => state.AuthReducer.token)
    
    const [searchUser, setSearchUser] = useState("")

    const handleSearchUser = (e) => {
       
        setSearchUser(e.target.value)
        console.log(e.target.value)
        dispatch(searchUserService(e.target.value, token))
     }


    return(
        <div class="card-title">

        <div class="d-flex align-items-center position-relative my-1">
            <i class="ki-outline ki-magnifier fs-3 position-absolute ms-5"></i>
            <input type="text" class="form-control form-control-solid w-250px ps-12" value={searchUser} onChange={handleSearchUser} placeholder="Rechercher des utilisateurs" />
        </div>

    </div>
    )
};

export default SearchUser;