import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//IMPORT DEPENDENCIES
import { BrowserRouter } from 'react-router-dom'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import {persistStore, persistReducer} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';

//IMPORT REDUCER
import AppSettingsReducer from './reducers/appsettings/appsettings';
import AuthReducer from './reducers/auth/auth';
import HomeReducer from './reducers/home/home';
import UserReducer from './reducers/user/user';
import ArticleReducer from './reducers/article/article';
import NotificationReducer from './reducers/notification/notification';

const RootReducer = combineReducers({
  AppSettingsReducer: AppSettingsReducer,
  AuthReducer:AuthReducer,
  HomeReducer:HomeReducer,
  UserReducer:UserReducer,
  ArticleReducer:ArticleReducer,
  NotificationReducer:NotificationReducer
})

const persistConfig = {
  key:'root',
  storage
}


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, RootReducer)


let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))

let persistore = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistore}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
    </PersistGate>
  </Provider>, document.getElementById('root')

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
