// IMPORT DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenUserDrawer, setOpenArticleDrawer, setOpenRendezVousDrawer, setOpenNotificationdrawer, setOpenSettingsdrawer } from '../../actions/appsettings/appsettings'
import { logOut } from '../../actions/auth/auth'


// IMPORT IMAGES
import Logo from '../../assets/images/logo-.png';

// IMPORT CSS
import '../../assets/css/mainstyle.css';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

const Navbar = ({ onChangeDrawer, isDrawerOpen }) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const { pathname } = location;

    const openuserdrawer = useSelector((state) => state.AppSettingsReducer.openuserdrawer)
    const openarticledrawer = useSelector((state) => state.AppSettingsReducer.openarticledrawer)
    const openrendezvousdrawer = useSelector((state) => state.AppSettingsReducer.openrendezvousdrawer)
    const opensettingsdrawer = useSelector((state) => state.AppSettingsReducer.opensettingsdrawer)
    const opennotificationdrawer = useSelector((state) => state.AppSettingsReducer.opennotificationdrawer)


    const [activeHome, setActiveHome] = useState(false)
    const [activeUser, setActiveUser] = useState(false)
    const [activeArticle, setActiveArticle] = useState(false)
    const [activeRendezVous, setActiveRendezVous] = useState(false)
    const [activeNotification, setActiveNotification] = useState(false)
    const [activeSettings, setActiveSettings] = useState(false)


    const [drawer, setDrawer] = useState(false)
    const [user, setUser] = useState(false)
    const [projet, setProjet] = useState(false)
    const [investissement, setInvestissement] = useState(false)
    const [organisation, setOrganisation] = useState(false)
    const [settings, setSettings] = useState(false)


    useEffect(() => {
        handleUserRoute(pathname);
        handleProjectRoute(pathname);
        handleInvestmentRoute(pathname);
        // handlePaymentRoute(pathname);
        handleNotificationRoute(pathname);
        handleSettingRoute(pathname);
    }, [pathname])


    const handleUserRoute = (routePathname) => {
        const userRoutes = ["/userlist", '/etudiants', '/ressortissants', '/requetes']
        if (userRoutes.includes(routePathname)) setActiveUser(true)
    }
    const handleProjectRoute = (routePathname) => {
        const projectRoutes = ["/articles", '/article-etudiant', '/article-non-etudiant']
        if (projectRoutes.includes(routePathname)) setActiveArticle(true)
    }

    const handleInvestmentRoute = (routePathname) => {
        const projectRoutes = ["/rendez-vous", '/rendez-vous-etudiant', '/rendez-vous-particulier']
        if (projectRoutes.includes(routePathname)) setActiveRendezVous(true)
    }

    const handleNotificationRoute = (routePathname) => {
        const projectRoutes = ["/notifications", '/notification-etudiant', '/notification-non-etudiant', '/notification-particulier']
        if (projectRoutes.includes(routePathname)) setActiveNotification(true)
    }

    const handleSettingRoute = (routePathname) => {
        const projectRoutes = ["/settings", '/gestion-des-roles', '/preferences']
        if (projectRoutes.includes(routePathname)) setActiveSettings(true)
    }


    const handleHome = () => {
        onChangeDrawer(false)
        setDrawer(false)
        setUser(false)
        setProjet(false)
        setInvestissement(false)
        setOrganisation(false)
        setSettings(false)
        dispatch(setOpenUserDrawer(false))
        dispatch(setOpenArticleDrawer(false))
        dispatch(setOpenRendezVousDrawer(false))
        dispatch(setOpenNotificationdrawer(false))
        dispatch(setOpenSettingsdrawer(false))

    }

    const handleUsers = () => {
        if (user || openuserdrawer) {
            if (isDrawerOpen) {
                onChangeDrawer(false)
            }
            setUser(false)
            dispatch(setOpenUserDrawer(false))

        }
        else {
            if (!isDrawerOpen) {
                onChangeDrawer(true)

            }
            setUser(true)
            dispatch(setOpenUserDrawer(!openuserdrawer))
            history.push('/userlist')
        }
        setProjet(false)
        setInvestissement(false)
        setOrganisation(false)
        setSettings(false)
        dispatch(setOpenArticleDrawer(false))
        dispatch(setOpenRendezVousDrawer(false))
        dispatch(setOpenNotificationdrawer(false))
        dispatch(setOpenSettingsdrawer(false))

    }

    const handleProjets = () => {

        if (projet || openarticledrawer) {
            if (isDrawerOpen) {
                onChangeDrawer(false)
            }
            setProjet(false)
            dispatch(setOpenArticleDrawer(false))
        }
        else {
            if (!isDrawerOpen) {
                onChangeDrawer(true)
            }
            setProjet(true)
            dispatch(setOpenArticleDrawer(!openarticledrawer))
            history.push('/articles')
        }
        setUser(false)
        setInvestissement(false)
        setOrganisation(false)
        setSettings(false)
        dispatch(setOpenUserDrawer(false))
        dispatch(setOpenRendezVousDrawer(false))
        dispatch(setOpenNotificationdrawer(false))
        dispatch(setOpenSettingsdrawer(false))

    }

    const handleInvestissement = () => {

        if (investissement || openrendezvousdrawer) {
            if (isDrawerOpen) {
                onChangeDrawer(false)
            }
            setInvestissement(false)
            dispatch(setOpenRendezVousDrawer(false))

        }
        else {
            if (!isDrawerOpen) {
                onChangeDrawer(true)
            }
            setInvestissement(true)
            dispatch(setOpenRendezVousDrawer(!openrendezvousdrawer))
            history.push('/rendez-vous')
        }
        setProjet(false)
        setUser(false)
        setOrganisation(false)
        setSettings(false)
        dispatch(setOpenUserDrawer(false))
        dispatch(setOpenArticleDrawer(false))
        dispatch(setOpenNotificationdrawer(false))
        dispatch(setOpenSettingsdrawer(false))

    }

    const handleOrganisation = () => {

        if (organisation || opennotificationdrawer) {
            if (isDrawerOpen) {
                onChangeDrawer(false)
            }
            setOrganisation(false)
            dispatch(setOpenNotificationdrawer(false))

        }
        else {
            if (!isDrawerOpen) {
                onChangeDrawer(true)
            }
            setOrganisation(true)
            dispatch(setOpenNotificationdrawer(!opennotificationdrawer))
            history.push('/notifications')
        }
        setInvestissement(false)
        setProjet(false)
        setUser(false)
        setSettings(false)
        dispatch(setOpenUserDrawer(false))
        dispatch(setOpenArticleDrawer(false))
        dispatch(setOpenSettingsdrawer(false))
        dispatch(setOpenRendezVousDrawer(false))
    }

    const handleSettings = () => {

        if (settings || opensettingsdrawer) {
            if (isDrawerOpen) {
                onChangeDrawer(false)
            }
            setSettings(false)
            dispatch(setOpenSettingsdrawer(false))
        }
        else {
            if (!isDrawerOpen) {
                onChangeDrawer(true)
            }
            setSettings(true)
            dispatch(setOpenSettingsdrawer(!opensettingsdrawer))
            history.push('/settings')
        }
        setOrganisation(false)
        setInvestissement(false)
        setProjet(false)
        setUser(false)
        dispatch(setOpenUserDrawer(false))
        dispatch(setOpenArticleDrawer(false))
        dispatch(setOpenRendezVousDrawer(false))
        dispatch(setOpenNotificationdrawer(false))
    }

    const handleLogout = () => {
        dispatch(logOut())
    }

    return (
        <div id="kt_app_sidebar" class="app-sidebar" data-kt-drawer="true"

            data-kt-app-sidebar-secondary-collapse={drawer ? "on" : ""}
            data-kt-app-sidebar-push-footer="true" data-kt-app-sidebar-secondary-enabled="true" >

            <div class="app-sidebar-primary" style={{ backgroundColor: '#101010' }}>

                <div class="app-sidebar-logo d-none d-md-flex flex-center pt-10 mb-5 mb-lg-17" id="kt_app_sidebar_logo">

                    <a href="#">
                        <img alt="Logo" src={Logo} class="h-80px" />
                    </a>
                </div>

                <div class="app-sidebar-menu flex-grow-1 hover-scroll-overlay-y scroll-ps mx-2 my-5" id="kt_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer" data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px">

                    <div id="kt_aside_menu" class="menu menu-rounded menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-6" data-kt-menu="true">

                        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item here show py-2 ">

                            <span class="menu-link menu-center" onClick={handleHome}>
                                <Link to='/home' >

                                    <span class="menu-icon me-0 ">
                                        <i class="ki-outline ki-home-2 fs-2x " style={{ color: pathname === '/' ? 'white' : '#EF7400' }} ></i>
                                        {/* <span class="tooltiptext" style={{fontSize:12}}>Dashboard</span> */}
                                    </span>
                                </Link>
                            </span>
                        </div>

                        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item py-2">
                            <span class="menu-icon me-0">
                                <span class="menu-link menu-center" onClick={handleUsers}>
                                    <i class="ki-outline  ki-duotone ki-profile-user fs-2x" style={{ color: (pathname === '/userlist' || activeUser) ? 'white' : '#EF7400' }}></i>
                                </span>
                            </span>

                        </div>


                        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item py-2">
                            <span class="menu-icon me-0">
                                <span class="menu-link menu-center" onClick={handleProjets}>
                                    <i class="ki-outline ki-duotone ki-file  fs-2x" style={{ color: (pathname === '/articles' || activeArticle) ? 'white' : '#EF7400' }}></i>
                                </span>
                            </span>
                        </div>


                        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item py-2">
                            <span class="menu-icon me-0">
                                <span class="menu-link menu-center" onClick={handleInvestissement}>
                                    <i class="ki-outline ki-duotone ki-abstract-45 fs-2x" style={{ color: (pathname === '/rendez-vous' || activeRendezVous) ? 'white' : '#EF7400' }} ></i>
                                </span>
                            </span>
                        </div>


                        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item py-2">

                            <span class="menu-icon me-0">
                                <span class="menu-link menu-center" onClick={handleOrganisation}>

                                    <i class="ki-outline ki-duotone ki-people fs-2x" style={{ color: (pathname === '/notifications' || activeNotification) ? 'white' : '#EF7400' }}></i>

                                </span>
                            </span>

                        </div>

                        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item py-2">
                            <span class="menu-icon me-0">
                                <span class="menu-link menu-center" onClick={handleSettings}>

                                    <i class="ki-outline ki-duotone ki-setting-2  fs-2x" style={{ color: (pathname === '/settings' || activeSettings) ? 'white' : '#EF7400' }}></i>

                                </span>
                            </span>
                        </div>

                        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item py-2">

                            <span class="menu-link menu-center" onClick={handleLogout}>
                                <span class="menu-icon me-0">
                                    <i class="ki-duotone ki-lock fs-2x" style={{ color: '#EF7400' }}>
                                        <span class="path1"></span>
                                        <span class="path2"></span>
                                        <span class="path3"></span>
                                    </i>

                                </span>
                            </span>

                        </div>
                    </div>
                </div>
            </div>


            <div class="app-sidebar-secondary" style={{ backgroundColor: '#383838' }}>

                <div class="d-flex flex-column">

                    {
                        (openuserdrawer || user) ?
                            <div style={{ width: 18 + 'rem' }}>
                                <div class="d-flex flex-column pt-10 ps-11" id="kt_app_sidebar_secondary_header">

                                    {/* <a href="../../demo29/dist/index.html" class="d-flex align-items-center custom-link fs-6 fw-semibold mb-5">
                                        <i class="ki-outline ki-black-left fs-2 me-3 text-white opacity-50"></i>Back to list</a> */}

                                    <span class="fs-2 fw-bolder text-white">Utilisateurs</span>
                                </div>

                                <div class="app-sidebar-secondary-menu menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 py-4 py-lg-6" id="kt_app_sidebar_secondary_menu" data-kt-menu="true">
                                    <div id="kt_app_sidebar_secondary_menu_wrapper" class="hover-scroll-y px-3 mx-3" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_secondary_header" data-kt-scroll-wrappers="#kt_app_sidebar_secondary_menu" data-kt-scroll-offset="20px">

                                        <div class="menu-item">

                                            <div class="menu-content">
                                                <span class="menu-section fs-5 fw-bolder ps-1 py-1" style={{ color: '#EF7400' }}>Actions</span>
                                            </div>
                                        </div>


                                        <div class="menu-item">

                                            <Link to='/userlist' class="menu-link" style={{ backgroundColor: pathname === '/userlist' ? '#EF7400' : '' }}>
                                                <span class="menu-icon me-0">

                                                    <i class="ki-outline  ki-duotone ki-profile-user fs-1x" style={{ color: pathname === '/userlist' ? 'white' : '#FFD1A6' }}></i>

                                                </span>

                                                <span class="menu-title" style={{ color: pathname === '/userlist' ? 'white' : '#FFD1A6' }}>Tous les utilisateurs</span>
                                            </Link>

                                        </div>


                                        <div class="menu-item">

                                            <Link to='/etudiants' class="menu-link" style={{ backgroundColor: pathname === '/etudiants' ? '#EF7400' : '' }}>
                                                <span class="menu-icon me-0">

                                                    <i class="ki-outline  ki-duotone ki-profile-user fs-1x" style={{ color: pathname === '/etudiants' ? 'white' : '#FFD1A6' }}></i>

                                                </span>
                                                <span class="menu-title" style={{ color: pathname === '/etudiants' ? 'white' : '#FFD1A6' }}>Etudiants</span>
                                            </Link>

                                        </div>


                                        <div class="menu-item">

                                            <Link to='/ressortissants' class="menu-link" style={{ backgroundColor: pathname === '/ressortissants' ? '#EF7400' : '' }} >
                                                <span class="menu-icon me-0">

                                                    <i class="ki-outline  ki-duotone ki-profile-user fs-1x" style={{ color: pathname === '/ressortissants' ? 'white' : '#FFD1A6' }}></i>

                                                </span>
                                                <span class="menu-title" style={{ color: pathname === '/ressortissants' ? 'white' : '#FFD1A6' }}>Ressortissants(Non-Etudiants)</span>
                                            </Link>
                                        </div>

                                        <span class="d-flex position-relative" style={{ color: '#EF7400' }}>

                                            <span class="d-inline-block position-absolute h-1px bottom-0 end-0 start-0 translate rounded" style={{ backgroundColor: '#888' }}></span>

                                        </span>


                                        <div class="menu-item">

                                            <Link to='/requetes' class="menu-link" style={{ backgroundColor: pathname === '/requetes' ? '#EF7400' : '' }} >
                                                <span class="menu-icon me-0">

                                                    <i class="ki-outline ki-duotone ki-abstract-16 fs-1x" style={{ color: pathname === '/requetes' ? 'white' : '#FFD1A6' }}></i>

                                                </span>
                                                <span class="menu-title" style={{ color: pathname === '/requetes' ? 'white' : '#FFD1A6' }}>Requetes</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            :
                            (projet || openarticledrawer) ?
                                <div style={{ width: 18 + 'rem' }}>
                                    <div class="d-flex flex-column pt-10 ps-11" id="kt_app_sidebar_secondary_header">

                                        {/* <a href="../../demo29/dist/index.html" class="d-flex align-items-center custom-link fs-6 fw-semibold mb-5">
                                    <i class="ki-outline ki-black-left fs-2 me-3 text-white opacity-50"></i>Back to list</a> */}

                                        <span class="fs-2 fw-bolder text-white">Articles</span>
                                    </div>

                                    <div class="app-sidebar-secondary-menu menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 py-4 py-lg-6" id="kt_app_sidebar_secondary_menu" data-kt-menu="true">
                                        <div id="kt_app_sidebar_secondary_menu_wrapper" class="hover-scroll-y px-3 mx-3" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_secondary_header" data-kt-scroll-wrappers="#kt_app_sidebar_secondary_menu" data-kt-scroll-offset="20px">

                                            <div class="menu-item">

                                                <div class="menu-content">
                                                    <span class="menu-section fs-5 fw-bolder ps-1 py-1" style={{ color: '#EF7400' }}>Actions</span>
                                                </div>
                                            </div>


                                            <div class="menu-item">

                                                <Link to='/articles' class="menu-link" style={{ backgroundColor: pathname === '/articles' ? '#EF7400' : '' }}>
                                                    <span class="menu-icon me-0">

                                                        <i class="ki-outline ki-duotone ki-file  fs-1x" style={{ color: pathname === '/articles' ? 'white' : '#FFD1A6' }}></i>

                                                    </span>
                                                    <span class="menu-title" style={{ color: pathname === '/articles' ? 'white' : '#FFD1A6' }}>Tous les articles</span>
                                                </Link>

                                            </div>


                                            <div class="menu-item">

                                                <Link to='/article-etudiant' class="menu-link" style={{ backgroundColor: pathname === '/article-etudiant' ? '#EF7400' : '' }}>
                                                    <span class="menu-icon me-0">

                                                        <i class="ki-outline ki-duotone ki-file  fs-1x" style={{ color: pathname === '/article-etudiant' ? 'white' : '#FFD1A6' }}></i>

                                                    </span>
                                                    <span class="menu-title" style={{ color: pathname === '/article-etudiant' ? 'white' : '#FFD1A6' }}>Articles etudiants</span>
                                                </Link>

                                            </div>

                                            <div class="menu-item">

                                                <Link to='/article-non-etudiant' class="menu-link" style={{ backgroundColor: pathname === '/article-non-etudiant' ? '#EF7400' : '' }}>
                                                    <span class="menu-icon me-0">

                                                        <i class="ki-outline ki-duotone ki-file  fs-1x" style={{ color: pathname === '/article-non-etudiant' ? 'white' : '#FFD1A6' }}></i>

                                                    </span>
                                                    <span class="menu-title" style={{ color: pathname === '/article-non-etudiant' ? 'white' : '#FFD1A6' }}>Articles Non-Etudiants</span>
                                                </Link>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                                :
                                (investissement || openrendezvousdrawer) ?
                                    <div style={{ width: 18 + 'rem' }}>
                                        <div class="d-flex flex-column pt-10 ps-11" id="kt_app_sidebar_secondary_header">

                                            <span class="fs-2 fw-bolder text-white">Rendez-vous</span>
                                        </div>

                                        <div class="app-sidebar-secondary-menu menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 py-4 py-lg-6" id="kt_app_sidebar_secondary_menu" data-kt-menu="true">
                                            <div id="kt_app_sidebar_secondary_menu_wrapper" class="hover-scroll-y px-3 mx-3" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_secondary_header" data-kt-scroll-wrappers="#kt_app_sidebar_secondary_menu" data-kt-scroll-offset="20px">

                                                <div class="menu-item">

                                                    <div class="menu-content">
                                                        <span class="menu-section fs-5 fw-bolder ps-1 py-1" style={{ color: '#EF7400' }}>Actions</span>
                                                    </div>
                                                </div>



                                                <div class="menu-item">

                                                    <Link to='/rendez-vous' class="menu-link" style={{ backgroundColor: pathname === '/rendez-vous' ? '#EF7400' : '' }}>
                                                        <span class="menu-icon me-0">

                                                            <i class="ki-outline ki-duotone ki-abstract-45 fs-1x" style={{ color: pathname === '/rendez-vous' ? 'white' : '#FFD1A6' }}></i>

                                                        </span>
                                                        <span style={{ color: pathname === '/rendez-vous' ? 'white' : '#FFD1A6' }} class="menu-title">Tous les rendez-vous</span>
                                                    </Link>

                                                </div>


                                                <div class="menu-item">

                                                    <Link to='/rendez-vous-etudiant' class="menu-link" style={{ backgroundColor: pathname === '/rendez-vous-etudiant' ? '#EF7400' : '' }}>
                                                        <span class="menu-icon me-0">

                                                            <i class="ki-outline ki-duotone ki-abstract-45 fs-1x" style={{ color: pathname === '/rendez-vous-etudiant' ? 'white' : '#FFD1A6' }}></i>
                                                        </span>
                                                        <span style={{ color: pathname === '/rendez-vous-etudiant' ? 'white' : '#FFD1A6' }} class="menu-title">Rendez-vous Etudiants</span>
                                                    </Link>

                                                </div>

                                                <div class="menu-item">

                                                    <Link to='/rendez-vous-particulier' class="menu-link" style={{ backgroundColor: pathname === '/rendez-vous-particulier' ? '#EF7400' : '' }} >
                                                        <span class="menu-icon me-0">

                                                            <i class="ki-outline ki-duotone ki-abstract-45 fs-1x" style={{ color: pathname === '/rendez-vous-particulier' ? 'white' : '#FFD1A6' }}></i>

                                                        </span>
                                                        <span class="menu-title" style={{ color: pathname === '/rendez-vous-particulier' ? 'white' : '#FFD1A6' }}>Rendez-vous particulier</span>
                                                    </Link>

                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                    //         : paiement ?

                                    //             <div style={{ width: 18 + 'rem' }}>
                                    //                 <div class="d-flex flex-column pt-10 ps-11" id="kt_app_sidebar_secondary_header">

                                    //                     {/* <a href="../../demo29/dist/index.html" class="d-flex align-items-center custom-link fs-6 fw-semibold mb-5">
                                    // <i class="ki-outline ki-black-left fs-2 me-3 text-white opacity-50"></i>Back to list</a> */}

                                    //                     <span class="fs-2 fw-bolder text-white">Paiements</span>
                                    //                 </div>

                                    //                 <div class="app-sidebar-secondary-menu menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 py-4 py-lg-6" id="kt_app_sidebar_secondary_menu" data-kt-menu="true">
                                    //                     <div id="kt_app_sidebar_secondary_menu_wrapper" class="hover-scroll-y px-3 mx-3" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_secondary_header" data-kt-scroll-wrappers="#kt_app_sidebar_secondary_menu" data-kt-scroll-offset="20px">

                                    //                         <div class="menu-item">

                                    //                             <div class="menu-content">
                                    //                                 <span class="menu-section fs-5 fw-bolder ps-1 py-1" style={{ color: '#8e736a' }}>Actions</span>
                                    //                             </div>
                                    //                         </div>


                                    //                         <div class="menu-item">

                                    //                             <a class="menu-link active" href="#" style={{ backgroundColor: '#8e736a' }}>
                                    //                                 <span class="menu-icon me-0">

                                    //                                     <i class="ki-outline ki-duotone ki-wallet fs-1x"></i>

                                    //                                 </span>
                                    //                                 <span class="menu-title">Tous les paiements</span>
                                    //                             </a>

                                    //                         </div>
                                    //                         <div class="menu-item">

                                    //                             <a class="menu-link" href="../../demo29/dist/dashboards/ecommerce.html">
                                    //                                 <span class="menu-icon me-0">

                                    //                                     <i class="ki-outline ki-duotone ki-wallet fs-1x" style={{ color: 'white' }}></i>

                                    //                                 </span>
                                    //                                 <span class="menu-title" style={{ color: '#8e736a' }}>En cours</span>
                                    //                             </a>

                                    //                         </div>



                                    //                         <div class="menu-item">

                                    //                             <a class="menu-link" href="../../demo29/dist/dashboards/ecommerce.html">
                                    //                                 <span class="menu-icon me-0">

                                    //                                     <i class="ki-outline ki-duotone ki-wallet fs-1x" style={{ color: 'white' }}></i>

                                    //                                 </span>
                                    //                                 <span class="menu-title" style={{ color: '#8e736a' }}>Terminés</span>
                                    //                             </a>

                                    //                         </div>


                                    //                     </div>
                                    //                 </div>

                                    //             </div>

                                    : (organisation || opennotificationdrawer) ?
                                        <div style={{ width: 18 + 'rem' }}>
                                            <div class="d-flex flex-column pt-10 ps-11" id="kt_app_sidebar_secondary_header">


                                                <span class="fs-2 fw-bolder text-white">Notifications</span>
                                            </div>

                                            <div class="app-sidebar-secondary-menu menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 py-4 py-lg-6" id="kt_app_sidebar_secondary_menu" data-kt-menu="true">
                                                <div id="kt_app_sidebar_secondary_menu_wrapper" class="hover-scroll-y px-3 mx-3" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_secondary_header" data-kt-scroll-wrappers="#kt_app_sidebar_secondary_menu" data-kt-scroll-offset="20px">

                                                    <div class="menu-item">

                                                        <div class="menu-content">
                                                            <span class="menu-section fs-5 fw-bolder ps-1 py-1" style={{ color: '#EF7400' }}>Actions</span>
                                                        </div>
                                                    </div>


                                                    <div class="menu-item">

                                                        <Link to='/notifications' class="menu-link" style={{ backgroundColor: pathname === '/notifications' ? '#EF7400' : '' }}>
                                                            <span class="menu-icon me-0">

                                                                <i class="ki-outline ki-duotone ki-people fs-1x" style={{ color: pathname === '/notifications' ? 'white' : '#FFD1A6' }}></i>

                                                            </span>
                                                            <span class="menu-title" style={{ color: pathname === '/notifications' ? 'white' : '#FFD1A6' }}>Toutes les notifications</span>
                                                        </Link>

                                                    </div>
                                                    <div class="menu-item">

                                                        <Link to='/notification-etudiant' class="menu-link" style={{ backgroundColor: pathname === '/notification-etudiant' ? '#EF7400' : '' }} >
                                                            <span class="menu-icon me-0">

                                                                <i class="ki-outline ki-duotone ki-people fs-1x" style={{ color: pathname === '/notification-etudiant' ? 'white' : '#FFD1A6' }}></i>

                                                            </span>
                                                            <span class="menu-title" style={{ color: pathname === '/notification-etudiant' ? 'white' : '#FFD1A6' }}>Notifications(Etudiants)</span>
                                                        </Link>

                                                    </div>

                                                    <div class="menu-item">

                                                        <Link to='/notification-non-etudiant' class="menu-link" style={{ backgroundColor: pathname === '/notification-non-etudiant' ? '#EF7400' : '' }} >
                                                            <span class="menu-icon me-0">

                                                                <i class="ki-outline ki-duotone ki-people fs-1x" style={{ color: pathname === '/notification-non-etudiant' ? 'white' : '#FFD1A6' }}></i>

                                                            </span>
                                                            <span class="menu-title" style={{ color: pathname === '/notification-non-etudiant' ? 'white' : '#FFD1A6' }}>Notifications(Non-Etudiants)</span>
                                                        </Link>

                                                    </div>

                                                    {/* <div class="menu-item">

                                                        <Link to='/notification-particulier' class="menu-link" style={{ backgroundColor: pathname === '/notification-particulier' ? '#EF7400' : '' }} >
                                                            <span class="menu-icon me-0">

                                                                <i class="ki-outline ki-duotone ki-people fs-1x" style={{ color: pathname === '/notification-particulier' ? 'white' : '#FFD1A6' }}></i>

                                                            </span>
                                                            <span class="menu-title" style={{ color: pathname === '/notification-particulier' ? 'white' : '#FFD1A6' }}>Notifications(Particulier)</span>
                                                        </Link>

                                                    </div> */}
                                                </div>
                                            </div>

                                        </div>
                                        : (settings || opensettingsdrawer) ?

                                            <div style={{ width: 18 + 'rem' }}>
                                                <div class="d-flex flex-column pt-10 ps-11" id="kt_app_sidebar_secondary_header">


                                                    <span class="fs-2 fw-bolder text-white">Parametres</span>
                                                </div>

                                                <div class="app-sidebar-secondary-menu menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 py-4 py-lg-6" id="kt_app_sidebar_secondary_menu" data-kt-menu="true">
                                                    <div id="kt_app_sidebar_secondary_menu_wrapper" class="hover-scroll-y px-3 mx-3" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_secondary_header" data-kt-scroll-wrappers="#kt_app_sidebar_secondary_menu" data-kt-scroll-offset="20px">

                                                        <div class="menu-item">

                                                            <div class="menu-content">
                                                                <span class="menu-section fs-5 fw-bolder ps-1 py-1" style={{ color: '#EF7400' }}>Actions</span>
                                                            </div>
                                                        </div>


                                                        <div class="menu-item">
                                                            <Link to='/settings' class="menu-link" style={{ backgroundColor: pathname === '/settings' ? '#EF7400' : '' }}>
                                                                <span class="menu-icon me-0">

                                                                    <i class="ki-outline ki-duotone ki-setting-2  fs-1x" style={{ color: pathname === '/settings' ? 'white' : '#FFD1A6' }}></i>

                                                                </span>
                                                                <span class="menu-title" style={{ color: pathname === '/settings' ? 'white' : '#FFD1A6' }}>Mon compte</span>
                                                            </Link>

                                                        </div>

                                                        <div class="menu-item">

                                                            <Link to='/gestion-des-roles' class="menu-link" style={{ backgroundColor: pathname === '/gestion-des-roles' ? '#EF7400' : '' }} >
                                                                <span class="menu-icon me-0">

                                                                    <i class="ki-outline ki-duotone ki-setting-2  fs-1x" style={{ color: pathname === '/gestion-des-roles' ? 'white' : '#FFD1A6' }}></i>

                                                                </span>
                                                                <span class="menu-title" style={{ color: pathname === '/gestion-des-roles' ? 'white' : '#FFD1A6' }}>Gestion des roles</span>
                                                            </Link>

                                                        </div>


                                                        <div class="menu-item">

                                                            <Link to='/preferences' class="menu-link" style={{ backgroundColor: pathname === '/preferences' ? '#EF7400' : '' }}  >
                                                                <span class="menu-icon me-0">

                                                                    <i class="ki-outline ki-duotone ki-setting-2  fs-1x" style={{ color: pathname === '/preferences' ? 'white' : '#FFD1A6' }}></i>

                                                                </span>
                                                                <span class="menu-title" style={{ color: pathname === '/preferences' ? 'white' : '#FFD1A6' }}>Préférences</span>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            : null
                    }
                </div>

            </div>

        </div>
    )
};

export default Navbar
