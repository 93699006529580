export const GET_ARTICLE_START = "GET_ARTICLE_START";
export const GET_ARTICLE_SUCCESS = "GET_ARTICLE_SUCCESS";
export const GET_ARTICLE_FAILED = "GET_ARTICLE_FAILED";

export const SET_RIGHT_DRAWER = "SET_RIGHT_DRAWER";
export const SET_TYPE_DRAWER = "SET_TYPE_DRAWER";

export const UPLOAD_ARTICLE_START="UPLOAD_ARTICLE_START";
export const UPLOAD_ARTICLE_SUCCESS="UPLOAD_ARTICLE_SUCCESS";
export const UPLOAD_ARTICLE_FAILED="UPLOAD_ARTICLE_FAILED";

export const ADD_ARTICLE_START = "ADD_ARTICLE_START";
export const ADD_ARTICLE_SUCCESS = "ADD_ARTICLE_SUCCESS";
export const ADD_ARTICLE_FAILED = "ADD_ARTICLE_FAILED";

export const SEARCH_ARTICLE_START = "SEARCH_ARTICLE_START";
export const SEARCH_ARTICLE_SUCCESS = "SEARCH_ARTICLE_SUCCESS";
export const SEARCH_ARTICLE_FAILED = "SEARCH_ARTICLE_FAILED";

export const ARTICLE_VISIBILITE_START = "ARTICLE_VISIBILITE_START";
export const ARTICLE_VISIBILITE_SUCCESS = "ARTICLE_VISIBILITE_SUCCESS";
export const ARTICLE_VISIBILITE_FAILED = "ARTICLE_VISIBILITE_FAILED";

export const GET_ARTICLE_PAGINATION_START = "GET_ARTICLE_PAGINATION_START";
export const GET_ARTICLE_PAGINATION_SUCCESS = "GET_ARTICLE_PAGINATION_SUCCESS";
export const GET_ARTICLE_PAGINATION_FAILED = "GET_ARTICLE_PAGINATION_FAILED";

export const GET_ARTICLE_ETUDIANT_PAGINATION_START = "GET_ARTICLE_ETUDIANT_PAGINATION_START";
export const GET_ARTICLE_ETUDIANT_PAGINATION_SUCCESS = "GET_ARTICLE_ETUDIANT_PAGINATION_SUCCESS";
export const GET_ARTICLE_ETUDIANT_PAGINATION_FAILED = "GET_ARTICLE_ETUDIANT_PAGINATION_FAILED";

export const GET_ARTICLE_RESSORTISSANT_PAGINATION_START = "GET_ARTICLE_RESSORTISSANT_PAGINATION_START";
export const GET_ARTICLE_RESSORTISSANT_PAGINATION_SUCCESS = "GET_ARTICLE_RESSORTISSANT_PAGINATION_SUCCESS";
export const GET_ARTICLE_RESSORTISSANT_PAGINATION_FAILED = "GET_ARTICLE_RESSORTISSANT_PAGINATION_FAILED";