import * as actionsTypes from '../../types/auth/auth';

const initialState = {
    token: null,
    role: null,
    name: null,
    error: null,
    loading: false,

    loading_update: false,
    error_update_password: null,
    showupdatepassword: false

}

const reducerAuth = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.LOGIN_ADMIN_START:
            return {
                ...state,
                loading: true
            }

        case actionsTypes.LOGIN_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                token: action.token,
                name: action.name,
                role: action.role,
                error: null
            }

        case actionsTypes.LOGIN_ADMIN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case actionsTypes.LOGOUT:
            return {
                ...state,
                token: null,
                name: null,
                role: null,
                auth: false
            }

        case actionsTypes.UPDATE_PASSWORD_ADMIN_START:
            return {
                ...state,
                loading_update: true
            }

        case actionsTypes.UPDATE_PASSWORD_ADMIN_SUCCESS:
            return {
                ...state,
                loading_update: false,
                error_update_password: null,
                showupdatepassword: false
            }

        case actionsTypes.UPDATE_PASSWORD_ADMIN_FAILED:
            return {
                ...state,
                loading_update: false,
                error_update_password: action.error_update_password
            }

        case actionsTypes.OPEN_UPDATE_PASSWORD_MODAL:
            return {
                ...state,
                showupdatepassword: true
            }

        case actionsTypes.CLOSE_UPDATE_PASSWORD_MODAL:
            return {
                ...state,
                showupdatepassword: false
            }


        default:
            return state
    }
}

export default reducerAuth