
export const GET_NOTIFICATION_PAGINATION_START = "GET_NOTIFICATION_PAGINATION_START";
export const GET_NOTIFICATION_PAGINATION_SUCCESS = "GET_NOTIFICATION_PAGINATION_SUCCESS";
export const GET_NOTIFICATION_PAGINATION_FAILED = "GET_NOTIFICATION_PAGINATION_FAILED";

export const GET_NOTIFICATION_ETUDIANT_PAGINATION_START = "GET_NOTIFICATION_ETUDIANT_PAGINATION_START";
export const GET_NOTIFICATION_ETUDIANT_PAGINATION_SUCCESS = "GET_NOTIFICATION_ETUDIANT_PAGINATION_SUCCESS";
export const GET_NOTIFICATION_ETUDIANT_PAGINATION_FAILED = "GET_NOTIFICATION_ETUDIANT_PAGINATION_FAILED";

export const GET_NOTIFICATION_RESSORTISSANT_PAGINATION_START = "GET_NOTIFICATION_RESSORTISSANT_PAGINATION_START";
export const GET_NOTIFICATION_RESSORTISSANT_PAGINATION_SUCCESS = "GET_NOTIFICATION_RESSORTISSANT_PAGINATION_SUCCESS";
export const GET_NOTIFICATION_RESSORTISSANT_PAGINATION_FAILED = "GET_NOTIFICATION_RESSORTISSANT_PAGINATION_FAILED";