
import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Layout from '../../components/layout/layout';
import User from '../../assets/images/avatar.png';

const Requetes = () => {
  const [inbox, setInbox] = useState(false);
  const [message, setMessage] = useState(false);

  useEffect(() => {
    setInbox(true)
  }, []);

  const handleInbox = () => {
    setInbox(true)
    setMessage(false);
  }
  const handleMessage = () => {
    setMessage(true);
    setInbox(false);
  }
  return (
    <Layout openDrawer={true}>
      <div style={{ padding: "1rem" }}>
        <div class="d-flex flex-column flex-lg-row">

          <div class="d-none d-lg-flex flex-column flex-lg-row-auto w-100 w-lg-275px" data-kt-drawer="true" data-kt-drawer-name="inbox-aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_inbox_aside_toggle">

            <div class="card card-flush mb-0" data-kt-sticky="false" data-kt-sticky-name="inbox-aside-sticky" data-kt-sticky-offset="{default: false, xl: '100px'}" data-kt-sticky-width="{lg: '275px'}" data-kt-sticky-left="auto" data-kt-sticky-top="100px" data-kt-sticky-animation="false" data-kt-sticky-zindex="95">

              <div class="card-body">

                <span onClick={() => handleMessage()} class="btn fw-bold w-100 mb-8" style={{ backgroundColor: message ? "#EF7400" : '#eee', color: message ? "#fff" : '' }}>Nouveau Message</span>


                <div class="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10">

                  <div class="menu-item mb-3">

                    <span class="menu-link" style={{ backgroundColor: inbox ? "#EF7400" : "#fff" }} onClick={() => handleInbox()}>
                      <span class="menu-icon">
                        <i class="ki-outline ki-sms fs-2 me-3" style={{ color: inbox ? '#fff' : '' }}></i>
                      </span>
                      <span class="menu-title fw-bold" style={{ color: inbox ? '#fff' : '' }}>Boite de réception</span>
                      <span class="badge badge-light-success">0</span>
                    </span>

                  </div>


                  <div class="menu-item mb-3">

                    <span class="menu-link">
                      <span class="menu-icon">
                        <i class="ki-outline ki-abstract-23 fs-2 me-3"></i>
                      </span>
                      <span class="menu-title fw-bold">Marqué(s) lus</span>
                    </span>

                  </div>


                  <div class="menu-item mb-3">

                    <span class="menu-link">
                      <span class="menu-icon">
                        <i class="ki-outline ki-file fs-2 me-3"></i>
                      </span>
                      <span class="menu-title fw-bold">Brouillon(s)</span>
                      <span class="badge badge-light-warning">0</span>
                    </span>

                  </div>


                  <div class="menu-item mb-3">

                    <span class="menu-link">
                      <span class="menu-icon">
                        <i class="ki-outline ki-send fs-2 me-3"></i>
                      </span>
                      <span class="menu-title fw-bold">Envoyé(s)</span>
                    </span>

                  </div>


                  <div class="menu-item">

                    <span class="menu-link">
                      <span class="menu-icon">
                        <i class="ki-outline ki-trash fs-2 me-3"></i>
                      </span>
                      <span class="menu-title fw-bold">Corbeille</span>
                    </span>

                  </div>

                </div>


                {/* <div class="menu menu-column menu-rounded menu-state-bg menu-state-title-primary">

                  <div class="menu-item mb-3">

                    <span class="menu-link">
                      <span class="menu-icon">
                        <i class="ki-outline ki-abstract-8 fs-5 text-danger me-3 lh-0"></i>
                      </span>
                      <span class="menu-title fw-semibold">Custom Work</span>
                      <span class="badge badge-light-danger">6</span>
                    </span>

                  </div>


                  <div class="menu-item mb-3">

                    <span class="menu-link">
                      <span class="menu-icon">
                        <i class="ki-outline ki-abstract-8 fs-5 text-success me-3 lh-0"></i>
                      </span>
                      <span class="menu-title fw-semibold">Partnership</span>
                    </span>

                  </div>


                  <div class="menu-item mb-3">

                    <span class="menu-link">
                      <span class="menu-icon">
                        <i class="ki-outline ki-abstract-8 fs-5 text-info me-3 lh-0"></i>
                      </span>
                      <span class="menu-title fw-semibold">In Progress</span>
                    </span>

                  </div>


                  <div class="menu-item">

                    <span class="menu-link">
                      <span class="menu-icon">
                        <i class="ki-outline ki-plus fs-2 me-3 lh-0"></i>
                      </span>
                      <span class="menu-title fw-semibold">Add Label</span>
                    </span>

                  </div>

                </div> */}

              </div>

            </div>

          </div>

          <div class="flex-lg-row-fluid ms-lg-7 ms-xl-10">

            {inbox &&
              <div class="card">
                <div class="card-header align-items-center py-5 gap-2 gap-md-5">

                  <div class="d-flex flex-wrap gap-2">

                    <div class="form-check form-check-sm form-check-custom form-check-solid me-4 me-lg-7">
                      <input class="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_inbox_listing .form-check-input" value="1" />
                    </div>


                    <a href="#" class="btn btn-sm btn-icon btn-light btn-active-light-primary" data-bs-toggle="tooltip" data-bs-dismiss="click" data-bs-placement="top" aria-label="Reload" data-bs-original-title="Reload" data-kt-initialized="1">
                      <i class="ki-outline ki-arrows-circle fs-2"></i>
                    </a>


                    <a href="#" class="btn btn-sm btn-icon btn-light btn-active-light-primary" data-bs-toggle="tooltip" data-bs-dismiss="click" data-bs-placement="top" aria-label="Archive" data-bs-original-title="Archive" data-kt-initialized="1">
                      <i class="ki-outline ki-sms fs-2"></i>
                    </a>


                    <a href="#" class="btn btn-sm btn-icon btn-light btn-active-light-primary" data-bs-toggle="tooltip" data-bs-dismiss="click" data-bs-placement="top" aria-label="Delete" data-bs-original-title="Delete" data-kt-initialized="1">
                      <i class="ki-outline ki-trash fs-2"></i>
                    </a>


                    <div>
                      {/* <a href="#" class="btn btn-sm btn-icon btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start">
                      <i class="ki-outline ki-down fs-2"></i>
                    </a> */}

                      {/* <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">

                      <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3" data-kt-inbox-listing-filter="show_all">All</a>
                      </div>


                      <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3" data-kt-inbox-listing-filter="show_read">Read</a>
                      </div>


                      <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3" data-kt-inbox-listing-filter="show_unread">Unread</a>
                      </div>


                      <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3" data-kt-inbox-listing-filter="show_starred">Starred</a>
                      </div>


                      <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3" data-kt-inbox-listing-filter="show_unstarred">Unstarred</a>
                      </div>

                    </div> */}

                    </div>
                    {/* <span>
                    <a href="#" class="btn btn-sm btn-icon btn-light btn-active-light-primary" data-bs-toggle="tooltip" data-bs-dismiss="click" data-bs-placement="top" aria-label="Sort" data-bs-original-title="Sort" data-kt-initialized="1">
                      <i class="ki-outline ki-dots-square fs-3 m-0"></i>
                    </a>

                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">

                      <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3" data-kt-inbox-listing-filter="filter_newest">Newest</a>
                      </div>


                      <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3" data-kt-inbox-listing-filter="filter_oldest">Oldest</a>
                      </div>


                      <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3" data-kt-inbox-listing-filter="filter_unread">Unread</a>
                      </div>

                    </div>

                  </span> */}

                  </div>


                  <div class="d-flex align-items-center flex-wrap gap-2">

                    <div class="d-flex align-items-center position-relative">
                      <i class="ki-outline ki-magnifier fs-3 position-absolute ms-4"></i>
                      <input type="text" data-kt-inbox-listing-filter="search" class="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px ps-11" placeholder="Rechercher" />
                    </div>


                    <a href="#" class="btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary d-lg-none" data-bs-toggle="tooltip" data-bs-dismiss="click" data-bs-placement="top" id="kt_inbox_aside_toggle" aria-label="Toggle inbox menu" data-bs-original-title="Toggle inbox menu" data-kt-initialized="1">
                      <i class="ki-outline ki-burger-menu-2 fs-3 m-0"></i>
                    </a>

                  </div>

                </div>
                <div class="card-body p-0">

                  <div id="kt_inbox_listing_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer"><div class="table-responsive"><table class="table table-hover table-row-dashed fs-6 gy-5 my-0 dataTable no-footer" id="kt_inbox_listing">
                    <thead class="d-none">
                      <tr><th class="sorting" tabindex="0" aria-controls="kt_inbox_listing" rowspan="1" colspan="1" style={{ width: "0px" }} aria-label="Checkbox: activate to sort column ascending">Checkbox</th><th class="sorting" tabindex="0" aria-controls="kt_inbox_listing" rowspan="1" colspan="1" style={{ width: "0px" }} aria-label="Actions: activate to sort column ascending">Actions</th><th class="sorting" tabindex="0" aria-controls="kt_inbox_listing" rowspan="1" colspan="1" style={{ width: "0px" }} aria-label="Author: activate to sort column ascending">Author</th><th class="sorting" tabindex="0" aria-controls="kt_inbox_listing" rowspan="1" colspan="1" style={{ width: "0px" }} aria-label="Title: activate to sort column ascending">Title</th><th class="sorting" tabindex="0" aria-controls="kt_inbox_listing" rowspan="1" colspan="1" style={{ width: "0px" }} aria-label="Date: activate to sort column ascending">Date</th></tr>
                    </thead>
                    <tbody>
                      <tr class="odd">
                        {/* <td class="ps-9">
                        <div class="form-check form-check-sm form-check-custom form-check-solid mt-3">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td class="min-w-80px">

                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Star" data-bs-original-title="Star" data-kt-initialized="1">
                          <i class="ki-outline ki-star fs-3"></i>
                        </a>


                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Mark as important" data-bs-original-title="Mark as important" data-kt-initialized="1">
                          <i class="ki-outline ki-save-2 fs-4 mt-1"></i>
                        </a>

                      </td>
                      <td class="w-150px w-md-175px">
                        <a href="../../demo29/dist/apps/inbox/reply.html" class="d-flex align-items-center text-dark">

                          <div class="symbol symbol-35px me-3">
                            <div class="symbol-label bg-light-danger">
                              <span class="text-danger">M</span>
                            </div>
                          </div>


                          <span class="fw-semibold">Melody Macy</span>

                        </a>
                      </td>
                      <td>
                        <div class="text-dark gap-1 pt-2">

                          <a href="../../demo29/dist/apps/inbox/reply.html" class="text-dark">
                            <span class="fw-bold">Confirmation du client PPV numérique</span>
                            <span class="fw-bold d-none d-md-inine">-</span>
                            <span class="d-none d-md-inine text-muted">Thank you for ordering UFC 240 Holloway vs Edgar Alternate camera angles...</span>
                          </a>

                        </div>

                        <div class="badge badge-light-primary">inbox</div>
                        <div class="badge badge-light-warning">task</div>

                      </td>
                      <td class="w-100px text-end fs-7 pe-9">
                        <span class="fw-semibold">8:30 PM</span>
                      </td> */}
                      </tr>
                      <tr class="even">
                        {/* <td class="ps-9">
                        <div class="form-check form-check-sm form-check-custom form-check-solid mt-3">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td class="min-w-80px">

                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Star" data-bs-original-title="Star" data-kt-initialized="1">
                          <i class="ki-outline ki-star fs-3"></i>
                        </a>


                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Mark as important" data-bs-original-title="Mark as important" data-kt-initialized="1">
                          <i class="ki-outline ki-save-2 fs-4 mt-1"></i>
                        </a>

                      </td>
                      <td class="w-150px w-md-175px">
                        <a href="../../demo29/dist/apps/inbox/reply.html" class="d-flex align-items-center text-dark">

                          <div class="symbol symbol-35px me-3">
                            <span class="symbol-label" style={{ backgroundImage: "url(assets/media/avatars/300-1.jpg)" }}></span>
                          </div>


                          <span class="fw-semibold">Max Smith</span>

                        </a>
                      </td>
                      <td>
                        <div class="text-dark gap-1 pt-2">

                          <a href="../../demo29/dist/apps/inbox/reply.html" class="text-dark">
                            <span class="fw-bold">Votre confirmation d'achat</span>
                            <span class="fw-bold d-none d-md-inine">-</span>
                            <span class="d-none d-md-inine text-muted">Please make sure that you have one of the following cards with you when we deliver your order...</span>
                          </a>

                        </div>
                      </td>
                      <td class="w-100px text-end fs-7 pe-9">
                        <span class="fw-semibold">day ago</span>
                      </td> */}
                      </tr>
                      <tr class="odd">
                        {/* <td class="ps-9">
                        <div class="form-check form-check-sm form-check-custom form-check-solid mt-3">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td class="min-w-80px">

                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Star" data-bs-original-title="Star" data-kt-initialized="1">
                          <i class="ki-outline ki-star fs-3"></i>
                        </a>


                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Mark as important" data-bs-original-title="Mark as important" data-kt-initialized="1">
                          <i class="ki-outline ki-save-2 fs-4 mt-1"></i>
                        </a>

                      </td>
                      <td class="w-150px w-md-175px">
                        <a href="../../demo29/dist/apps/inbox/reply.html" class="d-flex align-items-center text-dark">

                          <div class="symbol symbol-35px me-3">
                            <img class="symbol-label" src={User} />
                          </div>


                          <span class="fw-semibold">Sean Bean</span>

                        </a>
                      </td>
                      <td>
                        <div class="text-dark gap-1 pt-2">

                          <a href="../../demo29/dist/apps/inbox/reply.html" class="text-dark">
                            <span class="fw-bold">Votre commande #224820998666029 à été confirmée</span>
                            <span class="fw-bold d-none d-md-inine">-</span>
                            <span class="d-none d-md-inine text-muted">Your Order #224820998666029 has been placed on Saturday, 29 June</span>
                          </a>

                        </div>
                      </td>
                      <td class="w-100px text-end fs-7 pe-9">
                        <span class="fw-semibold text-muted">11:20 PM</span>
                      </td> */}
                      </tr>
                      <tr class="even">
                        {/* <td class="ps-9">
                        <div class="form-check form-check-sm form-check-custom form-check-solid mt-3">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td class="min-w-80px">

                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Star" data-bs-original-title="Star" data-kt-initialized="1">
                          <i class="ki-outline ki-star fs-3"></i>
                        </a>


                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Mark as important" data-bs-original-title="Mark as important" data-kt-initialized="1">
                          <i class="ki-outline ki-save-2 fs-4 mt-1"></i>
                        </a>

                      </td>
                      <td class="w-150px w-md-175px">
                        <a href="../../demo29/dist/apps/inbox/reply.html" class="d-flex align-items-center text-dark">

                          <div class="symbol symbol-35px me-3">
                            <span class="symbol-label" style={{ backgroundImage: "url(assets/media/avatars/300-25.jpg)" }}></span>
                          </div>


                          <span class="fw-semibold">Brian Cox</span>

                        </a>
                      </td>
                      <td>
                        <div class="text-dark gap-1 pt-2">

                          <a href="../../demo29/dist/apps/inbox/reply.html" class="text-dark">
                            <span class="fw-bold">Notification de paiement DLOP2329KD</span>
                            <span class="fw-bold d-none d-md-inine">-</span>
                            <span class="d-none d-md-inine text-muted">Your payment of 4500USD to AirCar has been authorized and confirmed, thank you your account. This...</span>
                          </a>

                        </div>

                        <div class="badge badge-light-danger">new</div>

                      </td>
                      <td class="w-100px text-end fs-7 pe-9">
                        <span class="fw-semibold text-muted">2 days ago</span>
                      </td> */}
                      </tr>

                      <tr class="odd">
                        {/* <td class="ps-9">
                        <div class="form-check form-check-sm form-check-custom form-check-solid mt-3">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td class="min-w-80px">

                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Star" data-bs-original-title="Star" data-kt-initialized="1">
                          <i class="ki-outline ki-star fs-3"></i>
                        </a>


                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Mark as important" data-bs-original-title="Mark as important" data-kt-initialized="1">
                          <i class="ki-outline ki-save-2 fs-4 mt-1"></i>
                        </a>

                      </td>
                      <td class="w-150px w-md-175px">
                        <a href="../../demo29/dist/apps/inbox/reply.html" class="d-flex align-items-center text-dark">

                          <div class="symbol symbol-35px me-3">
                            <div class="symbol-label bg-light-warning">
                              <span class="text-warning">C</span>
                            </div>
                          </div>


                          <span class="fw-semibold">Mikaela Collins</span>

                        </a>
                      </td>
                      <td>
                        <div class="text-dark gap-1 pt-2">

                          <a href="../../demo29/dist/apps/inbox/reply.html" class="text-dark">
                            <span class="fw-bold">Félicitations pour votre abonnement à iRun Coach</span>
                            <span class="fw-bold d-none d-md-inine">-</span>
                            <span class="d-none d-md-inine text-muted">Congratulations on your iRun Coach subscription. You made no space for excuses and you</span>
                          </a>

                        </div>
                      </td>
                      <td class="w-100px text-end fs-7 pe-9">
                        <span class="fw-semibold text-muted">July 25</span>
                      </td> */}
                      </tr>

                      <tr class="even">
                        {/* <td class="ps-9">
                        <div class="form-check form-check-sm form-check-custom form-check-solid mt-3">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td class="min-w-80px">

                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Star" data-bs-original-title="Star" data-kt-initialized="1">
                          <i class="ki-outline ki-star fs-3"></i>
                        </a>


                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Mark as important" data-bs-original-title="Mark as important" data-kt-initialized="1">
                          <i class="ki-outline ki-save-2 fs-4 mt-1"></i>
                        </a>

                      </td>
                      <td class="w-150px w-md-175px">
                        <a href="../../demo29/dist/apps/inbox/reply.html" class="d-flex align-items-center text-dark">

                          <div class="symbol symbol-35px me-3">
                            <span class="symbol-label" style={{ backgroundImage: "url(assets/media/avatars/300-9.jpg)" }}></span>
                          </div>


                          <span class="fw-semibold">Francis Mitcham</span>

                        </a>
                      </td>
                      <td>
                        <div class="text-dark gap-1 pt-2">

                          <a href="#" class="text-dark">
                            <span class="fw-bold">Problème de paiement, resolution en cours</span>
                            <span class="fw-bold d-none d-md-inine">-</span>
                            <span class="d-none d-md-inine text-muted">Congratulations on your iRun Coach subscription. You made no space for excuses and you decided on a healthier and happier life...</span>
                          </a>

                        </div>
                      </td>
                      <td class="w-100px text-end fs-7 pe-9">
                        <span class="fw-semibold text-muted">July 24</span>
                      </td> */}
                      </tr>

                      <tr class="odd">
                        {/* <td class="ps-9">
                        <div class="form-check form-check-sm form-check-custom form-check-solid mt-3">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td class="min-w-80px">

                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Star" data-bs-original-title="Star" data-kt-initialized="1">
                          <i class="ki-outline ki-star fs-3"></i>
                        </a>


                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Mark as important" data-bs-original-title="Mark as important" data-kt-initialized="1">
                          <i class="ki-outline ki-save-2 fs-4 mt-1"></i>
                        </a>

                      </td>
                      <td class="w-150px w-md-175px">
                        <a href="../../demo29/dist/apps/inbox/reply.html" class="d-flex align-items-center text-dark">

                          <div class="symbol symbol-35px me-3">
                            <div class="symbol-label bg-light-danger">
                              <span class="text-danger">O</span>
                            </div>
                          </div>


                          <span class="fw-semibold">Olivia Wild</span>

                        </a>
                      </td>
                      <td>
                        <div class="text-dark gap-1 pt-2">

                          <a href="#" class="text-dark">
                            <span class="fw-bold">Activation du compte</span>
                            <span class="fw-bold d-none d-md-inine">-</span>
                            <span class="d-none d-md-inine text-muted">Thank you for creating a LIPO Account. Please click the link below to activate your account.</span>
                          </a>

                        </div>

                        <div class="badge badge-light-warning">task</div>

                      </td>
                      <td class="w-100px text-end fs-7 pe-9">
                        <span class="fw-semibold text-muted">July 13</span>
                      </td> */}
                      </tr>

                      <tr class="even">
                        {/* <td class="ps-9">
                        <div class="form-check form-check-sm form-check-custom form-check-solid mt-3">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td class="min-w-80px">

                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Star" data-bs-original-title="Star" data-kt-initialized="1">
                          <i class="ki-outline ki-star fs-3"></i>
                        </a>


                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Mark as important" data-bs-original-title="Mark as important" data-kt-initialized="1">
                          <i class="ki-outline ki-save-2 fs-4 mt-1"></i>
                        </a>

                      </td>
                      <td class="w-150px w-md-175px">
                        <a href="../../demo29/dist/apps/inbox/reply.html" class="d-flex align-items-center text-dark">

                          <div class="symbol symbol-35px me-3">
                            <div class="symbol-label bg-light-primary">
                              <span class="text-primary">N</span>
                            </div>
                          </div>


                          <span class="fw-semibold">Neil Owen</span>

                        </a>
                      </td>
                      <td>
                        <div class="text-dark gap-1 pt-2">

                          <a href="../../demo29/dist/apps/inbox/reply.html" class="text-dark">
                            <span class="fw-bold">A propos de votre requête par Andre</span>
                            <span class="fw-bold d-none d-md-inine">-</span>
                            <span class="d-none d-md-inine text-muted">What you requested can't be arranged ahead of time but PalmLake said they'll do their best to accommodate you upon arrival....</span>
                          </a>

                        </div>
                      </td>
                      <td class="w-100px text-end fs-7 pe-9">
                        <span class="fw-semibold text-muted">May 25</span>
                      </td> */}
                      </tr>

                      <tr class="odd">
                        {/* <td class="ps-9">
                        <div class="form-check form-check-sm form-check-custom form-check-solid mt-3">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td class="min-w-80px">

                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Star" data-bs-original-title="Star" data-kt-initialized="1">
                          <i class="ki-outline ki-star fs-3"></i>
                        </a>


                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Mark as important" data-bs-original-title="Mark as important" data-kt-initialized="1">
                          <i class="ki-outline ki-save-2 fs-4 mt-1"></i>
                        </a>

                      </td>
                      <td class="w-150px w-md-175px">
                        <a href="../../demo29/dist/apps/inbox/reply.html" class="d-flex align-items-center text-dark">

                          <div class="symbol symbol-35px me-3">
                            <img class="symbol-label" src={User} />
                          </div>


                          <span class="fw-semibold">Dan Wilson</span>

                        </a>
                      </td>
                      <td>
                        <div class="text-dark gap-1 pt-2">

                          <a href="../../demo29/dist/apps/inbox/reply.html" class="text-dark">
                            <span class="fw-bold">Welcome, Patty</span>
                            <span class="fw-bold d-none d-md-inine">-</span>
                            <span class="d-none d-md-inine text-muted">Discover interesting ideas and unique perspectives. Read, explore and follow your interests. Get personalized recommendations delivered to you....</span>
                          </a>

                        </div>
                      </td>
                      <td class="w-100px text-end fs-7 pe-9">
                        <span class="fw-semibold">April 15</span>
                      </td> */}
                      </tr>

                      <tr class="even">
                        {/* <td class="ps-9">
                        <div class="form-check form-check-sm form-check-custom form-check-solid mt-3">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td class="min-w-80px">

                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Star" data-bs-original-title="Star" data-kt-initialized="1">
                          <i class="ki-outline ki-star fs-3"></i>
                        </a>


                        <a href="#" class="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Mark as important" data-bs-original-title="Mark as important" data-kt-initialized="1">
                          <i class="ki-outline ki-save-2 fs-4 mt-1"></i>
                        </a>

                      </td>
                      <td class="w-150px w-md-175px">
                        <a href="../../demo29/dist/apps/inbox/reply.html" class="d-flex align-items-center text-dark">

                          <div class="symbol symbol-35px me-3">
                            <div class="symbol-label bg-light-danger">
                              <span class="text-danger">E</span>
                            </div>
                          </div>


                          <span class="fw-semibold">Emma Bold</span>

                        </a>
                      </td>
                      <td>
                        <div class="text-dark gap-1 pt-2">

                          <a href="../../demo29/dist/apps/inbox/reply.html" class="text-dark">
                            <span class="fw-bold">Guide gratuit de marketing vidéo</span>
                            <span class="fw-bold d-none d-md-inine">-</span>
                            <span class="d-none d-md-inine text-muted">Video has rolled into every marketing platform or channel, leaving...</span>
                          </a>

                        </div>

                        <div class="badge badge-light-success">project</div>

                      </td>
                      <td class="w-100px text-end fs-7 pe-9">
                        <span class="fw-semibold text-muted">April 3</span>
                      </td> */}
                      </tr>

                    </tbody>
                  </table></div>
                    <div class="row px-9 pt-3 pb-5">
                      <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"><div class="dataTables_length" id="kt_inbox_listing_length"><label>
                        <select name="kt_inbox_listing_length" aria-controls="kt_inbox_listing" class="form-select form-select-sm form-select-solid"><option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </label>
                      </div></div>
                      <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"><div class="dataTables_paginate paging_simple_numbers" id="kt_inbox_listing_paginate">
                        <ul class="pagination">
                          {/* <li class="paginate_button page-item previous disabled" id="kt_inbox_listing_previous">
                            <a href="#" aria-controls="kt_inbox_listing" data-dt-idx="0" tabindex="0" class="page-link">
                              <i class="previous"></i></a>
                          </li> */}
                          <li class="paginate_button page-item" style={{ backgroundColor: "#EF7400" }}>
                            <a href="#" aria-controls="kt_inbox_listing" data-dt-idx="1" tabindex="0" class="page-link" style={{ color: '#fff' }}>1</a>
                          </li>
                          {/* <li class="paginate_button page-item ">
                            <a href="#" aria-controls="kt_inbox_listing" data-dt-idx="2" tabindex="0" class="page-link">2</a>
                          </li>
                          <li class="paginate_button page-item next" id="kt_inbox_listing_next">
                            <a href="#" aria-controls="kt_inbox_listing" data-dt-idx="3" tabindex="0" class="page-link"><i class="next"></i></a>
                          </li> */}
                        </ul>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            {message && <CKEditor
              editor={ClassicEditor}
              data="<p>Ecrire votre message ici</p>"
              onReady={editor => {
                // You can store the "editor" and use when it is needed.
                console.log('Editor is ready to use!', editor);
              }}
              onChange={(event) => {
                console.log(event);
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor);
              }}
            />}

            {/* {message && <div class="card">
              <div class="card-header d-flex align-items-center justify-content-between py-3">
                <h2 class="card-title m-0">Compose Message</h2>

                <a href="#" class="btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary d-lg-none" data-bs-toggle="tooltip" data-bs-dismiss="click" data-bs-placement="top" id="kt_inbox_aside_toggle" aria-label="Toggle inbox menu" data-bs-original-title="Toggle inbox menu" data-kt-initialized="1">
                  <i class="ki-outline ki-burger-menu-2 fs-3 m-0"></i>
                </a>

              </div>
              <div class="card-body p-0">

                <form id="kt_inbox_compose_form">

                  <div class="d-block">

                    <div class="d-flex align-items-center border-bottom px-8 min-h-50px">

                      <div class="text-dark fw-bold w-75px">To:</div>


                      <tags class="tagify form-control form-control-transparent border-0 tagify--noTags tagify--empty" tabindex="-1">
                        <span contenteditable="" tabindex="0" data-placeholder="​" aria-placeholder="" class="tagify__input" role="textbox" aria-autocomplete="both" aria-multiline="false"></span>

                      </tags><input type="text" class="form-control form-control-transparent border-0" name="compose_to" value="" data-kt-inbox-form="tagify" tabindex="-1" />


                      <div class="ms-auto w-75px text-end">
                        <span class="text-muted fs-bold cursor-pointer text-hover-primary me-2" data-kt-inbox-form="cc_button">Cc</span>
                        <span class="text-muted fs-bold cursor-pointer text-hover-primary" data-kt-inbox-form="bcc_button">Bcc</span>
                      </div>

                    </div>


                    <div class="d-none align-items-center border-bottom ps-8 pe-5 min-h-50px" data-kt-inbox-form="cc">

                      <div class="text-dark fw-bold w-75px">Cc:</div>


                      <tags class="tagify form-control form-control-transparent border-0 tagify--noTags tagify--empty" tabindex="-1">
                        <span contenteditable="" tabindex="0" data-placeholder="​" aria-placeholder="" class="tagify__input" role="textbox" aria-autocomplete="both" aria-multiline="false"></span>

                      </tags><input type="text" class="form-control form-control-transparent border-0" name="compose_cc" value="" data-kt-inbox-form="tagify" tabindex="-1" />


                      <span class="btn btn-clean btn-xs btn-icon" data-kt-inbox-form="cc_close">
                        <i class="ki-outline ki-cross fs-5"></i>
                      </span>

                    </div>


                    <div class="d-none align-items-center border-bottom inbox-to-bcc ps-8 pe-5 min-h-50px" data-kt-inbox-form="bcc">

                      <div class="text-dark fw-bold w-75px">Bcc:</div>


                      <tags class="tagify form-control form-control-transparent border-0 tagify--noTags tagify--empty" tabindex="-1">
                        <span contenteditable="" tabindex="0" data-placeholder="​" aria-placeholder="" class="tagify__input" role="textbox" aria-autocomplete="both" aria-multiline="false"></span>

                      </tags><input type="text" class="form-control form-control-transparent border-0" name="compose_bcc" value="" data-kt-inbox-form="tagify" tabindex="-1" />


                      <span class="btn btn-clean btn-xs btn-icon" data-kt-inbox-form="bcc_close">
                        <i class="ki-outline ki-cross fs-5"></i>
                      </span>

                    </div>


                    <div class="border-bottom">
                      <input class="form-control form-control-transparent border-0 px-8 min-h-45px" name="compose_subject" placeholder="Subject" />
                    </div>

                    <div class="ql-editor ql-blank" data-gramm="false" contenteditable="true" data-placeholder="Ecrire votre message ici"><p><br /></p></div>

                  </div>


                  <div class="d-flex flex-stack flex-wrap gap-2 py-5 ps-8 pe-5 border-top">

                    <div class="d-flex align-items-center me-3">

                      <div class="btn-group me-4">

                        <span class="btn fs-bold px-6" style={{ backgroundColor: "#8e736a", color: "#fff" }} data-kt-inbox-form="send">
                          <span class="indicator-label">Envoyer</span>
                          <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </span>


                       

                      </div>


                      <span class="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2 dz-clickable" id="kt_inbox_reply_attachments_select" data-kt-inbox-form="dropzone_upload">
                        <i class="ki-outline ki-paper-clip fs-2 m-0"></i>
                      </span>


                      <span class="btn btn-icon btn-sm btn-clean btn-active-light-primary">
                        <i class="ki-outline ki-geolocation fs-2 m-0"></i>
                      </span>

                    </div>


                    <div class="d-flex align-items-center">

                      <span class="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2" data-toggle="tooltip" title="More actions">
                        <i class="ki-outline ki-setting-2 fs-2"></i>
                      </span>


                      <span class="btn btn-icon btn-sm btn-clean btn-active-light-primary" data-inbox="dismiss" data-toggle="tooltip" title="Dismiss reply">
                        <i class="ki-outline ki-trash fs-2"></i>
                      </span>

                    </div>

                  </div>

                </form>

              </div>
            </div>} */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Requetes