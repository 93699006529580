import { OPEN_USER_DRAWER, OPEN_ARTICLE_DRAWER, OPEN_RENDEZVOUS_DRAWER, OPEN_NOTIFICATION_DRAWER, OPEN_SETTING_DRAWER} from '../../types/appsettings/appsettings';



export const setOpenUserDrawer = (value) => {
    return{
        type:OPEN_USER_DRAWER,
        value:value
    }
}

export const setOpenArticleDrawer = (value) => {
    return{
        type:OPEN_ARTICLE_DRAWER,
        value:value
    }
}

export const setOpenRendezVousDrawer = (value) => {
    return{
        type:OPEN_RENDEZVOUS_DRAWER,
        value:value
    }
}

export const setOpenNotificationdrawer = (value) => {
    return{
        type:OPEN_NOTIFICATION_DRAWER,
        value:value
    }
}

export const setOpenSettingsdrawer = (value) => {
    return{
        type:OPEN_SETTING_DRAWER,
        value:value
    }
}
